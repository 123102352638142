import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";

import { I18nText } from "component/I18nText";

enum States {
  Alabama,
  Alaska,
  Arizona,
  Arkansas,
  California,
  Colorado,
  Connecticut,
  Delaware,
  DistrictOfColumbia,
  Florida,
  Georgia,
  Hawaii,
  Idaho,
  Illinois,
  Indiana,
  Iowa,
  Kansas,
  Kentucky,
  Louisiana,
  Maine,
  Maryland,
  Massachusetts,
  Michigan,
  Minnesota,
  Mississippi,
  Missouri,
  Montana,
  Nebraska,
  Nevada,
  NewHampshire,
  NewJersey,
  NewMexico,
  NewYork,
  NorthCarolina,
  NorthDakota,
  Ohio,
  Oklahoma,
  Oregon,
  Pennsylvania,
  RhodeIsland,
  SouthCarolina,
  SouthDakota,
  Tennessee,
  Texas,
  Utah,
  Vermont,
  Virginia,
  Washington,
  WestVirginia,
  Wisconsin,
  Wyoming,
}

export interface USStatesDropDownFieldProps {
  error: boolean;
  inputLabel: string;
  inputPropName: string;
  onChange: (e: React.ChangeEvent<{ value: any }>) => void;
  label: string;
  value: number;
}

export class USStatesDropDownField extends React.Component<
  USStatesDropDownFieldProps
> {
  render() {
    const {
      error,
      inputLabel,
      inputPropName,
      label,
      onChange,
      value,
    } = this.props;
    return (
      <FormControl fullWidth={true}>
        <InputLabel htmlFor={inputLabel}>
          {<I18nText i18nKey={label} />}
        </InputLabel>
        <Select
          data-test-id="us-state-input"
          error={error}
          onChange={onChange}
          value={value}
          inputProps={{
            id: inputLabel,
            name: inputPropName,
          }}
        >
          {Object.keys(States)
            .filter((key: any) => typeof States[key] === "number")
            .map((key: any) => {
              const stateValue = States[key];
              return (
                <MenuItem data-test-id={key} key={key} value={stateValue}>
                  <I18nText i18nKey={`select_list.states.${stateValue}.full`} />
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  }
}
