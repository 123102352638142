import { UserProfile } from "model/index";

import { Fetcher } from "./index";

export function getMe() {
  return async (fetcher: Fetcher) => {
    const response = await fetcher("users/me", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    });

    return response.json();
  };
}

export function createUser(userProfile: Omit<UserProfile, "userId">) {
  return async (fetcher: Fetcher) => {
    const response = await fetcher("users", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userProfile),
      method: "POST",
    });

    return response.json();
  };
}
