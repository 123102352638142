import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { SalonFormProps } from "../form/state";

export type SalonDescriptionFormProps = SalonFormProps;

export class SalonDescriptionForm extends React.Component<
  SalonDescriptionFormProps
> {
  render() {
    const titleError = getErrorForKey(this.props.errors, "title");
    const descriptionError = getErrorForKey(this.props.errors, "description");
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_description.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_description.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="title-input"
            error={!!titleError}
            fullWidth={true}
            helperText={titleError}
            label={
              <I18nText
                i18nKey={"forms.salon_description.fields.title.label"}
              />
            }
            onChange={createStringEventHandler(this.props, "title" as any)}
            required={true}
            value={this.props.object.title}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="description-input"
            error={!!descriptionError}
            fullWidth={true}
            helperText={descriptionError}
            label={
              <I18nText
                i18nKey={"forms.salon_description.fields.description.label"}
              />
            }
            onChange={createStringEventHandler(
              this.props,
              "description" as any
            )}
            value={this.props.object.description}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
