import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { I18nText } from "component/I18nText";

export type HomePageFooterProps = WithStyles<typeof styles>;

const styles: any = (theme: Theme) => ({
  footer: {
    alignItems: "center",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_1200/v1551749522/siteImageAssets/Bkgrnd324.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: 600,
    justifyContent: "center",
    padding: theme.spacing(1),
  },

  textStyle: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
});

export class HomePageFooterClass extends React.Component<HomePageFooterProps> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.footer}>
        <Container maxWidth="xl">
          <Grid justify="center" container={true} spacing={6}>
            <Grid item={true} xs={12} md={3}>
              <Typography
                align="center"
                className={classes.textStyle}
                variant="h4"
              >
                <I18nText i18nKey="pages.home.section_three.item_one.title" />
              </Typography>
              <Typography
                align="center"
                className={classes.textStyle}
                variant="subtitle1"
              >
                <I18nText i18nKey="pages.home.section_three.item_one.description" />
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Typography
                align="center"
                className={classes.textStyle}
                variant="h4"
              >
                <I18nText i18nKey="pages.home.section_three.item_two.title" />
              </Typography>
              <Typography
                align="center"
                className={classes.textStyle}
                variant="subtitle1"
              >
                <I18nText i18nKey="pages.home.section_three.item_two.description" />
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Typography
                align="center"
                className={classes.textStyle}
                variant="h4"
              >
                <I18nText i18nKey="pages.home.section_three.item_three.title" />
              </Typography>
              <Typography
                align="center"
                className={classes.textStyle}
                variant="subtitle1"
              >
                <I18nText i18nKey="pages.home.section_three.item_three.description" />
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
export const HomePageFooter = withStyles(styles)(HomePageFooterClass);
