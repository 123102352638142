import FormGroup from "@material-ui/core/FormGroup";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { SalonCheckBoxItem } from "component/SalonCheckBoxItem";
import { FormProps } from "form/index";
import { Weekday } from "model/index";

import { SalonFormState } from "../../form/state";
import { SalonHoursDropDown } from "./SalonHoursDropDown";

export type SalonOperatingHoursFormStyles = WithStyles<typeof styles>;

export type SalonOperatingHoursFormClassProps = FormProps<
  SalonFormState,
  keyof SalonFormState
>;

export type SalonOperatingHoursFormProps = SalonOperatingHoursFormClassProps &
  SalonOperatingHoursFormStyles;

const orderedDaysOfWeek = [
  Weekday.SUNDAY,
  Weekday.MONDAY,
  Weekday.TUESDAY,
  Weekday.WEDNESDAY,
  Weekday.THURSDAY,
  Weekday.FRIDAY,
  Weekday.SATURDAY,
];

const styles = (theme: Theme) => ({
  hoursContainer: {
    marginLeft: theme.spacing(4),
  },
});

export class SalonOperatingHoursFormClass extends React.Component<
  SalonOperatingHoursFormProps
> {
  render() {
    const { classes } = this.props;
    const operatingHours = this.props.object.operatingHours;
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_operating_hours.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_operating_hours.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <FormGroup>
            {orderedDaysOfWeek.map((dayOfWeek) => {
              const operatingHoursForDay = operatingHours.find(
                (value) => value.dayOfWeek === dayOfWeek
              );
              return (
                <div key={dayOfWeek}>
                  <SalonCheckBoxItem
                    checked={!!operatingHoursForDay}
                    data-test-id={`day-${dayOfWeek}-input`}
                    handleChange={this.handleChange(dayOfWeek)}
                    label={
                      <I18nText i18nKey={`select_list.weekdays.${dayOfWeek}`} />
                    }
                    key={dayOfWeek}
                  />
                  {!!operatingHoursForDay ? (
                    <div className={classes.hoursContainer}>
                      <SalonHoursDropDown
                        endTime={operatingHoursForDay.endTime}
                        startTime={operatingHoursForDay.startTime}
                        onEndTimeChange={this.handleEndTimeChange(dayOfWeek)}
                        onStartTimeChange={this.handleStartTimeChange(
                          dayOfWeek
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </FormGroup>
        </Grid>
      </FormWrapper>
    );
  }

  private handleEndTimeChange(dayOfWeek: Weekday) {
    return (endTime: string) => {
      const operatingHoursForDay = this.props.object.operatingHours.find(
        (value) => value.dayOfWeek === dayOfWeek
      );
      if (operatingHoursForDay) {
        const updatedOperatingHoursForDay = {
          ...operatingHoursForDay,
          endTime,
        };

        const operatingHours = [
          ...this.props.object.operatingHours.filter(
            (value) => value.dayOfWeek !== dayOfWeek
          ),
          updatedOperatingHoursForDay,
        ];

        this.props.onObjectFieldChange("operatingHours", operatingHours);
      }
    };
  }

  private handleStartTimeChange(dayOfWeek: Weekday) {
    return (startTime: string) => {
      const operatingHoursForDay = this.props.object.operatingHours.find(
        (value) => value.dayOfWeek === dayOfWeek
      );
      if (operatingHoursForDay) {
        const updatedOperatingHoursForDay = {
          ...operatingHoursForDay,
          startTime,
        };

        const operatingHours = [
          ...this.props.object.operatingHours.filter(
            (value) => value.dayOfWeek !== dayOfWeek
          ),
          updatedOperatingHoursForDay,
        ];

        this.props.onObjectFieldChange("operatingHours", operatingHours);
      }
    };
  }

  private handleChange = (dayOfWeek: Weekday) => {
    return (checked: boolean) => {
      const operatingHours = checked
        ? [
            ...this.props.object.operatingHours,
            { dayOfWeek, startTime: "10:00", endTime: "18:00" },
          ]
        : this.props.object.operatingHours.filter(
            (value) => value.dayOfWeek !== dayOfWeek
          );
      this.props.onObjectFieldChange("operatingHours", operatingHours as any);
    };
  };
}

export const SalonOperatingHoursForm = withStyles(styles)(
  SalonOperatingHoursFormClass
);
