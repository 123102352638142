import { ReducersMapObject } from "redux";

import { editUserProfileFormReducer } from "./form/state";
import { editStylistLicenseFormReducer } from "./form/state";

export function getReducer(): ReducersMapObject {
  return {
    editStylistLicenseFormState: editStylistLicenseFormReducer,
    editUserProfileFormState: editUserProfileFormReducer,
  };
}

export { EditUserProfilePageContainer as EditUserProfilePage } from "./EditUserProfilePage";
