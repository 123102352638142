import some from "lodash/some";

import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import { History, Location } from "history";
import moment from "moment";
import { Moment } from "moment";
import queryString from "query-string";
import React, { useState } from "react";
import { FocusedInputShape } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams, useLocation, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { gql } from "apollo-boost";

import { getImageUrl } from "api/cloudinary";
import { I18nText } from "component/I18nText";
import Button from "component/modules/Button";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { DateRange } from "component/MultiDateRangePicker";
import { MultiDateRangePicker } from "component/MultiDateRangePicker";
import { SocialMediaButton } from "component/SocialMediaButton";
import { NavigationActions } from "tools/NavigationActions";
import { useNavigationActions } from "tools/useNavigationActions";

import { ItemList } from "./component/ItemList";
import { SalonOperatingHours } from "./component/SalonOperatingHours";
import {
  getRequestTourStatus,
  getRequestTourError,
  requestTour,
} from "./state";

import { fragments } from "./component/SalonOperatingHours";

import {
  SalonDetailQuery,
  SalonDetailQueryVariables,
} from "./SalonDetailPage.generated";

const SALON_IMAGE_OPTIONS = {
  backgroundColor: "rgb:FFFFFF",
  crop: "pad",
  height: 768,
  width: 1024,
};

const chairFragment = gql`
  fragment SalonDetailPage_chair on Chair {
    id
    title
    price
    availableDates(startDate: $startDate, endDate: $endDate)
  }
`;

const salonFragment = gql`
  fragment SalonDetailPage_salon on Salon {
    id
    name
    title
    address
    phoneNumber
    description
    images {
      publicId
    }
    chairs {
      ...SalonDetailPage_chair
    }
    amenities
    equipment
    practices
    rules
    socialMediaFacebook
    socialMediaInstagram
    socialMediaTwitter
    socialMediaWebsite
    socialMediaYelp
    operatingHours {
      ...SalonOperatingHours_operatingHours
    }
  }
  ${fragments.operatingHours}
  ${chairFragment}
`;

const SALON_DETAIL_QUERY = gql`
  query SalonDetail($id: ID!, $startDate: Date!, $endDate: Date!) {
    salonById(id: $id) {
      ...SalonDetailPage_salon
    }
  }
  ${salonFragment}
`;

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },

  hero: {
    margin: "0 auto",
    maxWidth: "800px",
  },

  lightText: {
    color: theme.palette.common.white,
  },

  list: {
    listStyle: "none",
  },

  middleSection: {
    backgroundColor: theme.palette.primary.main,
  },

  phoneNumber: {
    color: theme.palette.secondary.main,
  },

  section: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },

  spacer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  sectionSpacer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

export const SalonDetailPage = () => {
  const location = useLocation();
  const history = useHistory();
  const navigationActions = useNavigationActions();
  const params = useParams<{ id: string }>();
  const salonId = params.id;
  const { chairId } = queryString.parse(location.search);

  const [dateRanges, setDateRanges] = useState<ReadonlyArray<DateRange>>([]);
  const [bookingEndDate, setBookingEndDate] = useState<Moment | null>(null);
  const [bookingStartDate, setBookingStartDate] = useState<Moment | null>(null);

  const minDate = moment().startOf("day").subtract(1, "days");
  const maxDate = moment(minDate).add(90, "days");
  const salonDetailQueryResult = useQuery<
    SalonDetailQuery,
    SalonDetailQueryVariables
  >(
    SALON_DETAIL_QUERY,

    {
      fetchPolicy: "no-cache",
      variables: {
        id: salonId,
        startDate: minDate.format("YYYY-MM-DD"),
        endDate: maxDate.format("YYYY-MM-DD"),
      },
    }
  );

  const dispatch: any = useDispatch();
  const handleRequestTour = () => {
    dispatch(requestTour(salonId));
  };

  const requestTourError = useSelector(getRequestTourError);
  const requestTourStatus = useSelector(getRequestTourStatus);

  return (
    <SalonDetailPagePresentation
      chairId={chairId}
      history={history}
      location={location}
      navigationActions={navigationActions}
      salonDetailQueryResult={salonDetailQueryResult}
      bookingStartDate={bookingStartDate}
      bookingEndDate={bookingEndDate}
      onBookingStartDateChange={setBookingStartDate}
      onBookingEndDateChange={setBookingEndDate}
      dateRanges={dateRanges}
      onDateRangesChange={setDateRanges}
      requestTourError={requestTourError}
      requestTourStatus={requestTourStatus}
      onRequestTour={handleRequestTour}
    />
  );
};

export interface SalonDetailPagePresentationProps {
  chairId?: string;
  history: History;
  location: Location;
  navigationActions: NavigationActions;
  salonDetailQueryResult: QueryResult<SalonDetailQuery>;
  bookingStartDate: Moment | null;
  bookingEndDate: Moment | null;
  onBookingStartDateChange: (value: Moment | null) => void;
  onBookingEndDateChange: (value: Moment | null) => void;
  dateRanges: ReadonlyArray<DateRange>;
  onDateRangesChange: (dateRanges: ReadonlyArray<DateRange>) => void;
  requestTourError?: Error;
  requestTourStatus?: string;
  onRequestTour: () => void;
}

export const SalonDetailPagePresentation = (
  props: SalonDetailPagePresentationProps
) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  const {
    chairId,
    history,
    location,
    navigationActions,
    salonDetailQueryResult,
    bookingStartDate,
    bookingEndDate,
    onBookingStartDateChange,
    onBookingEndDateChange,
    dateRanges,
    onDateRangesChange,
    requestTourError,
    requestTourStatus,
    onRequestTour,
  } = props;
  const classes = useStyles();
  const { data, error, loading } = salonDetailQueryResult;
  const salon = data?.salonById;

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error || !salon) {
    return (
      <Typography align="center" color="error" variant="h5">
        <I18nText i18nKey={`common.error`} />
      </Typography>
    );
  }

  const currentChair = salon.chairs.find((c) => c.id === chairId);
  if (!currentChair && salon.chairs.length > 0) {
    return <Redirect to={getUrl(salon.chairs[0].id)} />;
  }

  const handleOnDatePickerChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    if (startDate) {
      onBookingStartDateChange(moment(startDate));
    }

    if (endDate) {
      onBookingEndDateChange(moment(endDate));
    }
  };

  const handleSubmit = () => {
    if (
      chairId &&
      (dateRanges.length > 0 || (bookingStartDate && bookingEndDate))
    ) {
      const checkInDateUrl = toDayUrlParameters("checkInDate", [
        ...dateRanges.map((dr: DateRange) => dr.startDate),
        bookingStartDate,
      ]);
      const checkOutDateUrl = toDayUrlParameters("checkOutDate", [
        ...dateRanges.map((dr: DateRange) => dr.endDate),
        bookingEndDate,
      ]);
      navigationActions.gotoBookingConfirmationPage(
        salon.id,
        chairId,
        checkInDateUrl,
        checkOutDateUrl
      );
    }
  };

  const isDayBlocked = (day: Moment) => {
    const isAvailable = some(currentChair?.availableDates, (availableDate) => {
      return moment.utc(day).isSame(availableDate, "day");
    });
    return !isAvailable;
  };

  const handleChairChange = (e: React.ChangeEvent<{ value: any }>) => {
    history.replace(getUrl(e.target.value));
  };

  const handleDateRangeAdded = (dateRange: DateRange) => {
    onBookingStartDateChange(null);
    onBookingEndDateChange(null);
    onDateRangesChange([...dateRanges, dateRange]);
  };
  const handleDateRangeRemoved = (dateRange: DateRange) => {
    const updatedDateRanges = dateRanges.filter(
      (dr: DateRange) => dr !== dateRange
    );
    onDateRangesChange(updatedDateRanges);
  };

  function toDayUrlParameters(
    key: string,
    values: ReadonlyArray<Moment | null>
  ) {
    return values.reduce((acc, date) => {
      if (!date) {
        return acc;
      }
      const prefix = !!acc ? "&" : "";
      return acc + `${prefix}${key}=${date.format("YYYY-MM-DD")}`;
    }, "");
  }

  function getUrl(currentChairId: string) {
    const queryParams = queryString.parse(location.search);
    const updatedQueryParams = {
      ...queryParams,
      chairId: currentChairId,
    };
    return `${location.pathname}?${queryString.stringify(updatedQueryParams)}`;
  }

  return (
    <div>
      <div className={classes.hero}>
        <Slider
          dots={true}
          lazyLoad="ondemand"
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
        >
          {salon.images.length === 0 && (
            <img
              src="https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_800/v1551749522/siteImageAssets/Bkgrnd683.jpg"
              alt="stock salon"
            />
          )}
          {salon.images.map((image) => {
            const url = getImageUrl(image.publicId, SALON_IMAGE_OPTIONS);
            return <img key={image.publicId} src={url} alt="salon" />;
          })}
        </Slider>
      </div>
      <Container maxWidth="xl">
        <Grid
          className={classes.section}
          container={true}
          justify="space-between"
        >
          <Grid item={true} xs={12} md={5}>
            <Typography variant="subtitle1">{salon.title}</Typography>
            <div className={classes.sectionSpacer}>
              <Typography variant="h3">{salon.name}</Typography>
              <Typography variant="h6">{salon.address}</Typography>
              <Typography variant="h6">
                <a
                  className={classes.phoneNumber}
                  href={`tel:${salon.phoneNumber}`}
                >
                  {salon.phoneNumber}
                </a>
              </Typography>
            </div>
            <div className={classes.spacer}>
              <Button
                color="secondary"
                disabled={
                  requestTourStatus === "pending" ||
                  requestTourStatus === "completed"
                }
                onClick={onRequestTour}
                size="small"
                variant="contained"
              >
                {requestTourStatus === "completed" && <Check />}
                {requestTourStatus === "pending" && (
                  <CircularProgress size={20} />
                )}
                <I18nText i18nKey="pages.salon_details.buttons.request_tour.label" />
              </Button>
              {requestTourError && (
                <Typography color="error">
                  {requestTourError.message}
                </Typography>
              )}
            </div>
            <div className={classes.sectionSpacer}>
              <Typography variant="h5" marked="left">
                <I18nText i18nKey="pages.salon_details.salon_info.book_now_title" />
              </Typography>
              {salon.chairs.length > 1 && (
                <div className={classes.spacer}>
                  <Select value={currentChair?.id} onChange={handleChairChange}>
                    {salon.chairs.map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.title}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
              <div>
                <MultiDateRangePicker
                  dateRanges={dateRanges}
                  endDate={bookingEndDate}
                  focusedInput={focusedInput}
                  isDayBlocked={isDayBlocked}
                  onDateRangeAdded={handleDateRangeAdded}
                  onDateRangeRemoved={handleDateRangeRemoved}
                  onDatesChange={handleOnDatePickerChange}
                  onFocusChange={(input: FocusedInputShape) => {
                    setFocusedInput(input);
                  }}
                  startDate={bookingStartDate}
                />
              </div>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                type="submit"
              >
                <I18nText i18nKey="pages.salon_details.buttons.book.label" />
              </Button>
            </div>

            <div>
              <div className={classes.sectionSpacer}>
                <SalonOperatingHours
                  operatingHours={salon.operatingHours || []}
                />
              </div>
            </div>
          </Grid>
          <Grid item={true} xs={12} md={5}>
            <div>
              <div className={classes.sectionSpacer}>
                <Typography variant="h5" marked="left">
                  <I18nText i18nKey="pages.salon_details.salon_info.chair_price_title" />
                </Typography>
                <Typography className={classes.spacer} variant="body1">
                  {`$${currentChair?.price}`}
                </Typography>
              </div>
              <Typography variant="h5" marked="left">
                <I18nText i18nKey="pages.salon_details.salon_info.description_title" />
              </Typography>
              <Typography className={classes.spacer} variant="body1">
                {salon.description}
              </Typography>
            </div>
            <div className={classes.sectionSpacer}>
              {(salon.socialMediaFacebook ||
                salon.socialMediaTwitter ||
                salon.socialMediaInstagram ||
                salon.socialMediaYelp ||
                salon.socialMediaWebsite) && (
                <React.Fragment>
                  <Typography variant="h5" marked="left">
                    <I18nText i18nKey="pages.salon_details.salon_info.social_media_title" />
                  </Typography>

                  <Grid
                    container={true}
                    justify="flex-start"
                    spacing={2}
                    className={classes.spacer}
                  >
                    <Grid item={true} sm={2} xs={12}>
                      <SocialMediaButton href={salon.socialMediaFacebook}>
                        <I18nText i18nKey="pages.salon_details.social_media_buttons.facebook_label" />
                      </SocialMediaButton>
                    </Grid>
                    <Grid item={true} sm={2} xs={12}>
                      <SocialMediaButton href={salon.socialMediaTwitter}>
                        <I18nText i18nKey="pages.salon_details.social_media_buttons.twitter_label" />
                      </SocialMediaButton>
                    </Grid>
                    <Grid item={true} sm={2} xs={12}>
                      <SocialMediaButton href={salon.socialMediaInstagram}>
                        <I18nText i18nKey="pages.salon_details.social_media_buttons.instagram_label" />
                      </SocialMediaButton>
                    </Grid>
                    <Grid item={true} sm={2} xs={12}>
                      <SocialMediaButton href={salon.socialMediaYelp}>
                        <I18nText i18nKey="pages.salon_details.social_media_buttons.yelp_label" />
                      </SocialMediaButton>
                    </Grid>
                    <Grid item={true} sm={2} xs={12}>
                      <SocialMediaButton href={salon.socialMediaWebsite}>
                        <I18nText i18nKey="pages.salon_details.social_media_buttons.website_label" />
                      </SocialMediaButton>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.middleSection}>
        <Container maxWidth="xl">
          <Grid container={true} justify="space-around" spacing={4}>
            <Grid item={true} xs={12} md={3}>
              <Typography variant="h5" className={classes.lightText}>
                <I18nText i18nKey="pages.salon_details.salon_info.amenities_title" />
              </Typography>
              <Divider className={classes.divider} />
              <ItemList
                itemKey="select_list.salon_amenities"
                list={salon.amenities || []}
              />
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Typography variant="h5" className={classes.lightText}>
                <I18nText i18nKey="pages.salon_details.salon_info.practices_title" />
              </Typography>
              <Divider className={classes.divider} />
              <ItemList
                itemKey="select_list.practices"
                list={salon.practices || []}
              />
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Typography variant="h5" className={classes.lightText}>
                <I18nText i18nKey="pages.salon_details.salon_info.rules_title" />
              </Typography>
              <Divider className={classes.divider} />
              <ItemList itemKey="select_list.rules" list={salon.rules} />
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Typography variant="h5" className={classes.lightText}>
                <I18nText i18nKey="pages.salon_details.salon_info.equipment_title" />
              </Typography>
              <Divider className={classes.divider} />
              <ItemList
                itemKey="select_list.equipment"
                list={salon.equipment || []}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
