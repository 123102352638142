import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import React from "react";

import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";

export type AboutUsStyles = WithStyles<typeof styles>;
export type AboutUsProps = AboutUsStyles;

const styles: any = (theme: Theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },

  footer: {
    alignItems: "center",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_800/v1551749523/siteImageAssets/Bkgrnd715.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: 500,
    justifyContent: "center",
  },

  headline: {
    color: theme.palette.common.white,
    margin: theme.spacing(1),
  },

  hero: {
    alignItems: "center",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_1000/v1551749704/siteImageAssets/about_us.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: 500,
    justifyContent: "center",
  },

  heroContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  spacer: {
    marginBottom: theme.spacing(1),
  },

  sectionThreeHeadline: {
    color: theme.palette.common.white,
    margin: theme.spacing(3),
  },

  subHeadline: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },

  container: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },

  textContainer: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
});

export class AboutUsPageClass extends React.Component<AboutUsProps> {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.hero}>
          <Container className={classes.heroContainer} maxWidth="lg">
            <Typography
              align="center"
              className={classes.headline}
              variant="h1"
            >
              <I18nText i18nKey={"pages.about_us.headline_one"} />
            </Typography>
            <Typography
              align="center"
              className={classes.headline}
              variant="h4"
            >
              <I18nText i18nKey={"pages.about_us.headline_two"} />
            </Typography>
            <Typography
              align="center"
              className={classes.subHeadline}
              variant="subtitle1"
            >
              <I18nText i18nKey={"pages.about_us.sub_headline"} />
            </Typography>
          </Container>
        </div>
        <Container className={classes.container} maxWidth="xl">
          <Grid container={true} justify="center" spacing={4}>
            <Grid item={true} xs={12} md={4}>
              <div className={classes.textContainer}>
                <Typography
                  className={classes.spacer}
                  variant="h5"
                  marked="left"
                >
                  <I18nText
                    i18nKey={
                      "pages.about_us.section_two.row_one.left_column.title"
                    }
                  />
                </Typography>
                <Typography variant="body1">
                  <I18nText
                    i18nKey={
                      "pages.about_us.section_two.row_one.left_column.description"
                    }
                  />
                </Typography>
              </div>
              <div className={classes.textContainer}>
                <Typography
                  className={classes.spacer}
                  variant="h5"
                  marked="left"
                >
                  <I18nText
                    i18nKey={
                      "pages.about_us.section_two.row_one.right_column.title"
                    }
                  />
                </Typography>
                <Typography variant="body1">
                  <I18nText
                    i18nKey={
                      "pages.about_us.section_two.row_one.right_column.description"
                    }
                  />
                </Typography>
              </div>
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <div className={classes.textContainer}>
                <Typography
                  className={classes.spacer}
                  variant="h5"
                  marked="left"
                >
                  <I18nText
                    i18nKey={
                      "pages.about_us.section_two.row_two.left_column.title"
                    }
                  />
                </Typography>
                <Typography variant="body1">
                  <I18nText
                    i18nKey={
                      "pages.about_us.section_two.row_two.left_column.description"
                    }
                  />
                </Typography>
              </div>
              <div className={classes.textContainer}>
                <Typography
                  className={classes.spacer}
                  variant="h5"
                  marked="left"
                >
                  <I18nText
                    i18nKey={
                      "pages.about_us.section_two.row_two.right_column.title"
                    }
                  />
                </Typography>
                <Typography variant="body1">
                  <I18nText
                    i18nKey={
                      "pages.about_us.section_two.row_two.right_column.description"
                    }
                  />
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className={classes.footer}>
          <Container className="" maxWidth="md">
            <Typography
              align="center"
              className={classes.headline}
              variant="h1"
            >
              <strong>
                <I18nText i18nKey={"pages.about_us.section_three.title"} />
              </strong>
            </Typography>
            <Typography
              align="center"
              className={classes.sectionThreeHeadline}
              variant="subtitle1"
            >
              <I18nText i18nKey={"pages.about_us.section_three.description"} />
            </Typography>
          </Container>
        </div>
      </div>
    );
  }
}

export const AboutUsPage = withStyles(styles)(AboutUsPageClass);
