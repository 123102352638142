import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { FormProps } from "form/index";
import { createSelectEventHandlerFactory } from "form/index";
import { getErrorForKey } from "form/index";

import { UserProfileFormState } from "../form/state";

export type UserProfileProfessionalRoleFormProps = FormProps<
  UserProfileFormState,
  keyof UserProfileFormState
>;

enum Roles {
  LicensedCosmetologist,
  Barber,
  SalonOwner,
  Assistant,
  FullTimeEducator,
  ProductSupplier,
}

const createNumberSelectEventHandler = createSelectEventHandlerFactory<
  number
>();

export class UserProfileProfessionalRoleForm extends React.Component<
  UserProfileProfessionalRoleFormProps
> {
  render() {
    const professionalRoleError = getErrorForKey(
      this.props.errors,
      "professionalRole"
    );
    return (
      <FormWrapper
        onPrevious={this.props.onPrevious}
        onSubmit={this.props.onSubmit}
      >
        <Grid item={true} xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="professional-role-select">
              Professional Role
            </InputLabel>
            <Select
              error={!!professionalRoleError}
              onChange={createNumberSelectEventHandler(
                this.props,
                "professionalRole" as any
              )}
              value={this.props.object.professionalRole}
              inputProps={{
                id: "professional-role-select",
                name: "professionalRole",
              }}
            >
              {Object.keys(Roles)
                .filter((key: any) => typeof Roles[key] === "number")
                .map((key: any) => {
                  const value = Roles[key];
                  return (
                    <MenuItem key={key} value={value}>
                      <I18nText
                        i18nKey={`select_list.professional_role.${value}`}
                      />
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </FormWrapper>
    );
  }
}
