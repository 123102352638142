import { useQuery, useMutation } from "@apollo/react-hooks";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { gql, ApolloError } from "apollo-boost";
import moment from "moment";
import React from "react";
import { useState } from "react";

import { I18nText } from "component/I18nText";
import Button from "component/modules/Button";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import SuccessAlert from "component/SuccessAlert";
import { WithNavigationProvidedProps } from "tools/withNavigation";
import { withNavigation } from "tools/withNavigation";

import {
  BookingListingsQuery,
  CancelBookingMutation,
} from "./BookingListingsPage.generated";

export type BookingListingsStyles = WithStyles<typeof styles>;

export type BookingListingsPageProps = BookingListingsStyles &
  WithNavigationProvidedProps<void>;

const BOOKING_LISTINGS_QUERY = gql`
  query BookingListings {
    myBookings {
      id
      cancelled
      startDate
      endDate
      chair {
        salon {
          id
          name
          images {
            url
          }
        }
      }
    }
  }
`;

const CANCEL_BOOKING_MUTATION = gql`
  mutation CancelBooking($input: CancelBookingInput!) {
    cancelBooking(input: $input) {
      booking {
        id
        cancelled
      }
    }
  }
`;

const styles: any = (theme: Theme) => ({
  headline: {
    paddingTop: 20,
  },

  card: {
    width: 350,
  },

  cardMedia: {
    height: 150,
    marginBottom: theme.spacing(2),
  },
});

const BookingListingsClassPage = (props: BookingListingsPageProps) => {
  const { classes } = props;
  const [bookingIdToCancel, setBookingIdToCancel] = useState("");
  const [cancellationError, setCancellationError] = useState<string | null>(
    null
  );
  const state = props.location.state as any;
  const [showSuccessAlert, setShowSuccessAlert] = useState(
    state?.bookingConfirmed
  );
  const refetch = state?.refetch;

  const { data, loading } = useQuery<BookingListingsQuery>(
    BOOKING_LISTINGS_QUERY,
    {
      fetchPolicy: refetch && "network-only",
    }
  );

  const [cancelBooking, { loading: cancelBookingLoading }] = useMutation<
    CancelBookingMutation
  >(CANCEL_BOOKING_MUTATION, {
    onCompleted() {
      setBookingIdToCancel("");
    },
    onError(error: ApolloError) {
      setCancellationError(error.message);
    },
  });

  const bookings = data?.myBookings;

  const handleErrorClose = () => {
    setCancellationError(null);
  };

  const handleConfirmClose = () => {
    setBookingIdToCancel("");
  };

  const handleCancelConfirm = () => {
    if (bookingIdToCancel) {
      cancelBooking({
        variables: { input: { id: bookingIdToCancel } },
      });
    }
  };

  const handleOnCancelPressed = (id: string) => {
    setBookingIdToCancel(id);
  };

  const handleSuccessAlertOnClose = () => {
    setShowSuccessAlert(false);
  };

  return (
    <Container maxWidth="xl">
      <SuccessAlert
        open={showSuccessAlert}
        message={
          <I18nText i18nKey="pages.booking_listings.alert_success_message" />
        }
        onClose={handleSuccessAlertOnClose}
      />
      <Dialog onClose={handleErrorClose} open={!!cancellationError}>
        <DialogTitle>
          <I18nText i18nKey="common.error" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {cancellationError && `Cancellation Error: ${cancellationError}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} color="secondary" autoFocus={true}>
            <I18nText i18nKey="common.ok" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleConfirmClose} open={!!bookingIdToCancel}>
        <DialogTitle>
          <I18nText i18nKey="pages.bookings.titles.confirm_cancel" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <I18nText i18nKey="pages.bookings.body.confirm_cancel" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={handleConfirmClose}
            color="secondary"
          >
            <I18nText i18nKey="common.no" />
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleCancelConfirm}
            color="secondary"
            autoFocus={true}
          >
            <I18nText i18nKey="common.yes" />
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container={true}
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={5}
      >
        <Grid item={true} xs={12}>
          <Typography variant="h4" marked="center" className={classes.headline}>
            <I18nText i18nKey="pages.bookings.headline" />
          </Typography>
        </Grid>

        <Grid item={true} xs={12}>
          {loading && <LoadingIndicator />}
          <List>
            {bookings?.map((booking) => {
              const salon = booking.chair.salon;
              const isFutureBooking = moment().isBefore(booking.endDate);
              return (
                <ListItem key={booking.id}>
                  <Card className={classes.card}>
                    <CardContent
                      onClick={() => {
                        props.navigationActions.gotoSalonDetailsPage(
                          booking.chair.salon.id
                        );
                      }}
                    >
                      <CardMedia
                        className={classes.cardMedia}
                        image={salon.images[0]?.url}
                        title="Salon"
                      />
                      <Typography variant="h5">{salon.name}</Typography>
                      {booking.startDate === booking.endDate ? (
                        <Typography>
                          {moment.utc(booking.startDate).format("LL")}
                        </Typography>
                      ) : (
                        <Typography>
                          {`${moment
                            .utc(booking.startDate)
                            .format("LL")} - ${moment
                            .utc(booking.endDate)
                            .format("LL")}`}
                        </Typography>
                      )}

                      {booking.cancelled && (
                        <Typography variant="h6" color="error">
                          <I18nText i18nKey="pages.bookings.titles.cancelled" />
                        </Typography>
                      )}
                    </CardContent>
                    {!booking.cancelled &&
                      (cancelBookingLoading &&
                      bookingIdToCancel === booking.id ? (
                        <LoadingIndicator size={20} />
                      ) : (
                        isFutureBooking && (
                          <CardActions>
                            <Button
                              onClick={handleOnCancelPressed.bind(
                                null,
                                booking.id
                              )}
                              size="small"
                              color="secondary"
                            >
                              <I18nText i18nKey="pages.bookings.buttons.cancel.label" />
                            </Button>
                          </CardActions>
                        )
                      ))}
                  </Card>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export const BookingListingsPage = withStyles(styles)(
  withNavigation(BookingListingsClassPage)
);
