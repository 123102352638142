import React from "react";

import { SessionActions } from "action/SessionActions";

export interface LoginPageProps {
  sessionActions: SessionActions;
}

export class LoginPage extends React.Component<LoginPageProps> {
  componentDidMount() {
    this.props.sessionActions.login();
  }

  render() {
    return null;
  }
}
