import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import queryString from "query-string";
import React from "react";
import { useLocation } from "react-router-dom";

import { useNavigationActions } from "tools/useNavigationActions";

import {
  StripeCallbackPage_AuthorizePaymentsMutation,
  StripeCallbackPage_AuthorizePaymentsMutationVariables,
} from "./StripeCallbackPage.generated";

const AUTHORIZE_PAYMENTS = gql`
  mutation StripeCallbackPage_AuthorizePayments(
    $input: AuthorizePaymentsInput!
  ) {
    authorizePayments(input: $input) {
      userProfile {
        userId
        bankConnected
      }
    }
  }
`;

export const StripeCallbackPage = () => {
  const navigationActions = useNavigationActions();
  const [authorizePayments] = useMutation<
    StripeCallbackPage_AuthorizePaymentsMutation,
    StripeCallbackPage_AuthorizePaymentsMutationVariables
  >(AUTHORIZE_PAYMENTS, {
    onCompleted: (updatedData) => {
      if (updatedData.authorizePayments?.userProfile) {
        navigationActions.gotoHomePage();
      }
    },
  });

  const location = useLocation();
  React.useEffect(() => {
    const { code } = queryString.parse(location.search);
    authorizePayments({
      variables: { input: { code } },
    });
  }, [authorizePayments, location]);

  return null;
};
