import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { FormProps } from "form/index";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { UserProfileFormState } from "../form/state";

export type UserProfileDetailsFormProps = FormProps<
  UserProfileFormState,
  keyof UserProfileFormState
>;

export class UserProfileDetailsForm extends React.Component<
  UserProfileDetailsFormProps
> {
  render() {
    const emailError = getErrorForKey(this.props.errors, "email");
    const firstNameError = getErrorForKey(this.props.errors, "firstName");
    const lastNameError = getErrorForKey(this.props.errors, "lastName");
    const phoneNumberError = getErrorForKey(this.props.errors, "phoneNumber");
    return (
      <FormWrapper onSubmit={this.props.onSubmit}>
        <Grid item={true} xs={12}>
          <TextField
            error={!!firstNameError}
            fullWidth={true}
            helperText={firstNameError}
            label={
              <I18nText i18nKey="forms.user_profile_details.fields.first_name.label" />
            }
            onChange={createStringEventHandler(this.props, "firstName")}
            value={this.props.object.firstName}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            error={!!lastNameError}
            fullWidth={true}
            helperText={lastNameError}
            label={
              <I18nText i18nKey="forms.user_profile_details.fields.last_name.label" />
            }
            onChange={createStringEventHandler(this.props, "lastName" as any)}
            value={this.props.object.lastName}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            error={!!emailError}
            fullWidth={true}
            helperText={emailError}
            label={
              <I18nText i18nKey="forms.user_profile_details.fields.email.label" />
            }
            onChange={createStringEventHandler(this.props, "email" as any)}
            value={this.props.object.email}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            error={!!phoneNumberError}
            fullWidth={true}
            helperText={phoneNumberError}
            label={
              <I18nText i18nKey="forms.user_profile_details.fields.phone_number.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "phoneNumber" as any
            )}
            value={this.props.object.phoneNumber}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
