import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import React from "react";

import { SearchForm } from "component/SearchForm";

import { HomePageFooter } from "./component/HomePageFooter";
import { HowItWorksSection } from "./component/HowItWorksSection";

export type HomePageStyles = WithStyles<typeof styles>;

export type HomePageProps = HomePageStyles;

const styles: any = (theme: Theme) => ({
  hero: {
    alignItems: "center",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_1200/v1551749526/siteImageAssets/Home_Page_Top.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: 600,
    justifyContent: "center",
  },
});

export class HomePageClass extends React.Component<HomePageProps> {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.hero}>
          <div>
            <SearchForm />
          </div>
        </div>
        <HowItWorksSection />
        <HomePageFooter />
      </div>
    );
  }
}

export const HomePage = withStyles(styles)(HomePageClass);
