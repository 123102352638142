import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { SalonCheckBoxItem } from "component/SalonCheckBoxItem";
import { FormProps } from "form/index";
import { createArrayEventHandler } from "form/index";

import { SalonFormState } from "../../form/state";

export type SalonRulesFormProps = FormProps<
  SalonFormState,
  keyof SalonFormState
>;

const allRules = [0, 1, 2];

export class SalonRulesForm extends React.Component<SalonRulesFormProps> {
  render() {
    const rules = this.props.object.rules;
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_rules.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_rules.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <FormGroup>
            {allRules.map((rule) => {
              return (
                <SalonCheckBoxItem
                  checked={rules.includes(rule)}
                  data-test-id={`rule-${rule}-input`}
                  handleChange={createArrayEventHandler(this.props, "rules", [
                    rule,
                  ])}
                  label={<I18nText i18nKey={`select_list.rules.${rule}`} />}
                  key={rule}
                />
              );
            })}
          </FormGroup>
        </Grid>
      </FormWrapper>
    );
  }
}
