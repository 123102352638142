import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import React from "react";
import { CardElement } from "react-stripe-elements";
import { injectStripe } from "react-stripe-elements";

import { I18nText } from "component/I18nText";
import { LinearLayout } from "component/LinearLayout";
import Button from "component/modules/Button";
import Typography from "component/modules/Typography";

export type BookingCheckoutFormClassStyles = WithStyles<typeof styles>;

export interface InjectStripeProps {
  stripe: any;
}

export interface BookingCheckoutFormClassProps {
  onCancel: () => void;
  onSubmit: (stripe: any) => void;
  pending: boolean;
}

export type BookingCheckoutFormProps = BookingCheckoutFormClassProps &
  InjectStripeProps &
  BookingCheckoutFormClassStyles;

const styles: any = (theme: Theme) => ({
  cardElement: {
    border: "solid",
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    fontSize: theme.typography.fontSize,
    padding: theme.spacing(1),
    maxWidth: 500,
  },
});

export class BookingCheckoutFormClass extends React.Component<
  BookingCheckoutFormProps
> {
  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <LinearLayout orientation="vertical" spacing={2}>
          <label>
            <Typography variant="subtitle1">
              <I18nText i18nKey="pages.booking_confirmation.card_details" />
            </Typography>
            <CardElement className={classes.cardElement} />
          </label>
          <LinearLayout orientation="horizontal" spacing={2}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={this.props.onCancel}
            >
              <I18nText i18nKey="pages.booking_confirmation.buttons.cancel_order.label" />
            </Button>
            <Button
              size="small"
              disabled={this.props.pending}
              type="submit"
              variant="contained"
              color="secondary"
            >
              <I18nText i18nKey="pages.booking_confirmation.buttons.confirm_order.label" />
            </Button>
          </LinearLayout>
        </LinearLayout>
      </form>
    );
  }

  private handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    this.props.onSubmit(this.props.stripe);
  };
}

export const BookingCheckoutForm: React.ComponentType<BookingCheckoutFormClassProps> = injectStripe(
  withStyles(styles)(BookingCheckoutFormClass) as any
);
