import omit from "lodash/omit";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ApolloError } from "apollo-client";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";

import { I18nText } from "component/I18nText";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { UserState } from "model/index";
import { bindActionCreators } from "tools/index";
import { useNavigationActions } from "tools/useNavigationActions";

import { SalonForm } from "./component/SalonForm";
import { CreateSalonFormActions } from "./form/state";
import { CreateOrEditSalonPageState } from "./model";
import { CreateSalonFormState } from "./model";
import { SalonFormState } from "./model";

import {
  EditSalonPage_GetSalonQuery,
  EditSalonPage_GetSalonQueryVariables,
  EditSalonPage_UpdateSalonMutation,
  EditSalonPage_UpdateSalonMutationVariables,
} from "./EditSalonPage.generated";

const salonFragment = gql`
  fragment EditSalonPage_salon on Salon {
    id
    name
    licenseNumber
    licenseState
    phoneNumber
    address
    location {
      longitude
      latitude
    }
    title
    description
    contactName
    contactEmail
    contactPhoneNumber
    images {
      id
      publicId
      url
    }
    amenities
    equipment
    practices
    rules
    restroomCountMen
    restroomCountUnisex
    restroomCountWomen
    operatingHours {
      id
      startMinuteOfWeek
      endMinuteOfWeek
    }
    socialMediaFacebook
    socialMediaInstagram
    socialMediaTwitter
    socialMediaWebsite
    socialMediaYelp
  }
`;

const GET_SALON = gql`
  query EditSalonPage_GetSalon($id: ID!) {
    salonById(id: $id) {
      ...EditSalonPage_salon
    }
  }
  ${salonFragment}
`;

const UPDATE_SALON = gql`
  mutation EditSalonPage_UpdateSalon($input: UpdateSalonInput!) {
    updateSalon(input: $input) {
      salon {
        ...EditSalonPage_salon
      }
    }
  }
  ${salonFragment}
`;

export const EditSalonPageConatiner = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const navigationActions = useNavigationActions();

  const { data, error, loading } = useQuery<
    EditSalonPage_GetSalonQuery,
    EditSalonPage_GetSalonQueryVariables
  >(GET_SALON, {
    variables: { id },
  });

  const [updateSalon, { error: updateSalonError }] = useMutation<
    EditSalonPage_UpdateSalonMutation,
    EditSalonPage_UpdateSalonMutationVariables
  >(UPDATE_SALON, {
    onCompleted: (updatedData) => {
      if (updatedData.updateSalon?.salon) {
        navigationActions.gotoSalonChairsPage(id, { refetch: true });
      }
    },
  });

  const mappedUpdateSalon = (salonFormState: SalonFormState) => {
    const mappedSalon: any = {
      ...omit(salonFormState, ["id", "__typename"]),
      images: salonFormState.images.map((i) => omit(i, ["__typename"])),
      location: omit(salonFormState.location, ["__typename"]),
      operatingHours: salonFormState.operatingHours.map((o) =>
        omit(o, "__typename")
      ),
    };
    updateSalon({
      variables: { input: { id, salon: mappedSalon } },
    });
  };

  const salon = data?.salonById;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error || !salon) {
    return (
      <Typography align="center" color="error" variant="h5">
        <I18nText i18nKey={`common.error`} />
      </Typography>
    );
  }
  return (
    <EditSalonPage
      salon={salon}
      updateSalon={mappedUpdateSalon}
      updateSalonError={updateSalonError}
    />
  );
};

export interface EditSalonPageClassProps {
  salon: SalonFormState;
  updateSalon: (salon: SalonFormState) => void;
  updateSalonError?: ApolloError;
  createSalonFormActions: CreateSalonFormActions;
  createSalonFormState: CreateSalonFormState;
  userState: UserState;
}

export type EditSalonPageProps = EditSalonPageClassProps &
  RouteComponentProps<{ id: string }>;

export class EditSalonPageClass extends React.Component<EditSalonPageProps> {
  componentDidMount() {
    const { salon } = this.props;
    this.props.createSalonFormActions.createObject(salon.id, salon);
  }

  render() {
    return (
      <SalonForm
        salonFormError={this.props.updateSalonError}
        createSalonFormActions={this.props.createSalonFormActions}
        createSalonFormState={this.props.createSalonFormState}
        id={this.props.salon.id}
        url={this.props.match.url}
        userState={this.props.userState}
      />
    );
  }
}

type UnconnectedEditSalonPageProps = Omit<
  EditSalonPageProps,
  "createSalonFormActions" | "createSalonFormState" | "userState"
>;

const mapStateToProps = (state: CreateOrEditSalonPageState) => {
  const { createSalonFormState, userState } = state;
  return {
    createSalonFormState,
    userState,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: UnconnectedEditSalonPageProps
) => {
  const createSalonFormActions = new CreateSalonFormActions(
    props.match.url,
    props.updateSalon
  );
  return {
    createSalonFormActions: bindActionCreators(
      createSalonFormActions,
      dispatch
    ),
  };
};

export const EditSalonPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditSalonPageClass)
);
