import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { FormProps } from "form/index";
import { createNumberEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { SalonFormState } from "../form/state";

export type SalonRestroomsFormProps = FormProps<
  SalonFormState,
  keyof SalonFormState
>;

export class SalonRestroomsForm extends React.Component<
  SalonRestroomsFormProps
> {
  render() {
    const restroomCountMen = isNaN(this.props.object.restroomCountMen)
      ? ""
      : this.props.object.restroomCountMen;
    const restroomCountUnisex = isNaN(this.props.object.restroomCountUnisex)
      ? ""
      : this.props.object.restroomCountUnisex;
    const restroomCountWomen = isNaN(this.props.object.restroomCountWomen)
      ? ""
      : this.props.object.restroomCountWomen;
    const restroomCountMenError = getErrorForKey(
      this.props.errors,
      "restroom_count_men"
    );
    const restroomCountUnisexError = getErrorForKey(
      this.props.errors,
      "restroom_count_unisex"
    );
    const restroomCountWomenError = getErrorForKey(
      this.props.errors,
      "restroom_count_womens"
    );

    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_restrooms.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_restrooms.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="restroom-men-input"
            error={!!restroomCountMenError}
            fullWidth={true}
            helperText={restroomCountMenError}
            label={
              <I18nText i18nKey="forms.salon_restrooms.fields.restroom_count_mens.label" />
            }
            onChange={createNumberEventHandler(this.props, "restroomCountMen")}
            value={restroomCountMen}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="restroom-unisex-input"
            error={!!restroomCountUnisexError}
            fullWidth={true}
            helperText={restroomCountUnisexError}
            label={
              <I18nText i18nKey="forms.salon_restrooms.fields.restroom_count_unisex.label" />
            }
            onChange={createNumberEventHandler(
              this.props,
              "restroomCountUnisex"
            )}
            value={restroomCountUnisex}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="restroom-women-input"
            error={!!restroomCountWomenError}
            fullWidth={true}
            helperText={restroomCountWomenError}
            label={
              <I18nText i18nKey="forms.salon_restrooms.fields.restroom_count_womens.label" />
            }
            onChange={createNumberEventHandler(
              this.props,
              "restroomCountWomen"
            )}
            value={restroomCountWomen}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
