import { ThunkAction } from "action/ThunkAction";
import { uploadImage } from "api/cloudinary";
import { FormActions } from "form/index";
import { FormProps } from "form/index";
import { FormState } from "form/index";
import { FormStep } from "form/index";
import { createForm } from "form/index";
import { findNextStep } from "form/index";
import { findPreviousStep } from "form/index";
import { getFormObject } from "form/index";
import { AppState } from "model/index";
import { Image } from "model/index";

import {
  EditUserProfilePage_StylistLicenseFragment,
  EditUserProfilePage_UserProfileFragment,
} from "../EditUserProfilePage.generated";
import { StylistLicenseForm } from "../component/StylistLicenseForm";
import { UserProfileAboutMeForm } from "../component/UserProfileAboutMeForm";
import { UserProfileDetailsForm } from "../component/UserProfileDetailsForm";
import { UserProfileImagesForm } from "../component/UserProfileImagesForm";
import { UserProfileProfessionalRoleForm } from "../component/UserProfileProfessionalRoleForm";
import { UserProfileSocialMediaForm } from "../component/UserProfileSocialMediaForm";
import { UserProfileSpecialtyTypeForm } from "../component/UserProfileSpecialtyTypeForm";

export interface EditUserProfileState extends AppState {
  editStylistLicenseFormState: EditStylistLicenseFormState;
  editUserProfileFormState: EditUserProfileFormState;
}

type EditUserProfileThunkAction = ThunkAction<void, EditUserProfileState>;

export type UserProfileFormState = EditUserProfilePage_UserProfileFragment;
export type EditUserProfileFormState = FormState<UserProfileFormState>;

export interface UserProfileFormProps
  extends FormProps<UserProfileFormState, keyof UserProfileFormState> {}

export type UserProfileFormStep = FormStep<
  UserProfileFormState,
  keyof UserProfileFormState,
  UserProfileFormProps
>;

export const {
  actions: userProfileFormActions,
  reducer: editUserProfileFormReducer,
} = createForm<UserProfileFormState>();

export const editUserProfileFormSteps: ReadonlyArray<UserProfileFormStep> = [
  {
    component: UserProfileDetailsForm,
    path: "user_profile_details",
  },
  {
    component: UserProfileProfessionalRoleForm,
    path: "user_profile_professional_role",
  },
  {
    component: UserProfileSpecialtyTypeForm,
    path: "user_profile_specialty_types",
  },
  {
    component: StylistLicenseForm,
    path: "user_profile_license",
  },
  {
    component: UserProfileAboutMeForm,
    path: "user_profile_about_me",
  },
  {
    component: UserProfileSocialMediaForm,
    path: "user_profile_social_media",
  },
  {
    component: UserProfileImagesForm,
    path: "user_profile_images",
  },
];

export class EditUserProfileFormActions extends FormActions<
  UserProfileFormState,
  keyof UserProfileFormState
> {
  constructor(
    private readonly rootPath: string,
    private readonly updateUserProfile: any
  ) {
    super(userProfileFormActions);
  }

  moveForward(
    id: string,
    currentStep: UserProfileFormStep
  ): EditUserProfileThunkAction {
    return (dispatch, getState, { navigationActions }) => {
      const userProfile = getFormObject(
        getState().editUserProfileFormState,
        id
      );
      const nextStep = findNextStep(
        editUserProfileFormSteps,
        currentStep,
        userProfile
      );
      if (nextStep) {
        navigationActions.gotoPath(this.rootPath, nextStep.path);
      } else {
        dispatch(this.submit(id, userProfile));
      }
    };
  }

  moveBackward(
    id: string,
    currentStep: UserProfileFormStep
  ): EditUserProfileThunkAction {
    return (dispatch, getState, { navigationActions }) => {
      const userProfile = getFormObject(
        getState().editUserProfileFormState,
        id
      );
      const previousStep = findPreviousStep(
        editUserProfileFormSteps,
        currentStep,
        userProfile
      );
      if (previousStep) {
        navigationActions.gotoPath(this.rootPath, previousStep.path);
      }
    };
  }

  submit(
    id: string,
    userProfile: UserProfileFormState
  ): EditUserProfileThunkAction {
    return async (dispatch) => {
      try {
        await this.updateUserProfile(userProfile);
      } catch (error) {
        dispatch(userProfileFormActions.error(id, error));
      }
    };
  }
}

export type StylistLicenseFormState = EditUserProfilePage_StylistLicenseFragment;
export type EditStylistLicenseFormState = FormState<StylistLicenseFormState>;

export const {
  actions: stylistLicenseFormActions,
  reducer: editStylistLicenseFormReducer,
} = createForm<StylistLicenseFormState>();

export const emptyLicense: EditUserProfilePage_StylistLicenseFragment = {
  expirationDate: undefined,
  id: "0",
  number: "",
  state: 0,
  type: "",
};

export class EditStylistLicenseFormActions extends FormActions<
  StylistLicenseFormState,
  keyof StylistLicenseFormState
> {
  constructor() {
    super(stylistLicenseFormActions);
  }
}

export function resolveFilesAndImages(
  filesAndImages: ReadonlyArray<File | Image>
) {
  return Promise.all(filesAndImages.map(resolveFileOrImage));
}

const uploadFolder = process.env.REACT_APP_CLOUDINARY_USER_UPLOAD_FOLDER || "";
function resolveFileOrImage(fileOrImage: File | Image) {
  if (fileOrImage instanceof File) {
    return uploadImage(fileOrImage, uploadFolder);
  } else {
    return Promise.resolve(fileOrImage);
  }
}
