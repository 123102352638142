import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Location } from "history";
import { Moment } from "moment";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Redirect, useParams, useLocation, useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { gql, ApolloError } from "apollo-boost";

import { I18nText } from "component/I18nText";
import { LinearLayout } from "component/LinearLayout";
import Button from "component/modules/Button";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";

import {
  SalonBookingsPageQuery,
  SalonBookingsPageQueryVariables,
  CancelBookingMutation,
  CancelBookingMutationVariables,
  SalonBookings_BookingsFragment,
} from "./SalonBookingsPage.generated";

export type SalonBookingsPageStyles = WithStyles<typeof styles>;

const bookingFragment = gql`
  fragment SalonBookings_bookings on Booking {
    id
    cancelled
    startDate
    endDate
    totalChargeAmount
  }
`;

const SALON_BOOKINGS_QUERY = gql`
  query SalonBookingsPage($id: ID!, $startDate: Date!, $endDate: Date!) {
    salonById(id: $id) {
      id
      name
      chairs {
        id
        title
        bookings(startDate: $startDate, endDate: $endDate) {
          ...SalonBookings_bookings
        }
      }
      images {
        url
      }
    }
  }
  ${bookingFragment}
`;

const CANCEL_BOOKING_MUTATION = gql`
  mutation CancelBooking($input: CancelBookingInput!) {
    cancelBooking(input: $input) {
      booking {
        id
        cancelled
      }
    }
  }
`;

export type SalonBookingsPageProps = SalonBookingsPageStyles &
  RouteComponentProps;

const styles: any = (theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },

  card: {
    width: 350,
  },

  cardMedia: {
    height: 150,
    marginBottom: theme.spacing(2),
  },
});
const SalonBookingsPageClass = (props: SalonBookingsPageProps) => {
  const { classes } = props;
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ id: string; chairId: string }>();
  const [allBookings, setAllBookings] = useState<any>({});
  const [bookingIdToCancel, setBookingIdToCancel] = useState<string>("");
  const [showCancelled, setShowCancelled] = useState<boolean>(true);
  const [cancellationError, setCancellationError] = useState<string | null>(
    null
  );
  const [queryError, setQueryError] = useState<string | null>(null);

  const salonId = params.id;
  const currentMonth = getMonthFromUrl(location.search);

  const { data, loading } = useQuery<
    SalonBookingsPageQuery,
    SalonBookingsPageQueryVariables
  >(SALON_BOOKINGS_QUERY, {
    variables: {
      id: salonId,
      startDate: moment(currentMonth).startOf("month").toISOString(),
      endDate: moment(currentMonth).endOf("month").toISOString(),
    },
    onCompleted() {
      const bookings = data?.salonById?.chairs.reduce((acc, item) => {
        acc[item.id] = item.bookings;
        return acc;
      }, {} as any);

      setAllBookings(bookings);
    },
    onError(error: ApolloError) {
      setQueryError(error.message);
    },
  });

  const [cancelBooking, { loading: cancelBookingLoading }] = useMutation<
    CancelBookingMutation,
    CancelBookingMutationVariables
  >(CANCEL_BOOKING_MUTATION, {
    onCompleted() {
      setBookingIdToCancel("");
    },
    onError(error: ApolloError) {
      setCancellationError(error.message);
    },
  });

  if (!location.search || !currentMonth.isValid()) {
    return <Redirect to={getUrl(location, { month: moment() })} />;
  }

  const salon = data?.salonById;
  if (!salon) {
    return <LoadingIndicator />;
  }

  const { chairId } = queryString.parse(location.search);
  if (!chairId && salon.chairs.length > 0) {
    return (
      <Redirect to={getUrl(location, { currentChairID: salon.chairs[0].id })} />
    );
  }

  const filteredBookings = allBookings[chairId]?.filter(
    (booking: SalonBookings_BookingsFragment) =>
      (showCancelled || !booking.cancelled) &&
      moment(currentMonth).isSame(booking.endDate, "month")
  );

  const handleChairChange = (e: React.ChangeEvent<{ value: any }>) => {
    history.replace(getUrl(location, { currentChairID: e.target.value }));
  };

  const handleLeftArrowClick = () => {
    const month = getMonthFromUrl(location.search);
    history.replace(
      getUrl(location, {
        month: moment(month).subtract(1, "months"),
      })
    );
  };

  const handleRightArrowClick = () => {
    const month = getMonthFromUrl(location.search);
    history.replace(
      getUrl(location, { month: moment(month).add(1, "months") })
    );
  };

  const handleFilterBookingsButtonClick = () => {
    setShowCancelled(!showCancelled);
  };

  const handleOnCancelPressed = (id: string) => {
    setBookingIdToCancel(id);
  };

  const handleErrorClose = () => {
    setCancellationError(null);
  };

  const handleQueryErrorClose = () => {
    setQueryError(null);
  };

  const handleConfirmClose = () => {
    setBookingIdToCancel("");
  };

  const handleCancelConfirm = () => {
    if (bookingIdToCancel) {
      cancelBooking({
        variables: { input: { id: bookingIdToCancel } },
      });
    }
  };

  function getMonthFromUrl(search: string): Moment {
    const { month } = queryString.parse(search);
    return moment(month);
  }

  function formatMonthForUrl(month: Moment): string {
    return month.format("YYYY-MM");
  }

  function getUrl(
    location: Location,
    {
      currentChairID,
      month,
    }: { currentChairID?: number | string; month?: Moment }
  ) {
    const queryParams = queryString.parse(location.search);
    const updatedQueryParams = { ...queryParams };
    if (currentChairID) {
      updatedQueryParams.chairId = currentChairID;
    }
    if (month) {
      updatedQueryParams.month = formatMonthForUrl(month);
    }
    return `${location.pathname}?${queryString.stringify(updatedQueryParams)}`;
  }

  return (
    <Container className={classes.container} maxWidth="xl">
      <Dialog onClose={handleErrorClose} open={!!cancellationError}>
        <DialogTitle>
          <I18nText i18nKey="common.error" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {cancellationError && `Cancellation Error: ${cancellationError}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} color="secondary" autoFocus={true}>
            <I18nText i18nKey="common.ok" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleQueryErrorClose} open={!!queryError}>
        <DialogTitle>
          <I18nText i18nKey="common.error" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {queryError && `Cancellation Error: ${queryError}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleQueryErrorClose}
            color="secondary"
            autoFocus={true}
          >
            <I18nText i18nKey="common.ok" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleConfirmClose} open={!!bookingIdToCancel}>
        <DialogTitle>
          <I18nText i18nKey="pages.salon_bookings.titles.confirm_cancel" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <I18nText i18nKey="pages.salon_bookings.body.confirm_cancel" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmClose}
            color="secondary"
            variant="contained"
            size="small"
          >
            <I18nText i18nKey="common.no" />
          </Button>
          <Button
            onClick={handleCancelConfirm}
            color="secondary"
            variant="contained"
            size="small"
            autoFocus={true}
          >
            <I18nText i18nKey="common.yes" />
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <LoadingIndicator />}
      <Grid
        container={true}
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={5}
      >
        <Grid item={true} xs={12}>
          <Typography align="center" variant="h4" marked="center">
            <I18nText
              i18nKey="pages.salon_bookings.headline"
              salonName={salon.name}
            />
          </Typography>
        </Grid>
        {salon.chairs.length > 1 && (
          <Grid item={true} xs={12}>
            <Select value={parseInt(chairId, 10)} onChange={handleChairChange}>
              {salon.chairs.map((c: any) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item={true} xs={12}>
          <LinearLayout orientation="horizontal" spacing={2}>
            <KeyboardArrowLeft onClick={handleLeftArrowClick} />
            <Typography variant="h5">
              {currentMonth.format("MMMM YYYY")}
            </Typography>
            <KeyboardArrowRight onClick={handleRightArrowClick} />
          </LinearLayout>
          <LinearLayout orientation="vertical" spacing={2}>
            <Button
              onClick={handleFilterBookingsButtonClick}
              size="small"
              color="secondary"
              variant="contained"
            >
              {showCancelled ? (
                <I18nText i18nKey="pages.salon_bookings.buttons.filter.hide_cancelled" />
              ) : (
                <I18nText i18nKey="pages.salon_bookings.buttons.filter.show_cancelled" />
              )}
            </Button>
          </LinearLayout>
        </Grid>
        <Grid item={true} xs={12}>
          <List>
            {salon && filteredBookings?.length === 0 ? (
              <Typography variant="h5" color="error">
                <I18nText i18nKey="pages.salon_bookings.no_bookings" />
              </Typography>
            ) : (
              filteredBookings?.map((booking: any) => {
                if (booking) {
                  const isFutureBooking = moment().isBefore(booking.endDate);
                  return (
                    <ListItem key={booking.id}>
                      <Card className={classes.card}>
                        <CardContent>
                          {booking.startDate === booking.endDate ? (
                            <Typography variant="h5">
                              {moment.utc(booking.startDate).format("LL")}
                            </Typography>
                          ) : (
                            <Typography variant="h5">
                              {`${moment
                                .utc(booking.startDate)
                                .format("LL")} - ${moment
                                .utc(booking.endDate)
                                .format("LL")}`}
                            </Typography>
                          )}
                          <Typography>
                            {`Total Price: $${booking.totalChargeAmount}`}
                          </Typography>

                          {booking.cancelled && (
                            <Typography variant="h6" color="error">
                              <I18nText i18nKey="pages.bookings.titles.cancelled" />
                            </Typography>
                          )}
                        </CardContent>
                        {!booking.cancelled &&
                          (cancelBookingLoading &&
                          bookingIdToCancel === booking.id ? (
                            <LoadingIndicator size={20} />
                          ) : (
                            isFutureBooking && (
                              <CardActions>
                                <Button
                                  onClick={handleOnCancelPressed.bind(
                                    null,
                                    booking.id
                                  )}
                                  size="small"
                                  color="secondary"
                                >
                                  <I18nText i18nKey="pages.bookings.buttons.cancel.label" />
                                </Button>
                              </CardActions>
                            )
                          ))}
                      </Card>
                    </ListItem>
                  );
                }
                return undefined;
              })
            )}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export const SalonBookingsPage = withRouter(
  withStyles(styles)(SalonBookingsPageClass)
);
