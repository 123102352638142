/// <reference types="@types/googlemaps" />

import React from "react";

import { Location } from "../model/index";

import { MapContext } from "./Map";

interface MapMarkerProps {
  position?: Location;
}

export interface MapMarkerWithMapProps extends MapMarkerProps {
  map?: google.maps.Map;
}

export interface State {
  marker?: google.maps.Marker;
}

export class MapMarkerWithMap extends React.Component<
  MapMarkerWithMapProps,
  State
> {
  constructor(props: MapMarkerWithMapProps) {
    super(props);

    this.state = {
      marker: undefined,
    };
  }

  componentDidUpdate(prevProps: MapMarkerWithMapProps) {
    if (
      this.props.map &&
      this.props.position &&
      (prevProps.map !== this.props.map ||
        prevProps.position !== this.props.position)
    ) {
      let { marker } = this.state;
      if (marker) {
        marker.setMap(null);
      }
      const position = {
        lat: this.props.position.latitude,
        lng: this.props.position.longitude,
      };
      marker = new google.maps.Marker({
        animation: google.maps.Animation.DROP,
        map: this.props.map,
        position: {
          lat: this.props.position.latitude,
          lng: this.props.position.longitude,
        },
      });
      this.props.map.setCenter(position);
      this.props.map.setZoom(16);
      this.setState({ marker });
    }
  }

  render() {
    return null;
  }
}

export const MapMarker = (props: MapMarkerProps) => (
  <MapContext.Consumer>
    {(map) => <MapMarkerWithMap {...props} map={map} />}
  </MapContext.Consumer>
);
