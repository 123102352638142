import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { USStatesDropDownField } from "component/USStatesDropDownField";
import { createSelectEventHandlerFactory } from "form/index";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { SalonFormProps } from "../form/state";

export interface SalonDetailsFormProps extends SalonFormProps {
  username: string;
}

const createNumberSelectEventHandler = createSelectEventHandlerFactory<
  number
>();

export class SalonDetailsForm extends React.Component<SalonDetailsFormProps> {
  render() {
    const nameError = getErrorForKey(this.props.errors, "name");
    const licenseNumberError = getErrorForKey(
      this.props.errors,
      "license_number"
    );
    const licenseStateError = getErrorForKey(
      this.props.errors,
      "license_state"
    );
    const phoneNumberError = getErrorForKey(this.props.errors, "phone_number");
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        headline={<I18nText i18nKey="forms.salon_details.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText
              i18nKey="forms.salon_details.headline"
              name={this.props.username}
            />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="name-input"
            error={!!nameError}
            fullWidth={true}
            helperText={nameError}
            label={<I18nText i18nKey="forms.salon_details.fields.name.label" />}
            onChange={createStringEventHandler(this.props, "name" as any)}
            required={true}
            value={this.props.object.name}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="license-number-input"
            error={!!licenseNumberError}
            fullWidth={true}
            helperText={licenseNumberError}
            label={
              <I18nText i18nKey="forms.salon_details.fields.license_number.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "licenseNumber" as any
            )}
            required={true}
            value={this.props.object.licenseNumber}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <USStatesDropDownField
            error={!!licenseStateError}
            inputLabel="license-state-select"
            inputPropName="licenseState"
            onChange={createNumberSelectEventHandler(
              this.props,
              "licenseState" as any
            )}
            label="forms.salon_details.fields.license_state.label"
            value={this.props.object.licenseState}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="phone-number-input"
            error={!!phoneNumberError}
            fullWidth={true}
            helperText={phoneNumberError}
            label={
              <I18nText i18nKey="forms.salon_details.fields.salon_phone_number.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "phoneNumber" as any
            )}
            required={true}
            value={this.props.object.phoneNumber}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
