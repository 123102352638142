import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { I18nText } from "component/I18nText";

export interface SalonHoursDropDownProps {
  startTime?: string;
  endTime?: string;
  onEndTimeChange: (endTime: string) => void;
  onStartTimeChange: (startTime: string) => void;
}

export const SalonHoursDropDown = (props: SalonHoursDropDownProps) => {
  return (
    <Grid container={true} spacing={5}>
      <Grid item={true}>
        <OperatingHourInput
          label={
            <I18nText i18nKey="forms.salon_operating_hours.fields.open_time.label" />
          }
          onChange={props.onStartTimeChange}
          value={props.startTime}
        />
      </Grid>
      <Grid item={true}>
        <OperatingHourInput
          label={
            <I18nText i18nKey="forms.salon_operating_hours.fields.close_time.label" />
          }
          onChange={props.onEndTimeChange}
          value={props.endTime}
        />
      </Grid>
    </Grid>
  );
};

interface OperatingHourInputProps {
  label: React.ReactNode;
  onChange: (value: string) => void;
  value?: string;
}

export const OperatingHourInput = (props: OperatingHourInputProps) => {
  return (
    <TextField
      label={props.label}
      type="time"
      defaultValue={props.value}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 1800,
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        props.onChange(e.currentTarget.value)
      }
    />
  );
};
