import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { USStatesDropDownField } from "component/USStatesDropDownField";
import { FormProps } from "form/index";
import { getErrorForKey } from "form/index";
import { getFormObject } from "form/index";
import { bindActionCreators } from "tools/index";

import { EditStylistLicenseFormActions } from "../form/state";
import { EditStylistLicenseFormState } from "../form/state";
import { EditUserProfileState } from "../form/state";
import { UserProfileFormState } from "../form/state";
import { StylistLicenseFormState } from "../form/state";
import { emptyLicense } from "../form/state";

const CREATE_LICENSE_ID = "CREATE_LICENSE_ID";

export interface StylistLicenseFormProps
  extends FormProps<UserProfileFormState, keyof UserProfileFormState> {
  editStylistLicenseFormActions: EditStylistLicenseFormActions;
  editStylistLicenseFormState: EditStylistLicenseFormState;
}

export class StylistLicenseFormClass extends React.Component<
  StylistLicenseFormProps
> {
  constructor(props: StylistLicenseFormProps) {
    super(props);
    const licenses = this.props.object.stylistLicenses;
    const license = licenses.length > 0 ? licenses[0] : emptyLicense;
    props.editStylistLicenseFormActions.createObject(
      CREATE_LICENSE_ID,
      license
    );
  }

  render() {
    const license = getFormObject(
      this.props.editStylistLicenseFormState,
      CREATE_LICENSE_ID
    );
    if (!license) {
      return null;
    }
    const licenseExpirationDateError = getErrorForKey(
      this.props.errors,
      "stylistLicense.expirationDate"
    );
    const licenseNumberError = getErrorForKey(
      this.props.errors,
      "stylistLicense.number"
    );
    const licenseStateError = getErrorForKey(
      this.props.errors,
      "stylistLicense.state"
    );
    const licenseTypeError = getErrorForKey(
      this.props.errors,
      "stylistLicense.type"
    );
    return (
      <FormWrapper
        onPrevious={this.props.onPrevious}
        onSubmit={this.handleSubmit}
        headline={<I18nText i18nKey="forms.stylist_license.headline" />}
      >
        <Grid item={true} xs={12}>
          <TextField
            error={!!licenseTypeError}
            fullWidth={true}
            helperText={licenseTypeError}
            label={
              <I18nText i18nKey="forms.stylist_license.fields.type.label" />
            }
            onChange={this.handleUserFieldChange("type")}
            value={license.type}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <USStatesDropDownField
            error={!!licenseStateError}
            inputLabel="license-state-select"
            inputPropName="licenseState"
            onChange={this.handleLicenseStateChange}
            label="forms.stylist_license.fields.state.label"
            value={license.state}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            error={!!licenseNumberError}
            fullWidth={true}
            helperText={licenseNumberError}
            label={
              <I18nText i18nKey="forms.stylist_license.fields.number.label" />
            }
            placeholder="License Number"
            onChange={this.handleUserFieldChange("number")}
            value={license.number}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            error={!!licenseExpirationDateError}
            fullWidth={true}
            helperText={licenseExpirationDateError}
            InputLabelProps={{
              shrink: true,
            }}
            label={
              <I18nText i18nKey="forms.stylist_license.fields.expiration.label" />
            }
            type="date"
            onChange={this.handleExpirationDateChange}
            value={
              license.expirationDate
                ? moment(license.expirationDate).format("YYYY-MM-DD")
                : ""
            }
          />
        </Grid>
      </FormWrapper>
    );
  }
  private handleLicenseStateChange = (
    e: React.ChangeEvent<{ value: string }>
  ) => {
    this.props.editStylistLicenseFormActions.updateField(
      CREATE_LICENSE_ID,
      "state",
      e.target.value
    );
  };

  private handleExpirationDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.props.editStylistLicenseFormActions.updateField(
      CREATE_LICENSE_ID,
      "expirationDate",
      e.currentTarget.value
    );
  };

  private handleUserFieldChange = (key: keyof StylistLicenseFormState) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      this.props.editStylistLicenseFormActions.updateField(
        CREATE_LICENSE_ID,
        key,
        e.currentTarget.value
      );
    };
  };

  private handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    const license = getFormObject(
      this.props.editStylistLicenseFormState,
      CREATE_LICENSE_ID
    );
    if (
      license.id !== emptyLicense.id ||
      license.type ||
      license.number ||
      license.expirationDate
    ) {
      // Only add the license if the user entered something
      this.props.onObjectFieldChange("stylistLicenses", [license]);
    }
    this.props.onSubmit();
  };
}

type UnconnectedStylistLicenseFormProps = Omit<
  StylistLicenseFormProps,
  "editStylistLicenseFormActions" | "editStylistLicenseFormState"
>;

const mapStateToProps = (state: EditUserProfileState) => {
  const { editStylistLicenseFormState } = state;
  return {
    editStylistLicenseFormState,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: UnconnectedStylistLicenseFormProps
) => {
  const editStylistLicenseFormActions = new EditStylistLicenseFormActions();
  return {
    editStylistLicenseFormActions: bindActionCreators(
      editStylistLicenseFormActions,
      dispatch
    ),
  };
};

export const StylistLicenseForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(StylistLicenseFormClass);
