import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import React from "react";
import { gql } from "apollo-boost";

import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";

import { SalonOperatingHours_OperatingHoursFragment } from "./SalonOperatingHours.generated";

export type SalonOperatingHoursStyles = WithStyles<typeof styles>;

export interface SalonOperatingHoursClassProps {
  operatingHours: ReadonlyArray<SalonOperatingHours_OperatingHoursFragment>;
}

export const fragments = {
  operatingHours: gql`
    fragment SalonOperatingHours_operatingHours on OperatingHour {
      endTime
      dayOfWeek
      startTime
    }
  `,
};

export type SalonOperatingHoursProps = SalonOperatingHoursClassProps &
  SalonOperatingHoursStyles;

const styles: any = (theme: Theme) => ({
  descriptionList: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
    maxWidth: 500,
  },

  list: {
    listStyle: "none",
  },
});

export class SalonOperatingHoursClass extends React.Component<
  SalonOperatingHoursProps
> {
  render() {
    const { classes, operatingHours } = this.props;

    return (
      <div>
        <Typography variant="h5" marked="left">
          <I18nText i18nKey="pages.salon_details.salon_info.operating_hours_title" />
        </Typography>
        {operatingHours.map((weekday) => (
          <dl key={weekday.dayOfWeek} className={classes.descriptionList}>
            <Typography component="dt" variant="body1">
              <I18nText i18nKey={`select_list.weekdays.${weekday.dayOfWeek}`} />
            </Typography>
            <Typography component="dd" variant="body1">
              {`${weekday.startTime} - ${weekday.endTime}`}
            </Typography>
          </dl>
        ))}
      </div>
    );
  }
}

export const SalonOperatingHours = withStyles(styles)(SalonOperatingHoursClass);
