import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { SalonCheckBoxItem } from "component/SalonCheckBoxItem";
import { FormProps } from "form/index";
import { createArrayEventHandler } from "form/index";

import { SalonFormState } from "../../form/state";

export type SalonPracticesFormProps = FormProps<
  SalonFormState,
  keyof SalonFormState
>;
const allPractices = [0, 1, 2, 3, 4, 5, 6];

export class SalonPracticesForm extends React.Component<
  SalonPracticesFormProps
> {
  render() {
    const practices = this.props.object.practices;
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_practices.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_practices.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <FormGroup>
            {allPractices.map((practice) => {
              return (
                <SalonCheckBoxItem
                  checked={practices.includes(practice)}
                  data-test-id={`practice-${practice}-input`}
                  handleChange={createArrayEventHandler(
                    this.props,
                    "practices",
                    [practice]
                  )}
                  label={
                    <I18nText i18nKey={`select_list.practices.${practice}`} />
                  }
                  key={practice}
                />
              );
            })}
          </FormGroup>
        </Grid>
      </FormWrapper>
    );
  }
}
