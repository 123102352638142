import { Fetcher } from "api/index";

export function getStripeDashboardUrl() {
  return async (fetcher: Fetcher) => {
    const response = await fetcher("users/dashboard_url", {
      method: "GET",
    });

    const body = await response.json();
    return body.url;
  };
}
