import { SessionActionsType } from "action/SessionActions";
import { AppState } from "model/index";
import { SessionState } from "model/index";
import { SessionStatus } from "model/index";

import {
  AUTH_COMPLETED,
  AUTH_ERRORED,
  LOGOUT_USER_COMPLETED,
  RENEW_TOKEN_REQUESTED,
} from "constant/redux";

export function getSessionState(state: AppState) {
  return state.sessionState;
}

export function getSessionStatus(state: AppState) {
  const { renewPending, token } = getSessionState(state);
  if (token) {
    return SessionStatus.AUTHED;
  }
  if (renewPending) {
    return SessionStatus.AUTH_PENDING;
  }
  return SessionStatus.UNAUTHED;
}

const initialState: SessionState = {
  renewPending: true,
};

export function sessionReducer(
  state: SessionState = initialState,
  action: SessionActionsType
): SessionState {
  switch (action.type) {
    case AUTH_COMPLETED: {
      return Object.assign({}, state, {
        profile: action.payload && action.payload.idTokenPayload,
        renewPending: false,
        token: action.payload && action.payload.accessToken,
      });
    }
    case AUTH_ERRORED: {
      return Object.assign({}, state, {
        renewPending: false,
        token: undefined,
      });
    }
    case LOGOUT_USER_COMPLETED: {
      return Object.assign({}, state, { token: undefined });
    }
    case RENEW_TOKEN_REQUESTED: {
      return Object.assign({}, state, { renewPending: true });
    }
    default: {
      return state;
    }
  }
}
