import React from "react";

import { LinearLayout } from "component/LinearLayout";
import Typography from "component/modules/Typography";

export interface HowItWorksItemProps {
  description: React.ReactNode;
  icon: React.ReactNode;
  title: React.ReactNode;
}

export class HowItWorksItem extends React.Component<HowItWorksItemProps> {
  render() {
    const { description, icon, title } = this.props;
    return (
      <LinearLayout orientation="horizontal" spacing={2}>
        {icon}
        <LinearLayout orientation="vertical" spacing={2}>
          <Typography variant="h5" marked="left">
            {title}
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </LinearLayout>
      </LinearLayout>
    );
  }
}
