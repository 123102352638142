export enum Weekday {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum SessionStatus {
  UNAUTHED,
  AUTH_PENDING,
  AUTHED,
}

export type FormError = Readonly<{
  field: string;
  value: string;
}>;

export type Image = Readonly<{
  publicId: string;
  url: string;
}>;

export type Location = Readonly<{
  longitude: number;
  latitude: number;
}>;

export type Booking = Readonly<{
  cancelled: boolean;
  chairId: number;
  endDate: string;
  id: number;
  salonId: number;
  startDate: string;
  userId: number;
}>;

export type BlockedDate = Readonly<{
  chairId: number;
  endDate: string;
  id: number;
  startDate: string;
}>;

export type BookingPrice = Readonly<{
  chairCost: string;
  guestFee: string;
  totalPrice: string;
}>;

export type Chair = Readonly<{
  amenities: ReadonlyArray<number>;
  description: string;
  id: number;
  price: string;
  squareFootage: number;
  stationCount: number;
  title: string;
  type: ChairType;
}>;

export enum ChairType {
  Chair,
  PrivateSuite,
  EventSpace,
}

export enum SalonStatus {
  PENDING,
  APPROVED,
  TESTING,
}

export type OperatingHours = Readonly<{
  endTime: string;
  day: Weekday;
  startTime: string;
}>;

export type Salon = Readonly<{
  address: string;
  amenities: ReadonlyArray<number>;
  chairs: ReadonlyArray<Chair>;
  contactEmail: string;
  contactName: string;
  contactPhoneNumber: string;
  description: string;
  equipment: ReadonlyArray<number>;
  id: number;
  images: ReadonlyArray<Image>;
  licenseNumber: string;
  licenseState: number;
  location?: Location;
  name: string;
  operatingHours: ReadonlyArray<OperatingHours>;
  phoneNumber: string;
  practices: ReadonlyArray<number>;
  restroomCountMen: number;
  restroomCountUnisex: number;
  restroomCountWomen: number;
  rules: ReadonlyArray<number>;
  socialMediaFacebook: string;
  socialMediaInstagram: string;
  socialMediaTwitter: string;
  socialMediaWebsite: string;
  socialMediaYelp: string;
  status: SalonStatus;
  title: string;
}>;

export type EntityState = Readonly<{
  blockedDates: { [id: number]: BlockedDate };
  bookings: { [id: number]: Booking };
  salons: { [id: number]: Salon };
  userProfiles: { [id: number]: UserProfile };
}>;

export type SessionProfile = Readonly<{
  email: string;
  family_name: string;
  given_name: string;
}>;

export type SessionState = Readonly<{
  profile?: SessionProfile;
  renewPending: boolean;
  token?: string;
}>;

export type User = Readonly<{
  id: number;
  profile: UserProfile;
}>;

export type UserProfile = Readonly<{
  aboutMeDescription?: string;
  bankConnected: boolean;
  email: string;
  firstName: string;
  images: ReadonlyArray<Image>;
  lastName: string;
  licenses: ReadonlyArray<StylistLicense>;
  phoneNumber?: string;
  professionalRole?: number;
  socialMediaFacebook: string;
  socialMediaInstagram: string;
  socialMediaTwitter: string;
  socialMediaWebsite: string;
  socialMediaYelp: string;
  specialtyTypes: ReadonlyArray<number>;
  userId: string;
}>;

export type StylistLicense = Readonly<{
  expirationDate?: string;
  id: number;
  number: string;
  state: number;
  type: string;
}>;

export type UserState = Readonly<{
  error?: Error;
  pending: boolean;
  userProfile?: UserProfile;
}>;

export type AppState = Readonly<{
  entityState: EntityState;
  sessionState: SessionState;
  userState: UserState;
}>;
