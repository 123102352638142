import React from "react";
import { matchPath } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { SessionActions } from "action/SessionActions";
import { SessionStatus } from "model/index";

import { AuthRedirect } from "./AuthRedirect";
import LoadingIndicator from "./modules/LoadingIndicator";

interface GatekeeperProps {
  children?: React.ReactNode;
  publicUrls?: ReadonlyArray<string>;
  sessionStatus: SessionStatus;
  sessionActions: SessionActions;
}

export const Gatekeeper = (props: GatekeeperProps) => {
  const { pathname } = useLocation();
  const { publicUrls = [] } = props;
  if (props.sessionStatus === SessionStatus.AUTH_PENDING) {
    return <LoadingIndicator />;
  } else if (
    props.sessionStatus === SessionStatus.AUTHED ||
    publicUrls.reduce((acc: boolean, url: string) => {
      return acc || !!matchPath(pathname, url);
    }, false)
  ) {
    // TODO: This is legit. Type defs are wrong.
    // tslint:disable-next-line:no-any
    return props.children as any;
  } else {
    return <AuthRedirect sessionActions={props.sessionActions} />;
  }
};

Gatekeeper.displayName = "Gatekeeper";
