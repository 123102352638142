import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { SalonCheckBoxItem } from "component/SalonCheckBoxItem";
import { createArrayEventHandler } from "form/index";
import { createNumberEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { ChairFormProps } from "../form/state";

export type PrivateSuiteDetailsFormProps = ChairFormProps;

const allAmenities = [0, 1];

export class PrivateSuiteDetailsForm extends React.Component<
  PrivateSuiteDetailsFormProps
> {
  render() {
    const amenities = this.props.object.amenities;
    const squareFootage = this.props.object.squareFootage ?? "";
    const stationCount = this.props.object.stationCount ?? "";
    const squareFootageError = getErrorForKey(
      this.props.errors,
      "squareFootage"
    );
    const stationCountError = getErrorForKey(this.props.errors, "stationCount");
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={
          <I18nText i18nKey="forms.private_suite_detail.fields.workspace_stations.label" />
        }
      >
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="station-count-input"
            error={!!stationCountError}
            id="number"
            InputLabelProps={{
              shrink: true,
            }}
            label={
              <I18nText i18nKey="forms.private_suite_detail.fields.number_of_stations.label" />
            }
            margin="normal"
            onChange={createNumberEventHandler(
              this.props,
              "stationCount" as any
            )}
            type="number"
            value={stationCount}
          />
        </Grid>
        <Divider />
        <Grid item={true} xs={12}>
          <Typography gutterBottom={true}>
            <I18nText i18nKey="forms.private_suite_detail.fields.workspace_options.label" />
          </Typography>
          {allAmenities.map((amenity) => (
            <SalonCheckBoxItem
              checked={amenities.includes(amenity)}
              data-test-id={`amenity-${amenity}-input`}
              handleChange={createArrayEventHandler(this.props, "amenities", [
                amenity,
              ])}
              label={
                <I18nText i18nKey={`select_list.chair_amenities.${amenity}`} />
              }
              key={amenity}
            />
          ))}
        </Grid>
        <Divider />
        <Grid item={true} xs={12}>
          <Typography gutterBottom={true}>
            <I18nText i18nKey="forms.private_suite_detail.fields.workspace_square_footage.heading" />
          </Typography>
          <TextField
            data-test-id="square-footage-input"
            error={!!squareFootageError}
            id="number"
            InputLabelProps={{
              shrink: true,
            }}
            label={
              <I18nText i18nKey="forms.private_suite_detail.fields.workspace_square_footage.label" />
            }
            margin="normal"
            onChange={createNumberEventHandler(
              this.props,
              "squareFootage" as any
            )}
            type="number"
            value={squareFootage}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
