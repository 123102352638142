import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";

import { SessionActions } from "action/SessionActions";
import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { SessionState } from "model/index";

export interface SignUpPageProps {
  sessionActions: SessionActions;
  sessionState: SessionState;
}

export interface State {
  email: string;
  firstName: string;
  lastName: string;
}

export class SignUpPage extends React.Component<SignUpPageProps, State> {
  constructor(props: SignUpPageProps) {
    super(props);

    const { sessionState } = props;
    this.state = {
      email: (sessionState.profile && sessionState.profile.email) || "",
      firstName:
        (sessionState.profile && sessionState.profile.given_name) || "",
      lastName:
        (sessionState.profile && sessionState.profile.family_name) || "",
    };
  }

  render() {
    return (
      <FormWrapper
        onSubmit={this.handleSubmit}
        headline={<I18nText i18nKey="forms.sign_up.title" />}
      >
        <Grid item={true} xs={12}>
          <TextField
            label={<I18nText i18nKey="forms.sign_up.fields.first_name.label" />}
            onChange={this.handleFirstNameChange}
            required={true}
            value={this.state.firstName}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            label={<I18nText i18nKey="forms.sign_up.fields.last_name.label" />}
            onChange={this.handleLastNameChange}
            required={true}
            value={this.state.lastName}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            label={<I18nText i18nKey="forms.sign_up.fields.email.label" />}
            onChange={this.handleEmailChange}
            required={true}
            value={this.state.email}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Typography>
            <I18nText i18nKey="forms.sign_up.legal.part_one" />
            <Link to={"/terms-of-use"}>
              <I18nText i18nKey="forms.sign_up.legal.link_one" />
            </Link>
          </Typography>
          <Typography>
            <I18nText i18nKey="forms.sign_up.legal.part_two" />
            <Link to={"/terms-of-use"}>
              <I18nText i18nKey="forms.sign_up.legal.link_two" />
            </Link>
          </Typography>
        </Grid>
      </FormWrapper>
    );
  }

  private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.currentTarget.value });
  };

  private handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: e.currentTarget.value });
  };

  private handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: e.currentTarget.value });
  };

  private handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    this.props.sessionActions.finishSignUp({
      bankConnected: false,
      email: this.state.email,
      firstName: this.state.firstName,
      images: [],
      lastName: this.state.lastName,
      licenses: [],
      socialMediaFacebook: "",
      socialMediaInstagram: "",
      socialMediaTwitter: "",
      socialMediaWebsite: "",
      socialMediaYelp: "",
      specialtyTypes: [],
    });
  };
}
