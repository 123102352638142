import { ThunkAction } from "action/ThunkAction";
import { FormProps } from "form/index";
import { FormStep } from "form/index";
import { createForm } from "form/index";
import { findNextStep } from "form/index";
import { findPreviousStep } from "form/index";
import { getFormObject } from "form/index";
import { ChairType } from "types.generated";

import { ChairFormState } from "../model";
import { CreateOrEditChairPageState } from "../model";
import { ChairDetailsForm } from "../component/ChairDetailsForm";
import { PrivateSuiteDetailsForm } from "../component/PrivateSuiteDetailsForm";
import { SquareFootageForm } from "../component/SquareFootageForm";

type CreateChairThunkAction = ThunkAction<void, CreateOrEditChairPageState>;

export interface ChairFormProps
  extends FormProps<ChairFormState, keyof ChairFormState> {}

export type ChairFormStep = FormStep<
  ChairFormState,
  keyof ChairFormState,
  ChairFormProps
>;

export const {
  actions: chairFormActions,
  reducer: createChairFormReducer,
} = createForm<ChairFormState>();

export const createChairFormSteps: ReadonlyArray<ChairFormStep> = [
  {
    component: ChairDetailsForm,
    path: "chair_details",
  },
  {
    component: PrivateSuiteDetailsForm,
    path: "private_suite_details",
    show: (chair: ChairFormState) => chair.type === ChairType.PrivateSuite,
  },
  {
    component: SquareFootageForm,
    path: "square_footage",
    show: (chair: ChairFormState) => chair.type === ChairType.EventSpace,
  },
];

export const emptyChair: ChairFormState = {
  amenities: [],
  description: "",
  id: "0",
  price: "",
  squareFootage: 0,
  stationCount: 0,
  title: "",
  type: ChairType.Chair,
};

export class CreateChairFormActions {
  constructor(
    private readonly rootPath: string,
    private readonly submitChair: any
  ) {}

  createObject(id: string, initialObject: ChairFormState) {
    return chairFormActions.createObject(id, initialObject);
  }

  updateField(
    id: string,
    key: keyof ChairFormState,
    value: ChairFormState[keyof ChairFormState]
  ) {
    return chairFormActions.updateField(id, key, value);
  }

  moveForward(id: string, currentStep: ChairFormStep): CreateChairThunkAction {
    return (dispatch, getState, { navigationActions }) => {
      const chair = getFormObject(getState().createChairFormState, id);
      const nextStep = findNextStep(createChairFormSteps, currentStep, chair);
      if (nextStep) {
        navigationActions.gotoPath(this.rootPath, nextStep.path);
      } else {
        dispatch(this.submit(id, chair));
      }
    };
  }

  moveBackward(id: string, currentStep: ChairFormStep): CreateChairThunkAction {
    return (dispatch, getState, { navigationActions }) => {
      const chair = getFormObject(getState().createChairFormState, id);
      const previousStep = findPreviousStep(
        createChairFormSteps,
        currentStep,
        chair
      );
      if (previousStep) {
        navigationActions.gotoPath(this.rootPath, previousStep.path);
      }
    };
  }

  submit(id: string, chair: ChairFormState): CreateChairThunkAction {
    return async (dispatch) => {
      try {
        await this.submitChair(chair);
      } catch (error) {
        dispatch(chairFormActions.error(id, error));
      }
    };
  }
}
