import React from "react";

import Button from "component/modules/Button";

interface SocialMediaButtonProps {
  children?: React.ReactNode;
  href?: string;
}

const SocialMediaButton = (props: SocialMediaButtonProps) => {
  return (
    (props.href && (
      <Button
        href={props.href}
        target="_blank"
        variant="contained"
        color="secondary"
        size="small"
      >
        {props.children}
      </Button>
    )) ||
    null
  );
};

SocialMediaButton.displayName = "SocialMediaButton";

export { SocialMediaButton };
