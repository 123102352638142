import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { Map } from "component/Map";
import { MapMarker } from "component/MapMarker";
import Button from "component/modules/Button";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";
import { Location } from "model/index";

import { SalonFormProps } from "../form/state";

export type SalonAddressFormStyles = WithStyles<typeof styles>;

export type SalonAddressFormProps = SalonFormProps & SalonAddressFormStyles;

const styles: any = () => ({
  map: {
    height: "400px",
  },
});

const US_CENTER = {
  lat: 39.5,
  lng: -98.35,
};
const US_ZOOM = 4;

export class SalonAddressFormClass extends React.Component<
  SalonAddressFormProps
> {
  render() {
    const { classes } = this.props;
    const addressError = getErrorForKey(this.props.errors, "address");
    const locationError = getErrorForKey(this.props.errors, "location");
    const addressFormatText = (
      <I18nText i18nKey="forms.salon_address.fields.address.help_text" />
    );
    const position = isLocationValid(this.props.object.location)
      ? this.props.object.location
      : undefined;
    return (
      <FormWrapper
        onSubmit={this.handleSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_address.title" />}
      >
        <Grid item={true} xs={9}>
          <TextField
            data-test-id="address-input"
            error={!!addressError || !!locationError}
            fullWidth={true}
            helperText={addressError || locationError || addressFormatText}
            label={
              <I18nText i18nKey="forms.salon_address.fields.address.label" />
            }
            onChange={createStringEventHandler(this.props, "address" as any)}
            required={true}
            value={this.props.object.address}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            data-test-id="address-resolve-submit"
            onClick={this.handleGeocoding}
            variant="contained"
            color="secondary"
          >
            <I18nText i18nKey="forms.salon_address.buttons.locate.label" />
          </Button>
        </Grid>
        <Grid item={true} xs={12}>
          <div className={classes.map}>
            <Map center={US_CENTER} zoom={US_ZOOM}>
              <MapMarker position={position} />
            </Map>
          </div>
        </Grid>
      </FormWrapper>
    );
  }

  private handleGeocoding = () => {
    this.props.onGeocodeRequest(this.props.object.address);
  };

  private handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    if (!isLocationValid(this.props.object.location)) {
      this.handleGeocoding();
      return;
    }
    this.props.onSubmit(e);
  };
}

export const SalonAddressForm = withStyles(styles)(SalonAddressFormClass);

function isLocationValid(location: Location) {
  return (
    location.latitude >= -90 &&
    location.latitude <= 90 &&
    location.longitude >= -180 &&
    location.longitude <= 180
  );
}
