import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { SalonFormProps } from "../form/state";

export type SalonContactFormProps = SalonFormProps;

export class SalonContactForm extends React.Component<SalonContactFormProps> {
  render() {
    const contactNameError = getErrorForKey(this.props.errors, "contact_name");
    const contactEmailError = getErrorForKey(
      this.props.errors,
      "contact_email"
    );
    const contactPhoneNumberError = getErrorForKey(
      this.props.errors,
      "contact_phone_number"
    );
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_contact.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_contact.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="contact-name-input"
            error={!!contactNameError}
            fullWidth={true}
            helperText={contactNameError}
            label={<I18nText i18nKey="forms.salon_contact.fields.name.label" />}
            onChange={createStringEventHandler(
              this.props,
              "contactName" as any
            )}
            required={true}
            value={this.props.object.contactName}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="contact-email-input"
            error={!!contactEmailError}
            fullWidth={true}
            helperText={contactEmailError}
            label={
              <I18nText i18nKey="forms.salon_contact.fields.email.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "contactEmail" as any
            )}
            required={true}
            value={this.props.object.contactEmail}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="contact-phone-number-input"
            error={!!contactPhoneNumberError}
            fullWidth={true}
            helperText={contactPhoneNumberError}
            label={
              <I18nText i18nKey="forms.salon_contact.fields.phone_number.label" />
            }
            placeholder="Contact Phone Number"
            onChange={createStringEventHandler(
              this.props,
              "contactPhoneNumber" as any
            )}
            required={true}
            value={this.props.object.contactPhoneNumber}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
