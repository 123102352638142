import { AnyAction, Store } from "redux";
import { applyMiddleware } from "redux";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { ExtraArgument } from "action/ThunkAction";
import { AppState } from "model/index";
import { reducer } from "reducer/index";

// tslint:disable-next-line:no-any
declare var module: any;

export function configureStore(
  initialState: Partial<AppState>,
  extraArgument: ExtraArgument
): Store<AppState, AnyAction> {
  let middleware = applyMiddleware(thunk.withExtraArgument(extraArgument));

  if (process.env.NODE_ENV === "development") {
    middleware = composeWithDevTools(middleware);
  }

  const store = createStore(reducer, initialState, middleware);

  if (module.hot) {
    module.hot.accept("../reducer/index", () => {
      store.replaceReducer(require("../reducer/index").reducer);
    });
  }

  return store as Store<AppState>;
}
