import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";

export interface SalonCheckBoxItemProps {
  checked: boolean;
  handleChange: (value: boolean) => void;
  label: React.ReactNode;
}

export const SalonCheckBoxItem = (props: SalonCheckBoxItemProps) => {
  const {
    checked,
    handleChange,
    label,
    ...other
  } = props as SalonCheckBoxItemProps;
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...other}
          checked={checked}
          onChange={(_: any, c: boolean) => handleChange(c)}
        />
      }
      label={label}
    />
  );
};
