import React from "react";

import { SessionActions } from "action/SessionActions";

export interface Auth0CallbackPageProps {
  sessionActions: SessionActions;
}

export class Auth0LoginCallbackPage extends React.Component<
  Auth0CallbackPageProps
> {
  componentDidMount() {
    this.props.sessionActions.handleLoginAuthentication();
  }

  render() {
    return null;
  }
}

export class Auth0SignUpCallbackPage extends React.Component<
  Auth0CallbackPageProps
> {
  componentDidMount() {
    this.props.sessionActions.handleSignUpAuthentication();
  }

  render() {
    return null;
  }
}
