import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React from "react";

import { I18nText } from "component/I18nText";
import { LinearLayout } from "component/LinearLayout";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { PageContainer } from "component/PageContainer";

import { AdminSalonListingsQuery } from "./AdminPage.generated";
import { SalonList, fragments } from "./component/SalonList";

const ADMIN_SALON_LISTINGS_QUERY = gql`
  query AdminSalonListings {
    pendingSalons {
      id
      ...SalonList_salon
    }
  }
  ${fragments.salon}
`;

export const AdminPage = () => {
  const salonsListingsQueryResult = useQuery<AdminSalonListingsQuery>(
    ADMIN_SALON_LISTINGS_QUERY,
    {
      fetchPolicy: "no-cache",
    }
  );
  return (
    <AdminPagePresentation
      salonsListingsQueryResult={salonsListingsQueryResult}
    />
  );
};

export type AdminPagePresentationProps = Readonly<{
  salonsListingsQueryResult: QueryResult<AdminSalonListingsQuery>;
}>;

export const AdminPagePresentation = (props: AdminPagePresentationProps) => {
  const { salonsListingsQueryResult } = props;
  const { data, error, loading } = salonsListingsQueryResult;
  return (
    <PageContainer>
      <LinearLayout orientation="vertical" spacing={2}>
        <Typography align="center" variant="h4" marked="center">
          <I18nText i18nKey="forms.admin.headline" />
        </Typography>
        {loading && <LoadingIndicator />}
        {error && (
          <Typography align="center" color="error" variant="h5">
            <I18nText i18nKey={`common.error`} />
          </Typography>
        )}
        {data?.pendingSalons && <SalonList salons={data.pendingSalons} />}
      </LinearLayout>
    </PageContainer>
  );
};
