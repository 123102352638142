import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

export type TermsOfUsePageProps = WithStyles<typeof styles>;

const styles = (theme: Theme) => ({
  body: { paddingBottom: theme.spacing(2) },
  container: {
    margin: theme.spacing(2),
  },
});

const TermsOfUsePageFunction: React.FunctionComponent<TermsOfUsePageProps> = (
  props
) => {
  const classes = props.classes;
  const { t } = useTranslation();
  const terms: Array<{
    headline: string;
    body: string;
  }> = t("pages.terms_of_use.content", { returnObjects: true });
  return (
    <div className={classes.container}>
      <Grid container={true} spacing={5} justify="center" direction="column">
        <Grid item={true} xs={12}>
          {terms.map(({ headline, body }, index) => (
            <React.Fragment key={index}>
              <Typography variant="h5" component="h3">
                {headline}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.body}
              >
                {body}
              </Typography>
            </React.Fragment>
          ))}
          <Typography variant="body1" component="p" align="center">
            {t("pages.terms_of_use.footer.body")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const TermsOfUsePage = withStyles(styles)(TermsOfUsePageFunction);
