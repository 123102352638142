import { Auth0DecodedHash } from "auth0-js";
import { Auth0Error } from "auth0-js";
import { Location } from "history";
import { Dispatch } from "redux";

import { Auth } from "Auth";
import { UserProfile } from "model/index";
import { ActionsUnion } from "tools/redux";
import { createAction } from "tools/redux";

import { ThunkAction } from "./ThunkAction";
import { UserActions } from "./UserActions";

import {
  AUTH_COMPLETED,
  AUTH_ERRORED,
  LOGOUT_USER_COMPLETED,
  RENEW_TOKEN_REQUESTED,
} from "constant/redux";

export const AUTH_REDIRECT_STORAGE_KEY = "auth_redirect";

export function loadAuthRedirect(): string | undefined {
  try {
    return localStorage.getItem(AUTH_REDIRECT_STORAGE_KEY) || undefined;
  } catch (e) {
    // Fail silently
    return undefined;
  }
}

export function clearAuthRedirect(): void {
  try {
    localStorage.removeItem(AUTH_REDIRECT_STORAGE_KEY);
  } catch (e) {
    // Fail silently
  }
}

export function saveAuthRedirect(location: Location): void {
  try {
    localStorage.setItem(
      AUTH_REDIRECT_STORAGE_KEY,
      `${location.pathname}${location.search}`
    );
  } catch (e) {
    // Fail silently
  }
}

const SessionActionCreators = {
  authCompleted: (authResult?: Auth0DecodedHash) =>
    createAction(AUTH_COMPLETED, authResult),
  authErrored: (error: Auth0Error) => createAction(AUTH_ERRORED, error),
  logoutUserCompleted: () => createAction(LOGOUT_USER_COMPLETED),
  renewTokenRequested: () => createAction(RENEW_TOKEN_REQUESTED),
};
export type SessionActionsType = ActionsUnion<typeof SessionActionCreators>;

export class SessionActions {
  constructor(
    private readonly auth: Auth,
    private readonly userActions: UserActions
  ) {}

  login() {
    return async (dispatch: Dispatch) => {
      this.auth.login();
    };
  }

  loginWithRedirect(): ThunkAction {
    return async (dispatch, _, { history }) => {
      saveAuthRedirect(history.location);
      this.auth.login();
    };
  }

  startSignUp() {
    return async (dispatch: Dispatch) => {
      this.auth.signUp();
    };
  }

  finishSignUp(user: Omit<UserProfile, "userId">): ThunkAction {
    return async (dispatch, getState, { navigationActions }) => {
      await dispatch(this.userActions.create(user));
      const userState = getState().userState;
      if (userState && userState.userProfile) {
        navigationActions.gotoHomePage();
      }
    };
  }

  handleLoginAuthentication(): ThunkAction {
    return async (dispatch, _, { navigationActions }) => {
      try {
        const authResult = await this.auth.handleLoginAuthentication();
        dispatch(SessionActionCreators.authCompleted(authResult));
        await dispatch(this.userActions.getMeWithError());
        const redirect = loadAuthRedirect();
        clearAuthRedirect();
        if (redirect) {
          navigationActions.gotoPath(redirect);
        } else {
          navigationActions.gotoHomePage();
        }
      } catch (error) {
        navigationActions.gotoSignUpPage();
      }
    };
  }

  handleSignUpAuthentication(): ThunkAction {
    return async (dispatch, _, { navigationActions }) => {
      try {
        const authResult = await this.auth.handleSignUpAuthentication();
        dispatch(SessionActionCreators.authCompleted(authResult));
        navigationActions.gotoSignUpPage();
      } catch (error) {
        dispatch(SessionActionCreators.authErrored(error));
      }
    };
  }

  logout(): ThunkAction {
    return async () => {
      this.auth.logout();
    };
  }

  clearToken() {
    return SessionActionCreators.logoutUserCompleted();
  }

  renewToken(): ThunkAction {
    return async (dispatch, getState, { history }) => {
      try {
        dispatch(SessionActionCreators.renewTokenRequested());
        const authResult = await this.auth.checkSession();
        dispatch(SessionActionCreators.authCompleted(authResult));
      } catch (error) {
        dispatch(SessionActionCreators.authErrored(error));
      }
    };
  }
}
