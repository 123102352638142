import { ReducersMapObject } from "redux";

import { editSalonProfileFormReducer } from "./form/state";

export function getReducer(): ReducersMapObject {
  return {
    editSalonProfileFormState: editSalonProfileFormReducer,
  };
}

export { EditSalonProfilePageConatiner as EditSalonProfilePage } from "./EditSalonProfilePage";
