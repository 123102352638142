import { ThunkAction } from "action/ThunkAction";
import { uploadImage } from "api/cloudinary";
import { FormActions } from "form/index";
import { FormProps } from "form/index";
import { FormStep } from "form/index";
import { createForm } from "form/index";
import { findNextStep } from "form/index";
import { findPreviousStep } from "form/index";
import { getFormObject } from "form/index";
import { Image } from "model/index";

import { SalonAddressForm } from "../component/SalonAddressForm";
import { SalonContactForm } from "../component/SalonContactForm";
import { SalonDescriptionForm } from "../component/SalonDescriptionForm";
import { SalonDetailsForm } from "../component/SalonDetailsForm";
import { SalonImagesForm } from "../component/SalonImagesForm";
import { CreateOrEditSalonPageState } from "../model";
import { SalonFormState } from "../model";

type CreateSalonThunkAction = ThunkAction<void, CreateOrEditSalonPageState>;

export interface SalonFormProps
  extends FormProps<SalonFormState, keyof SalonFormState> {
  onGeocodeRequest: (address: string) => void;
  username?: string;
}

export type SalonFormStep = FormStep<
  SalonFormState,
  keyof SalonFormState,
  SalonFormProps
>;

export const {
  actions: salonFormActions,
  reducer: createSalonFormReducer,
} = createForm<SalonFormState>();

export const createSalonFormSteps: ReadonlyArray<SalonFormStep> = [
  {
    component: SalonDetailsForm,
    path: "salon_details",
  },
  {
    component: SalonAddressForm,
    path: "salon_address",
  },
  {
    component: SalonDescriptionForm,
    path: "salon_description",
  },
  {
    component: SalonContactForm,
    path: "salon_contact",
  },
  {
    component: SalonImagesForm,
    path: "salon_images",
  },
];

export const emptySalon: SalonFormState = {
  address: "",
  contactEmail: "",
  contactName: "",
  contactPhoneNumber: "",
  description: "",
  id: "0",
  images: [],
  licenseNumber: "",
  licenseState: 0,
  location: {
    longitude: 9999,
    latitude: 9999,
  },
  name: "",
  phoneNumber: "",
  title: "",
  amenities: [],
  equipment: [],
  practices: [],
  rules: [],
  restroomCountMen: 0,
  restroomCountUnisex: 0,
  restroomCountWomen: 0,
  operatingHours: [],
};

export class CreateSalonFormActions extends FormActions<
  SalonFormState,
  keyof SalonFormState
> {
  constructor(
    private readonly rootPath: string,
    private readonly submitSalon: any
  ) {
    super(salonFormActions);
  }

  updateAddressAndLocation(
    id: string,
    address: string
  ): CreateSalonThunkAction {
    return async (dispatch, getState, { history }) => {
      const result = await resolveAddress(address);
      const location = {
        longitude: result.center[0],
        latitude: result.center[1],
      };
      dispatch(this.updateField(id, "location", location));
      dispatch(this.updateField(id, "address", result.place_name));
    };
  }

  moveForward(id: string, currentStep: SalonFormStep): CreateSalonThunkAction {
    return (dispatch, getState, { navigationActions }) => {
      const salon = getFormObject(getState().createSalonFormState, id);
      const nextStep = findNextStep(createSalonFormSteps, currentStep, salon);
      if (nextStep) {
        navigationActions.gotoPath(this.rootPath, nextStep.path);
      } else {
        dispatch(this.submit(id, salon));
      }
    };
  }

  moveBackward(id: string, currentStep: SalonFormStep): CreateSalonThunkAction {
    return (dispatch, getState, { navigationActions }) => {
      const salon = getFormObject(getState().createSalonFormState, id);
      const previousStep = findPreviousStep(
        createSalonFormSteps,
        currentStep,
        salon
      );
      if (previousStep) {
        navigationActions.gotoPath(this.rootPath, previousStep.path);
      }
    };
  }

  submit(id: string, salon: SalonFormState): CreateSalonThunkAction {
    return async (dispatch) => {
      try {
        await this.submitSalon(salon);
      } catch (error) {
        dispatch(salonFormActions.error(id, error));
      }
    };
  }
}

export function resolveFilesAndImages(
  filesAndImages: ReadonlyArray<File | Image>
) {
  return Promise.all(filesAndImages.map(resolveFileOrImage));
}

const uploadFolder = process.env.REACT_APP_CLOUDINARY_SALON_UPLOAD_FOLDER || "";
function resolveFileOrImage(fileOrImage: File | Image) {
  if (fileOrImage instanceof File) {
    return uploadImage(fileOrImage, uploadFolder);
  } else {
    return Promise.resolve(fileOrImage);
  }
}

export type Feature = Readonly<{
  center: [number, number];
  place_name: string;
}>;

export async function resolveAddress(address: string): Promise<Feature> {
  const response = await fetch(
    encodeURI(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?autocomplete=false&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
    )
  );
  const json = await response.json();
  if (!response.ok || json?.features.length === 0) {
    throw new Error("Geocode failed");
  }
  return json.features[0];
}
