import partial from "lodash/partial";

import { ApolloError } from "apollo-client";
import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";

import { FormErrorMessage } from "component/FormErrorMessage";
import { ObjectFieldChangeCallback } from "form/index";
import { getFormObject } from "form/index";
import { FormError } from "model/index";
import { UserState } from "model/index";

import { CreateSalonFormActions } from "../form/state";
import { SalonFormStep } from "../form/state";
import { createSalonFormSteps } from "../form/state";
import { CreateSalonFormState } from "../model";
import { SalonFormState } from "../model";

// Need to think about some names since SalonFormProps was already taken
export interface SalonFormComponentProps {
  salonFormError?: ApolloError;
  createSalonFormActions: CreateSalonFormActions;
  createSalonFormState: CreateSalonFormState;
  id: string;
  url: string;
  userState: UserState;
}

export class SalonForm extends React.Component<SalonFormComponentProps> {
  render() {
    const validationErrors: ReadonlyArray<FormError> =
      this.props.salonFormError?.graphQLErrors.reduce((acc, graphQLError) => {
        return acc.concat(
          Object.entries(graphQLError.extensions!).map(([field, value]) => {
            return { field, value };
          })
        );
      }, [] as Array<FormError>) ?? [];
    return (
      <span>
        <Route exact={true} path={this.props.url} render={this.mainRedirect} />
        {this.props.salonFormError && (
          <FormErrorMessage error={this.props.salonFormError} />
        )}
        <Switch>
          {createSalonFormSteps.map((formStep, i) => {
            return (
              <Route
                key={formStep.path}
                path={`${this.props.url}/${formStep.path}`}
                render={this.createFormStep(formStep, validationErrors)}
              />
            );
          })}
        </Switch>
      </span>
    );
  }

  private mainRedirect = () => {
    return <Redirect to={`${this.props.url}/salon_details`} />;
  };

  private createFormStep(
    formStep: SalonFormStep,
    errors: ReadonlyArray<FormError>
  ) {
    const onSalonFieldChange: ObjectFieldChangeCallback<
      SalonFormState,
      keyof SalonFormState
    > = partial(
      this.props.createSalonFormActions.updateField,
      this.props.id
    ) as any;
    const onSalonGeocodeRequest = partial(
      this.props.createSalonFormActions.updateAddressAndLocation,
      this.props.id
    );
    const moveForward = (e?: React.FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      this.props.createSalonFormActions.moveForward(this.props.id, formStep);
    };
    const moveBackward = (e?: React.FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      this.props.createSalonFormActions.moveBackward(this.props.id, formStep);
    };
    return () => {
      const salon = getFormObject(
        this.props.createSalonFormState,
        this.props.id
      );
      if (!salon) {
        return null;
      }
      const { component: Component } = formStep;
      return (
        <Component
          errors={errors}
          object={salon}
          onGeocodeRequest={onSalonGeocodeRequest}
          onObjectFieldChange={onSalonFieldChange}
          onPrevious={moveBackward}
          onSubmit={moveForward}
          username={
            (this.props.userState.userProfile &&
              this.props.userState.userProfile.email) ||
            ""
          }
        />
      );
    };
  }
}
