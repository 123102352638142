import orderBy from "lodash/orderBy";

import { useQuery } from "@apollo/react-hooks";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { gql } from "apollo-boost";
import queryString from "query-string";
import React from "react";

import { Center } from "component/Center";
import { I18nText } from "component/I18nText";
import { SearchForm } from "component/SearchForm";
import { WithNavigationProvidedProps } from "tools/withNavigation";
import { withNavigation } from "tools/withNavigation";

import {
  SalonSearchResultsQuery,
  SalonSearchResultsQueryVariables,
} from "./SalonSearchResultsPage.generated";

export type SalonSearchResultPageStyles = WithStyles<typeof styles>;

export type SalonSearchResultPageProps = SalonSearchResultPageStyles &
  WithNavigationProvidedProps<void>;

const SALON_SEARCH_QUERY = gql`
  query SalonSearchResults(
    $northEast: LocationInput!
    $southWest: LocationInput!
    $startDate: Date
    $endDate: Date
    $cursor: String
  ) {
    searchSalons(
      northEast: $northEast
      southWest: $southWest
      startDate: $startDate
      endDate: $endDate
      after: $cursor
      limit: 10
    ) {
      edges {
        node {
          id
          name
          title
          address
          images {
            url
          }
          chairs {
            price
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

const styles: any = (theme: Theme) => ({
  card: {
    margin: theme.spacing(2),
  },

  media: {
    height: 200,
    objectFit: "cover",
  },

  spacer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
});

export const SalonSearchResultsPageClass = (
  props: SalonSearchResultPageProps
) => {
  const { classes } = props;
  const {
    checkInDate,
    checkOutDate,
    name,
    northEast: northEastString,
    southWest: southWestString,
  } = queryString.parse(props.location.search);
  const northEast = parseLocation(northEastString);
  const southWest = parseLocation(southWestString);

  const { data, loading, fetchMore } = useQuery<
    SalonSearchResultsQuery,
    SalonSearchResultsQueryVariables
  >(SALON_SEARCH_QUERY, {
    variables: {
      northEast,
      southWest,
      startDate: checkInDate,
      endDate: checkOutDate,
    },
  });
  const searchSalons = data?.searchSalons;

  const handleSalonClick = (id: number) => {
    props.navigationActions.gotoSalonDetailsPage(id);
  };
  const handleLoadMoreClick = () => {
    fetchMore({
      variables: {
        cursor: searchSalons?.pageInfo.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.searchSalons.edges;
        const pageInfo = fetchMoreResult?.searchSalons.pageInfo;

        return newEdges?.length
          ? {
              searchSalons: {
                __typename: previousResult.searchSalons.__typename,
                edges: [...previousResult.searchSalons.edges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };
  return (
    <Grid container={true} justify="center" spacing={5}>
      <Grid item={true} xs={12}>
        <SearchForm
          checkInDate={checkInDate || ""}
          checkOutDate={checkOutDate || ""}
          name={name}
          northEast={northEast}
          southWest={southWest}
        />
      </Grid>
      <Grid item={true} xs={12}>
        {searchSalons?.edges.length ? (
          searchSalons.edges.map(({ node: salon }: any) => {
            const lowestChairPrice =
              salon.chairs.length > 0
                ? `$${orderBy(salon.chairs, "price", ["asc"])[0].price}`
                : "$--";
            return (
              <Card
                onClick={() => handleSalonClick(salon.id)}
                className={classes.card}
                key={salon.id}
              >
                <CardMedia
                  className={classes.media}
                  component="img"
                  src={
                    salon.images && salon.images.length > 0
                      ? salon.images[0].url
                      : "https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_400/v1551749522/siteImageAssets/Bkgrnd324.jpg"
                  }
                  title="Salon Image"
                />
                <CardContent>
                  <Typography className={classes.spacer} variant="h2">
                    {salon.name}
                  </Typography>
                  <Typography className={classes.spacer} variant="subtitle1">
                    {salon.title}
                  </Typography>
                  <Typography className={classes.spacer} component="p">
                    {salon.address}
                  </Typography>
                  <Typography className={classes.spacer} variant="body1">
                    {lowestChairPrice}
                  </Typography>
                </CardContent>
              </Card>
            );
          })
        ) : (
          <Center>
            <Typography variant="h5">
              <I18nText i18nKey="pages.search_results.no_results" />
            </Typography>
          </Center>
        )}
      </Grid>
      {loading && (
        <Grid item={true} xs={1}>
          <CircularProgress />
        </Grid>
      )}
      {searchSalons?.pageInfo.hasNextPage && (
        <Grid item={true} xs={6}>
          <Button
            color="secondary"
            variant="contained"
            fullWidth={true}
            onClick={handleLoadMoreClick}
          >
            <I18nText i18nKey="common.load_more" />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export const SalonSearchResultsPage = withStyles(styles)(
  withNavigation(SalonSearchResultsPageClass)
);

function parseLocation(queryParam: string) {
  const [longitude, latitude] = queryParam.split(",");
  return {
    latitude: parseFloat(latitude),
    longitude: parseFloat(longitude),
  };
}
