import { Dispatch } from "redux";

export function bindActionCreators<T>(actions: T, dispatch: Dispatch) {
  const boundActions: any = {};
  let prototype = Object.getPrototypeOf(actions);
  do {
    for (const key of Object.getOwnPropertyNames(prototype)) {
      const actionCreator = prototype[key];
      if (actionCreator instanceof Function) {
        boundActions[key] = (...args: Array<any>) =>
          dispatch(actionCreator.call(actions, ...args));
      }
    }
    /* eslint-disable no-cond-assign */
  } while ((prototype = Object.getPrototypeOf(prototype)));
  return boundActions as T;
}
