import { ReducersMapObject } from "redux";

import { createSalonFormReducer } from "./form/state";

export function getReducer(): ReducersMapObject {
  return {
    createSalonFormState: createSalonFormReducer,
  };
}

export { CreateSalonPageConatiner as CreateSalonPage } from "./CreateSalonPage";
export { EditSalonPageConatiner as EditSalonPage } from "./EditSalonPage";
