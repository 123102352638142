import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { gql } from "apollo-boost";
import React from "react";

import { I18nText } from "component/I18nText";
import Button from "component/modules/Button";
import Typography from "component/modules/Typography";
import { SalonStatus } from "types.generated";

import { SalonDetail_SalonFragment } from "./SalonDetail.generated";

export const fragments = {
  salon: gql`
    fragment SalonDetail_salon on Salon {
      id
      name
      title
      address
      phoneNumber
      status
      licenseNumber
      licenseState
      contactName
      contactEmail
      contactPhoneNumber
      amenities
      equipment
      practices
      rules
      description
      restroomCountMen
      restroomCountUnisex
      restroomCountWomen
      socialMediaFacebook
      socialMediaInstagram
      socialMediaTwitter
      socialMediaWebsite
      socialMediaYelp
      chairs {
        price
      }
      images {
        url
      }
    }
  `,
};

export type SalonDetailProps = Readonly<{
  salon: SalonDetail_SalonFragment;
  onUpdateSalonStatus: (id: string, status: SalonStatus) => void;
}>;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: 345,
  },

  cardMedia: {
    height: 200,
  },

  spacer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

export const SalonDetail = (props: SalonDetailProps) => {
  const classes = useStyles();
  const { salon } = props;
  const handleSalonAppoval = (e: React.MouseEvent<HTMLElement>) => {
    props.onUpdateSalonStatus(salon.id, SalonStatus.Approved);
  };

  const handleSalonTesting = (e: React.MouseEvent<HTMLElement>) => {
    props.onUpdateSalonStatus(salon.id, SalonStatus.Testing);
  };

  return (
    <div>
      <Typography
        align="center"
        className={classes.spacer}
        variant="h3"
        component="h3"
      >
        {salon.name}
      </Typography>
      <Grid container={true} className={classes.spacer} spacing={5}>
        <Grid item={true} xs={12}>
          <Typography variant="body1" component="pre">
            {JSON.stringify(salon, null, 2)}
          </Typography>
        </Grid>
        <Grid item={true} xs={6}>
          <Button
            className={classes.spacer}
            onClick={handleSalonAppoval}
            disabled={salon.status === SalonStatus.Approved}
            color="secondary"
            variant="contained"
          >
            <I18nText i18nKey="forms.admin.buttons.approve.label" />
          </Button>
        </Grid>
        <Grid item={true} xs={6}>
          <Button
            className={classes.spacer}
            onClick={handleSalonTesting}
            disabled={salon.status === SalonStatus.Testing}
            color="secondary"
            variant="contained"
          >
            <I18nText i18nKey="forms.admin.buttons.testing.label" />
          </Button>
        </Grid>
        <Grid item={true} xs={12}>
          {salon.images.map((image) => {
            return (
              <div key={image.url} className={classes.spacer}>
                <Card className={classes.card}>
                  <CardMedia image={image.url} className={classes.cardMedia} />
                </Card>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};
