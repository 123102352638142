import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";

import { UnstyledLink } from "../UnstyledLink";

import logo from "./assets/logo.png";

export type ChairedAppBarStyles = WithStyles<typeof styles>;

interface ChairedAppBarProps {
  homepagePath: string;
  toggleMenu: () => void;
}

const styles: any = () => ({
  logo: {
    height: "35px",
  },
});

const ChairedAppBarInternal: React.FunctionComponent<
  ChairedAppBarProps & ChairedAppBarStyles
> = ({ classes, homepagePath, toggleMenu }) => (
  <AppBar color="primary" position="static">
    <Toolbar>
      <IconButton color="inherit" onClick={toggleMenu}>
        <MenuIcon />
      </IconButton>
      <UnstyledLink to={homepagePath}>
        <img src={logo} alt="Chaired" className={classes.logo} />
      </UnstyledLink>
    </Toolbar>
  </AppBar>
);

export const ChairedAppBar = withStyles(styles)(ChairedAppBarInternal);
