import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { createNumberEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { ChairFormProps } from "../form/state";

export type SquareFootageFormProps = ChairFormProps;

export class SquareFootageForm extends React.Component<SquareFootageFormProps> {
  render() {
    const squareFootage = this.props.object.squareFootage ?? "";
    const squareFootageError = getErrorForKey(
      this.props.errors,
      "squareFootage"
    );
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={
          <I18nText i18nKey="forms.event_space.fields.workspace_square_footage.heading" />
        }
      >
        <Grid item={true} xs={12}>
          <TextField
            error={!!squareFootageError}
            id="number"
            InputLabelProps={{
              shrink: true,
            }}
            label={
              <I18nText i18nKey="forms.event_space.fields.workspace_square_footage.label" />
            }
            margin="normal"
            onChange={createNumberEventHandler(
              this.props,
              "squareFootage" as any
            )}
            type="number"
            value={squareFootage}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
