import Grid from "@material-ui/core/Grid";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { MultiImageSelect } from "component/MultiImageSelect";
import { FormProps } from "form/index";
import { Image } from "model/index";

import { UserProfileFormState } from "../form/state";
import { resolveFilesAndImages } from "../form/state";

export type UserProfileImagesFormProps = FormProps<
  UserProfileFormState,
  keyof UserProfileFormState
>;

export class UserProfileImagesForm extends React.Component<
  UserProfileImagesFormProps
> {
  render() {
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
      >
        <Grid item={true} xs={12}>
          <MultiImageSelect
            images={this.props.object.images}
            onImagesAdded={this.handleImagesAdded}
            onImageRemoved={this.handleImageRemoved}
          />
        </Grid>
      </FormWrapper>
    );
  }

  private handleImagesAdded = (files: FileList) => {
    const filesAndImages = [...this.props.object.images, ...Array.from(files)];
    this.props.onObjectFieldChange(
      "images",
      filesAndImages,
      resolveFilesAndImages as any
    );
  };

  private handleImageRemoved = (image: Image) => {
    const images = this.props.object.images.filter((i) => i !== image);
    this.props.onObjectFieldChange("images", images);
  };
}
