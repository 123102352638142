import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { SalonCheckBoxItem } from "component/SalonCheckBoxItem";
import { FormProps } from "form/index";
import { createArrayEventHandler } from "form/index";

import { SalonFormState } from "../form/state";

export type SalonAmenitiesFormProps = FormProps<
  SalonFormState,
  keyof SalonFormState
>;
const allAmenities = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export class SalonAmenitiesForm extends React.Component<
  SalonAmenitiesFormProps
> {
  render() {
    const amenities = this.props.object.amenities;
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_amenities.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_amenities.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <FormGroup>
            {allAmenities.map((amenity) => {
              return (
                <SalonCheckBoxItem
                  checked={amenities.includes(amenity)}
                  data-test-id={`amenity-${amenity}-input`}
                  handleChange={createArrayEventHandler(
                    this.props,
                    "amenities",
                    [amenity]
                  )}
                  label={
                    <I18nText
                      i18nKey={`select_list.salon_amenities.${amenity}`}
                    />
                  }
                  key={amenity}
                />
              );
            })}
          </FormGroup>
        </Grid>
      </FormWrapper>
    );
  }
}
