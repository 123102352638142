import React from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { Location } from "model/index";
import { WithNavigationProvidedProps } from "tools/withNavigation";
import { withNavigation } from "tools/withNavigation";

import { GeocoderInput } from "./GeocoderInput";
import { I18nText } from "./I18nText";
import Button from "./modules/Button";

export type SearchFormStyleProps = WithStyles<typeof styles>;

export interface SearchFormClassProps {
  checkInDate?: string;
  checkOutDate?: string;
  name?: string;
  northEast?: Location;
  southWest?: Location;
}

export interface State {
  checkInDate?: string;
  checkOutDate?: string;
  name?: string;
  northEast?: Location;
  southWest?: Location;
}

export type SearchFormProps = SearchFormClassProps &
  SearchFormStyleProps &
  WithNavigationProvidedProps<void>;

const styles = (theme: Theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
  textField: {
    minWidth: 300,
  },
  location: {
    marginTop: theme.spacing(2),
    minWidth: 300,
  },
});

export class SearchFormClass extends React.Component<SearchFormProps, State> {
  constructor(props: SearchFormProps) {
    super(props);

    const { checkInDate, checkOutDate, name, northEast, southWest } = props;
    this.state = {
      checkInDate: checkInDate || "",
      checkOutDate: checkOutDate || "",
      name,
      northEast,
      southWest,
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <Container maxWidth="xl">
          <Grid
            container={true}
            alignItems="center"
            direction="column"
            spacing={4}
          >
            <Grid item={true} xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  container={true}
                  justify="center"
                  alignItems="center"
                  spacing={6}
                >
                  <Grid item={true} xs={12} md={4}>
                    <GeocoderInput
                      initialValue={this.props.name}
                      onPlaceChange={this.handlePlaceChange}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={4}>
                    <TextField
                      className={classes.textField}
                      id="date"
                      InputLabelProps={{ shrink: true }}
                      label={
                        <I18nText i18nKey="forms.search.fields.start_date.label" />
                      }
                      onChange={this.handleStartDateChange}
                      type="date"
                      value={this.state.checkInDate}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={4}>
                    <TextField
                      className={classes.textField}
                      id="date"
                      InputLabelProps={{ shrink: true }}
                      label={
                        <I18nText i18nKey="forms.search.fields.end_date.label" />
                      }
                      onChange={this.handleEndDateChange}
                      type="date"
                      value={this.state.checkOutDate}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item={true} xs={12}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                type="submit"
              >
                <I18nText i18nKey="forms.search.buttons.submit.label" />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    );
  }

  private handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checkInDate: e.target.value });
  };

  private handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ checkOutDate: e.target.value });
  };

  private handlePlaceChange = ({ result }: any) => {
    const bbox = result.bbox;
    if (!bbox || bbox.length < 4) {
      return;
    }
    const name = result.place_name;
    const northEast = {
      longitude: bbox[2],
      latitude: bbox[3],
    };
    const southWest = {
      longitude: bbox[0],
      latitude: bbox[1],
    };
    this.setState({ name, northEast, southWest });
  };

  private handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    const {
      checkInDate,
      checkOutDate,
      name,
      northEast,
      southWest,
    } = this.state;
    if (name && northEast && southWest) {
      const searchParams = new URLSearchParams();
      searchParams.set("name", name);
      searchParams.set(
        "northEast",
        `${northEast.longitude},${northEast.latitude}`
      );
      searchParams.set(
        "southWest",
        `${southWest.longitude},${southWest.latitude}`
      );
      if (!!checkInDate) {
        searchParams.set("checkInDate", checkInDate);
      }
      if (!!checkOutDate) {
        searchParams.set("checkOutDate", checkOutDate);
      }
      this.props.navigationActions.gotoSearchFormResultsPage(
        `/search_results?${searchParams.toString()}`
      );
    }
  };
}

export const SearchForm = withStyles(styles)(
  withNavigation<SearchFormProps>(SearchFormClass)
);
