import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { FormProps } from "form/index";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { UserProfileFormState } from "../form/state";

export type UserProfileSocialMediaFormProps = FormProps<
  UserProfileFormState,
  keyof UserProfileFormState
>;

export class UserProfileSocialMediaForm extends React.Component<
  UserProfileSocialMediaFormProps
> {
  render() {
    const socialMediaFacebookError = getErrorForKey(
      this.props.errors,
      "socialMediaFacebook"
    );
    const socialMediaInstagramError = getErrorForKey(
      this.props.errors,
      "socialMediaInstagram"
    );
    const socialMediaTwitterError = getErrorForKey(
      this.props.errors,
      "socialMediaTwitter"
    );
    const socialMediaWebsiteError = getErrorForKey(
      this.props.errors,
      "socialMediaWebsite"
    );
    const socialMediaYelpError = getErrorForKey(
      this.props.errors,
      "socialMediaYelp"
    );

    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={
          <I18nText i18nKey="forms.user_profile_social_media.headline" />
        }
      >
        <Grid item={true} xs={12}>
          <TextField
            error={!!socialMediaFacebookError}
            fullWidth={true}
            helperText={socialMediaFacebookError}
            label={
              <I18nText i18nKey="forms.user_profile_social_media.fields.facebook.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaFacebook" as any
            )}
            value={this.props.object.socialMediaFacebook}
          />
          <TextField
            error={!!socialMediaInstagramError}
            fullWidth={true}
            helperText={socialMediaInstagramError}
            label={
              <I18nText i18nKey="forms.user_profile_social_media.fields.instagram.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaInstagram" as any
            )}
            value={this.props.object.socialMediaInstagram}
          />
          <TextField
            error={!!socialMediaTwitterError}
            fullWidth={true}
            helperText={socialMediaTwitterError}
            label={
              <I18nText i18nKey="forms.user_profile_social_media.fields.twitter.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaTwitter" as any
            )}
            value={this.props.object.socialMediaTwitter}
          />
          <TextField
            error={!!socialMediaWebsiteError}
            fullWidth={true}
            helperText={socialMediaWebsiteError}
            label={
              <I18nText i18nKey="forms.user_profile_social_media.fields.website.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaWebsite" as any
            )}
            value={this.props.object.socialMediaWebsite}
          />
          <TextField
            error={!!socialMediaYelpError}
            fullWidth={true}
            helperText={socialMediaYelpError}
            label={
              <I18nText i18nKey="forms.user_profile_social_media.fields.yelp.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaYelp" as any
            )}
            value={this.props.object.socialMediaYelp}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
