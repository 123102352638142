import Input from "@material-ui/core/Input";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

import { I18nText } from "component/I18nText";
import Button from "component/modules/Button";
import Typography from "component/modules/Typography";
import { Image } from "model/index";

export type MultiImageSelectStyles = WithStyles<typeof styles>;

export interface MultiImageSelectClassProps {
  images: ReadonlyArray<Image>;
  onImagesAdded: (files: FileList) => void;
  onImageRemoved: (image: Image) => void;
}

export type MultiImageSelectProps = MultiImageSelectClassProps &
  MultiImageSelectStyles;

const styles: any = (theme: Theme) => ({
  spacer: {
    marginRight: theme.spacing(2),
  },
});

export class MultiImageSelectClass extends React.Component<
  MultiImageSelectProps
> {
  render() {
    return (
      <List>
        {this.buildImageInputs()}
        <ListItem>
          <Input type="file" onChange={this.handleInputChange} value="" />
        </ListItem>
      </List>
    );
  }

  private buildImageInputs() {
    return this.props.images.map((image, index) => {
      const { classes } = this.props;
      return (
        <ListItem key={index}>
          <Typography className={classes.spacer}>
            <I18nText
              i18nKey="forms.salon_images.image_label"
              index={`${index + 1}`}
            />
          </Typography>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => this.props.onImageRemoved(image)}
          >
            <I18nText i18nKey="forms.image_selector.buttons.remove.label" />
          </Button>
        </ListItem>
      );
    });
  }

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      this.props.onImagesAdded(e.target.files);
    }
  };
}

export const MultiImageSelect = withStyles(styles)(MultiImageSelectClass);
