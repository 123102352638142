import { Image } from "model/index";

const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";

export async function uploadImage(file: File, folder: string): Promise<Image> {
  const formData = new FormData();
  formData.append("upload_preset", uploadPreset);
  formData.append("folder", folder);
  formData.append("file", file);

  const response = await fetch(process.env.REACT_APP_CLOUDINARY_UPLOAD_URL!, {
    body: formData,
    method: "POST",
  });
  const responseJson = await response.json();
  return {
    publicId: responseJson.public_id,
    url: responseJson.secure_url,
  };
}

export interface CloudinaryTransformationOptions {
  backgroundColor?: string;
  crop?: string;
  height?: number | string;
  width?: number | string;
}

export function getImageUrl(
  id: string,
  options?: CloudinaryTransformationOptions
) {
  const transformations = options ? getTransformations(options) : "";
  return `https://res.cloudinary.com/dqckrnxlu/image/upload/${transformations}/${id}`;
}

function getTransformations(options: CloudinaryTransformationOptions): string {
  let transformations = "";
  if (options.backgroundColor) {
    transformations += `b_${options.backgroundColor},`;
  }
  if (options.crop) {
    transformations += `c_${options.crop},`;
  }
  if (options.height) {
    transformations += `h_${options.height},`;
  }
  if (options.width) {
    transformations += `w_${options.width},`;
  }
  return transformations.length > 0
    ? transformations.slice(0, -1)
    : transformations;
}
