export type Maybe<T> = T | undefined;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
};


export type UserProfile = {
  readonly __typename?: 'UserProfile';
  readonly userId: Scalars['ID'];
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName?: Maybe<Scalars['String']>;
  readonly fullName?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly professionalRole?: Maybe<Scalars['Int']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly specialtyTypes?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly socialMediaFacebook?: Maybe<Scalars['String']>;
  readonly socialMediaInstagram?: Maybe<Scalars['String']>;
  readonly socialMediaTwitter?: Maybe<Scalars['String']>;
  readonly socialMediaWebsite?: Maybe<Scalars['String']>;
  readonly socialMediaYelp?: Maybe<Scalars['String']>;
  readonly bankConnected?: Maybe<Scalars['Boolean']>;
  readonly images: ReadonlyArray<Image>;
  readonly stylistLicenses: ReadonlyArray<StylistLicense>;
};

export type StylistLicense = {
  readonly __typename?: 'StylistLicense';
  readonly id: Scalars['ID'];
  readonly number: Scalars['String'];
  readonly state: Scalars['Int'];
  readonly type: Scalars['String'];
  readonly expirationDate?: Maybe<Scalars['Date']>;
};

export type Salon = {
  readonly __typename?: 'Salon';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly title: Scalars['String'];
  readonly address: Scalars['String'];
  readonly location: Location;
  readonly phoneNumber: Scalars['String'];
  readonly status: SalonStatus;
  readonly licenseNumber: Scalars['String'];
  readonly licenseState: Scalars['Int'];
  readonly contactName: Scalars['String'];
  readonly contactEmail: Scalars['String'];
  readonly contactPhoneNumber: Scalars['String'];
  readonly amenities: ReadonlyArray<Scalars['Int']>;
  readonly equipment: ReadonlyArray<Scalars['Int']>;
  readonly practices: ReadonlyArray<Scalars['Int']>;
  readonly rules: ReadonlyArray<Scalars['Int']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly restroomCountMen: Scalars['Int'];
  readonly restroomCountUnisex: Scalars['Int'];
  readonly restroomCountWomen: Scalars['Int'];
  readonly socialMediaFacebook?: Maybe<Scalars['String']>;
  readonly socialMediaInstagram?: Maybe<Scalars['String']>;
  readonly socialMediaTwitter?: Maybe<Scalars['String']>;
  readonly socialMediaWebsite?: Maybe<Scalars['String']>;
  readonly socialMediaYelp?: Maybe<Scalars['String']>;
  readonly chairs: ReadonlyArray<Chair>;
  readonly images: ReadonlyArray<Image>;
  readonly operatingHours: ReadonlyArray<OperatingHour>;
};

export enum SalonStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Testing = 'TESTING'
}

export type Location = {
  readonly __typename?: 'Location';
  readonly longitude: Scalars['Float'];
  readonly latitude: Scalars['Float'];
};

export type OperatingHour = {
  readonly __typename?: 'OperatingHour';
  readonly id: Scalars['ID'];
  readonly startMinuteOfWeek: Scalars['Int'];
  readonly endMinuteOfWeek: Scalars['Int'];
  readonly dayOfWeek: Scalars['Int'];
  readonly startTime: Scalars['String'];
  readonly endTime: Scalars['String'];
};

export type Image = {
  readonly __typename?: 'Image';
  readonly id: Scalars['ID'];
  readonly publicId: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Chair = {
  readonly __typename?: 'Chair';
  readonly id: Scalars['ID'];
  readonly salon: Salon;
  readonly type: ChairType;
  readonly price?: Maybe<Scalars['String']>;
  readonly title: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly amenities: ReadonlyArray<Scalars['Int']>;
  readonly squareFootage?: Maybe<Scalars['Int']>;
  readonly stationCount?: Maybe<Scalars['Int']>;
  readonly bookings: ReadonlyArray<Booking>;
  readonly blockedDates: ReadonlyArray<ChairBlockedDate>;
  readonly availableDates: ReadonlyArray<Scalars['Date']>;
  readonly priceForBooking: BookingPrice;
};


export type ChairBookingsArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type ChairBlockedDatesArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type ChairAvailableDatesArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type ChairPriceForBookingArgs = {
  flights: ReadonlyArray<FlightInput>;
};

export enum ChairType {
  Chair = 'CHAIR',
  PrivateSuite = 'PRIVATE_SUITE',
  EventSpace = 'EVENT_SPACE'
}

export type SalonSearchResultsEdge = {
  readonly __typename?: 'SalonSearchResultsEdge';
  readonly cursor: Scalars['String'];
  readonly node: Salon;
};

export type PageInfo = {
  readonly __typename?: 'PageInfo';
  readonly startCursor?: Maybe<Scalars['String']>;
  readonly endCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage: Scalars['Boolean'];
};

export type SalonSearchResults = {
  readonly __typename?: 'SalonSearchResults';
  readonly edges: ReadonlyArray<SalonSearchResultsEdge>;
  readonly pageInfo: PageInfo;
};

export type Booking = {
  readonly __typename?: 'Booking';
  readonly id: Scalars['ID'];
  readonly chair: Chair;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly cancelled: Scalars['Boolean'];
  readonly totalChargeAmount: Scalars['String'];
};

export type ChairBlockedDate = {
  readonly __typename?: 'ChairBlockedDate';
  readonly id: Scalars['ID'];
  readonly chair: Chair;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
};

export type BookingPrice = {
  readonly __typename?: 'BookingPrice';
  readonly chairCost: Scalars['String'];
  readonly guestFeeAmount: Scalars['String'];
  readonly totalChargeAmount: Scalars['String'];
};

export type LocationInput = {
  readonly longitude: Scalars['Float'];
  readonly latitude: Scalars['Float'];
};

export type FlightInput = {
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly userProfileById?: Maybe<UserProfile>;
  readonly myBookings: ReadonlyArray<Booking>;
  readonly pendingSalons: ReadonlyArray<Salon>;
  readonly mySalons: ReadonlyArray<Salon>;
  readonly salonById?: Maybe<Salon>;
  readonly searchSalons: SalonSearchResults;
  readonly chairById?: Maybe<Chair>;
  readonly bookingsByIds: ReadonlyArray<Booking>;
};


export type QueryUserProfileByIdArgs = {
  id: Scalars['ID'];
};


export type QuerySalonByIdArgs = {
  id: Scalars['ID'];
};


export type QuerySearchSalonsArgs = {
  northEast: LocationInput;
  southWest: LocationInput;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryChairByIdArgs = {
  id: Scalars['ID'];
};


export type QueryBookingsByIdsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};

export type CreateBookingInput = {
  readonly token?: Maybe<Scalars['String']>;
  readonly chairId?: Maybe<Scalars['String']>;
  readonly flights?: Maybe<ReadonlyArray<FlightInput>>;
};

export type CreateBookingOutput = {
  readonly __typename?: 'CreateBookingOutput';
  readonly bookings?: Maybe<ReadonlyArray<Booking>>;
};

export type CancelBookingInput = {
  readonly id?: Maybe<Scalars['ID']>;
};

export type CancelBookingOutput = {
  readonly __typename?: 'CancelBookingOutput';
  readonly booking?: Maybe<Booking>;
};

export type CreateChairBlockedDatesInput = {
  readonly chairId?: Maybe<Scalars['ID']>;
  readonly flights?: Maybe<ReadonlyArray<FlightInput>>;
};

export type CreateChairBlockedDatesOutput = {
  readonly __typename?: 'CreateChairBlockedDatesOutput';
  readonly chairBlockedDates?: Maybe<ReadonlyArray<ChairBlockedDate>>;
};

export type DeleteChairBlockedDateInput = {
  readonly id?: Maybe<Scalars['ID']>;
};

export type DeleteChairBlockedDateOutput = {
  readonly __typename?: 'DeleteChairBlockedDateOutput';
  readonly chairBlockedDate?: Maybe<ChairBlockedDate>;
};

export type UpdateUserProfileInput = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly userProfile?: Maybe<UserProfileInput>;
};

export type UserProfileInput = {
  readonly email?: Maybe<Scalars['String']>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly fullName?: Maybe<Scalars['String']>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly professionalRole?: Maybe<Scalars['Int']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly specialtyTypes?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly socialMediaFacebook?: Maybe<Scalars['String']>;
  readonly socialMediaInstagram?: Maybe<Scalars['String']>;
  readonly socialMediaTwitter?: Maybe<Scalars['String']>;
  readonly socialMediaWebsite?: Maybe<Scalars['String']>;
  readonly socialMediaYelp?: Maybe<Scalars['String']>;
  readonly images?: Maybe<ReadonlyArray<ImageInput>>;
  readonly stylistLicenses?: Maybe<ReadonlyArray<StylistLicenseInput>>;
};

export type ImageInput = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly publicId?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
};

export type StylistLicenseInput = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly number?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['Int']>;
  readonly type?: Maybe<Scalars['String']>;
  readonly expirationDate?: Maybe<Scalars['Date']>;
};

export type UpdateUserProfileOutput = {
  readonly __typename?: 'UpdateUserProfileOutput';
  readonly userProfile?: Maybe<UserProfile>;
};

export type CreateSalonInput = {
  readonly salon?: Maybe<SalonInput>;
};

export type UpdateSalonInput = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly salon?: Maybe<SalonInput>;
};

export type SalonInput = {
  readonly name?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly address?: Maybe<Scalars['String']>;
  readonly location?: Maybe<LocationInput>;
  readonly phoneNumber?: Maybe<Scalars['String']>;
  readonly licenseNumber?: Maybe<Scalars['String']>;
  readonly licenseState?: Maybe<Scalars['Int']>;
  readonly contactName?: Maybe<Scalars['String']>;
  readonly contactEmail?: Maybe<Scalars['String']>;
  readonly contactPhoneNumber?: Maybe<Scalars['String']>;
  readonly amenities?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly equipment?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly practices?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly rules?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly description?: Maybe<Scalars['String']>;
  readonly restroomCountMen?: Maybe<Scalars['Int']>;
  readonly restroomCountUnisex?: Maybe<Scalars['Int']>;
  readonly restroomCountWomen?: Maybe<Scalars['Int']>;
  readonly socialMediaFacebook?: Maybe<Scalars['String']>;
  readonly socialMediaInstagram?: Maybe<Scalars['String']>;
  readonly socialMediaTwitter?: Maybe<Scalars['String']>;
  readonly socialMediaWebsite?: Maybe<Scalars['String']>;
  readonly socialMediaYelp?: Maybe<Scalars['String']>;
  readonly images?: Maybe<ReadonlyArray<ImageInput>>;
  readonly operatingHours?: Maybe<ReadonlyArray<OperatingHourInput>>;
};

export type OperatingHourInput = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly startMinuteOfWeek?: Maybe<Scalars['Int']>;
  readonly endMinuteOfWeek?: Maybe<Scalars['Int']>;
  readonly dayOfWeek?: Maybe<Scalars['Int']>;
  readonly startTime?: Maybe<Scalars['String']>;
  readonly endTime?: Maybe<Scalars['String']>;
};

export type CreateSalonOutput = {
  readonly __typename?: 'CreateSalonOutput';
  readonly salon?: Maybe<Salon>;
};

export type UpdateSalonOutput = {
  readonly __typename?: 'UpdateSalonOutput';
  readonly salon?: Maybe<Salon>;
};

export type CreateChairInput = {
  readonly salonId?: Maybe<Scalars['ID']>;
  readonly chair?: Maybe<ChairInput>;
};

export type UpdateChairInput = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly chair?: Maybe<ChairInput>;
};

export type ChairInput = {
  readonly type?: Maybe<ChairType>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['String']>;
  readonly amenities?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly squareFootage?: Maybe<Scalars['Int']>;
  readonly stationCount?: Maybe<Scalars['Int']>;
};

export type CreateChairOutput = {
  readonly __typename?: 'CreateChairOutput';
  readonly chair?: Maybe<Chair>;
};

export type UpdateChairOutput = {
  readonly __typename?: 'UpdateChairOutput';
  readonly chair?: Maybe<Chair>;
};

export type UpdateSalonStatusInput = {
  readonly id?: Maybe<Scalars['ID']>;
  readonly status?: Maybe<SalonStatus>;
};

export type UpdateSalonStatusOutput = {
  readonly __typename?: 'UpdateSalonStatusOutput';
  readonly salon?: Maybe<Salon>;
};

export type AuthorizePaymentsInput = {
  readonly code?: Maybe<Scalars['String']>;
};

export type AuthorizePaymentsOutput = {
  readonly __typename?: 'AuthorizePaymentsOutput';
  readonly userProfile?: Maybe<UserProfile>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly createSalon?: Maybe<CreateSalonOutput>;
  readonly updateSalon?: Maybe<UpdateSalonOutput>;
  readonly createChair?: Maybe<CreateChairOutput>;
  readonly updateChair?: Maybe<UpdateChairOutput>;
  readonly createBooking?: Maybe<CreateBookingOutput>;
  readonly cancelBooking?: Maybe<CancelBookingOutput>;
  readonly createChairBlockedDates?: Maybe<CreateChairBlockedDatesOutput>;
  readonly deleteChairBlockedDate?: Maybe<DeleteChairBlockedDateOutput>;
  readonly updateUserProfile?: Maybe<UpdateUserProfileOutput>;
  readonly updateSalonStatus?: Maybe<UpdateSalonStatusOutput>;
  readonly authorizePayments?: Maybe<AuthorizePaymentsOutput>;
};


export type MutationCreateSalonArgs = {
  input: CreateSalonInput;
};


export type MutationUpdateSalonArgs = {
  input: UpdateSalonInput;
};


export type MutationCreateChairArgs = {
  input: CreateChairInput;
};


export type MutationUpdateChairArgs = {
  input: UpdateChairInput;
};


export type MutationCreateBookingArgs = {
  input: CreateBookingInput;
};


export type MutationCancelBookingArgs = {
  input: CancelBookingInput;
};


export type MutationCreateChairBlockedDatesArgs = {
  input: CreateChairBlockedDatesInput;
};


export type MutationDeleteChairBlockedDateArgs = {
  input: DeleteChairBlockedDateInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationUpdateSalonStatusArgs = {
  input: UpdateSalonStatusInput;
};


export type MutationAuthorizePaymentsArgs = {
  input: AuthorizePaymentsInput;
};
