import omit from "lodash/omit";

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ApolloError } from "apollo-client";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";

import { bindActionCreators } from "tools/index";
import { useNavigationActions } from "tools/useNavigationActions";

import { ChairForm } from "./component/ChairForm";
import { CreateChairFormActions } from "./form/state";
import { emptyChair } from "./form/state";
import { CreateOrEditChairPageState } from "./model";
import { ChairFormState } from "./model";
import { CreateChairFormState } from "./model";

import {
  CreateChairPage_CreateChairMutation,
  CreateChairPage_CreateChairMutationVariables,
} from "./CreateChairPage.generated";

const CREATE_CHAIR = gql`
  mutation CreateChairPage_CreateChair($input: CreateChairInput!) {
    createChair(input: $input) {
      chair {
        id
      }
    }
  }
`;

export const CreateChairPageConatiner = () => {
  const { salonId } = useParams<{ salonId: string }>();
  const navigationActions = useNavigationActions();

  const [createChair, { error: createChairError }] = useMutation<
    CreateChairPage_CreateChairMutation,
    CreateChairPage_CreateChairMutationVariables
  >(CREATE_CHAIR, {
    onCompleted: (updatedData) => {
      if (updatedData.createChair?.chair) {
        navigationActions.gotoSalonChairsPage(salonId, { refetch: true });
      }
    },
  });

  const mappedCreateChair = (chairFormState: ChairFormState) => {
    const mappedChair = {
      ...omit(chairFormState, ["id"]),
    };
    createChair({
      variables: { input: { salonId, chair: mappedChair } },
    });
  };

  return (
    <CreateChairPage
      createChair={mappedCreateChair}
      createChairError={createChairError}
    />
  );
};

export interface CreateChairPageClassProps {
  createChair: (chair: ChairFormState) => void;
  createChairError?: ApolloError;
  createChairFormActions: CreateChairFormActions;
  createChairFormState: CreateChairFormState;
}

export type CreateChairPageProps = CreateChairPageClassProps &
  RouteComponentProps<void>;

export class CreateChairPageClass extends React.Component<
  CreateChairPageProps
> {
  constructor(props: CreateChairPageProps) {
    super(props);

    props.createChairFormActions.createObject(CREATE_CHAIR_ID, emptyChair);
  }

  render() {
    return (
      <ChairForm
        chairFormError={this.props.createChairError}
        createChairFormActions={this.props.createChairFormActions}
        createChairFormState={this.props.createChairFormState}
        id={CREATE_CHAIR_ID}
        url={this.props.match.url}
      />
    );
  }
}

const CREATE_CHAIR_ID = "CREATE_CHAIR_ID";

type UnconnectedCreateChairPageProps = Omit<
  CreateChairPageProps,
  "createChairFormActions" | "createChairFormState"
>;

const mapStateToProps = (state: CreateOrEditChairPageState) => {
  const { createChairFormState } = state;
  return {
    createChairFormState,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: UnconnectedCreateChairPageProps
) => {
  const createChairFormActions = new CreateChairFormActions(
    props.match.url,
    props.createChair
  );
  return {
    createChairFormActions: bindActionCreators(
      createChairFormActions,
      dispatch
    ),
  };
};

export const CreateChairPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateChairPageClass)
);
