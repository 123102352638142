import { createUser as createUserApi, getMe as getMeApi } from "api/user";
import { User } from "model/index";
import { UserProfile } from "model/index";
import { ActionsUnion } from "tools/redux";
import { createAction } from "tools/redux";

import { ThunkAction } from "./ThunkAction";

import {
  CREATE_USER_COMPLETED,
  CREATE_USER_ERRORED,
  CREATE_USER_REQUESTED,
  GET_ME_COMPLETED,
  GET_ME_ERRORED,
  GET_ME_REQUESTED,
} from "constant/redux";

const UserActionCreators = {
  createUserCompleted: (user: User) =>
    createAction(CREATE_USER_COMPLETED, user),
  createUserErrored: (error: Error) => createAction(CREATE_USER_ERRORED, error),
  createUserRequested: () => createAction(CREATE_USER_REQUESTED),
  getMeCompleted: (user: User) => createAction(GET_ME_COMPLETED, user),
  getMeErrored: (error: Error) => createAction(GET_ME_ERRORED, error),
  getMeRequested: () => createAction(GET_ME_REQUESTED),
};
export type UserActionsType = ActionsUnion<typeof UserActionCreators>;

export class UserActions {
  getMe(): ThunkAction {
    return async (dispatch, _, { fetchV2 }) => {
      dispatch(UserActionCreators.getMeRequested());
      try {
        const user = await fetchV2(getMeApi());
        dispatch(UserActionCreators.getMeCompleted(user));
      } catch (error) {
        dispatch(UserActionCreators.getMeErrored(error));
      }
    };
  }

  getMeWithError(): ThunkAction {
    return async (dispatch, _, { fetchV2 }) => {
      dispatch(UserActionCreators.getMeRequested());
      try {
        const user = await fetchV2(getMeApi());
        dispatch(UserActionCreators.getMeCompleted(user));
      } catch (error) {
        dispatch(UserActionCreators.getMeErrored(error));
        throw error;
      }
    };
  }

  create(newUser: Omit<UserProfile, "userId">): ThunkAction {
    return async (dispatch, _, { fetchV2 }) => {
      dispatch(UserActionCreators.createUserRequested());
      try {
        const user = await fetchV2(createUserApi(newUser));
        dispatch(UserActionCreators.createUserCompleted(user));
      } catch (error) {
        dispatch(UserActionCreators.createUserErrored(error));
      }
    };
  }
}
