import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { I18nText } from "component/I18nText";
import Button from "component/modules/Button";

interface SuccessAlertProps {
  message: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

const SuccessAlert = (props: SuccessAlertProps) => {
  const { message, open, onClose } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <I18nText i18nKey="common.success" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          onClick={onClose}
          color="secondary"
        >
          <I18nText i18nKey="common.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessAlert;
