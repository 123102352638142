export interface Action<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P, M> extends Action<T> {
  payload: P;
  metadata?: M;
}

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P, M>(
  type: T,
  payload: P,
  metadata?: M
): ActionWithPayload<T, P, M>;
export function createAction<T extends string, P, M>(
  type: T,
  payload?: P,
  metadata?: M
) {
  return { type, payload, metadata };
}

type FunctionType = (...args: any[]) => any;
interface ActionCreatorsMapObject {
  [actionCreator: string]: FunctionType;
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;
