import { SessionActionsType } from "action/SessionActions";
import { UserActionsType } from "action/UserActions";
import { UserState } from "model/index";

import {
  CREATE_USER_COMPLETED,
  CREATE_USER_ERRORED,
  CREATE_USER_REQUESTED,
  GET_ME_COMPLETED,
  GET_ME_ERRORED,
  GET_ME_REQUESTED,
  LOGOUT_USER_COMPLETED,
} from "constant/redux";

const initialState: UserState = {
  pending: false,
};

export function userReducer(
  state: UserState = initialState,
  action: UserActionsType | SessionActionsType
): UserState {
  switch (action.type) {
    case CREATE_USER_REQUESTED:
    case GET_ME_REQUESTED: {
      return Object.assign({}, state, { pending: true });
    }
    case CREATE_USER_COMPLETED:
    case GET_ME_COMPLETED: {
      const user = action.payload;
      return Object.assign({}, state, {
        pending: false,
        userProfile: user.profile,
      });
    }
    case CREATE_USER_ERRORED:
    case GET_ME_ERRORED: {
      const error = action.payload;
      return Object.assign({}, state, { pending: false, error });
    }
    case LOGOUT_USER_COMPLETED: {
      return Object.assign({}, state, { userProfile: undefined });
    }
    default: {
      return state;
    }
  }
}
