import React from "react";
import { StripeProvider } from "react-stripe-elements";

export interface State {
  stripe: any;
}

export class AsyncStripeProvider extends React.Component<{}, State> {
  state = {
    stripe: null,
  };

  componentDidMount() {
    const Stripe = getGlobalStripe();
    if (Stripe) {
      this.setState({ stripe: Stripe(process.env.REACT_APP_STRIPE_API_KEY!) });
    } else {
      document.querySelector("#stripe-js")!.addEventListener("load", () => {
        this.setState({
          stripe: getGlobalStripe()(process.env.REACT_APP_STRIPE_API_KEY!),
        });
      });
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        {this.props.children}
      </StripeProvider>
    );
  }
}

function getGlobalStripe() {
  return (window as any).Stripe;
}
