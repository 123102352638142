import React from "react";

import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";

import { WithNavigationProvidedProps } from "tools/withNavigation";
import { withNavigation } from "tools/withNavigation";

import { I18nText } from "./I18nText";
import Typography from "./modules/Typography";
import { UnstyledLink } from "./UnstyledLink";

export type FooterStyles = WithStyles<typeof styles>;

export type FooterProps = FooterStyles & WithNavigationProvidedProps<void>;

const styles: any = (theme: Theme) => ({
  footer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(5),
  },
});

export class FooterClass extends React.Component<FooterProps> {
  render() {
    const { classes } = this.props;
    return (
      <footer className={classes.footer}>
        <Typography color="inherit" variant="caption">
          <I18nText
            i18nKey="footer.copyrite"
            year={`${new Date().getFullYear()}`}
          />
        </Typography>
        <Typography color="inherit" variant="caption">
          <UnstyledLink to={this.props.navigationActions.termsOfUsePagePath}>
            <I18nText i18nKey="footer.links.terms.label" />
          </UnstyledLink>
        </Typography>
        <Typography color="inherit" variant="caption">
          <UnstyledLink to={this.props.navigationActions.privacyPolicyPagePath}>
            <I18nText i18nKey="footer.links.privacy.label" />
          </UnstyledLink>
        </Typography>
        <Typography color="inherit" variant="caption">
          <UnstyledLink
            to={this.props.navigationActions.spaceLicenseAgreementPagePath}
          >
            <I18nText i18nKey="footer.links.space_license_agreement.label" />
          </UnstyledLink>
        </Typography>
      </footer>
    );
  }
}

export const Footer = withStyles(styles)(withNavigation(FooterClass));
