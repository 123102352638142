import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import AttachMoneyOutlined from "@material-ui/icons/AttachMoney";
import CalendarToday from "@material-ui/icons/CalendarToday";
import ChatOutlined from "@material-ui/icons/Chat";
import HomeOutlined from "@material-ui/icons/Home";
import LocationOnOutlined from "@material-ui/icons/LocationOn";
import PhonelinkRingOutlined from "@material-ui/icons/PhonelinkRing";
import React from "react";

import { I18nText } from "component/I18nText";
import { LinearLayout } from "component/LinearLayout";
import Typography from "component/modules/Typography";

import { HowItWorksItem } from "./HowItWorksItem";

export type HowItWorksSectionProps = WithStyles<typeof styles>;

const styles = (theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },

  spacer: {
    marginBottom: theme.spacing(2),
  },

  title: {
    marginBottom: theme.spacing(4),
  },
});

export class HowItWorksSectionClass extends React.Component<
  HowItWorksSectionProps
> {
  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.container} maxWidth="xl">
        <Grid container={true} justify="center" direction="column">
          <Grid item={true} xs={12}>
            <Typography
              align="center"
              variant="h4"
              marked="center"
              className={classes.title}
            >
              <I18nText i18nKey="pages.home.section_two.title" />
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Grid justify="center" container={true}>
              <Grid className={classes.spacer} item={true} xs={12} md={5}>
                <Typography
                  align="left"
                  className={classes.spacer}
                  variant="h6"
                  color="primary"
                >
                  <I18nText i18nKey="pages.home.section_two.column_one.title" />
                </Typography>
                <LinearLayout orientation="vertical" spacing={3}>
                  <HowItWorksItem
                    description={
                      <I18nText i18nKey="pages.home.section_two.column_one.row_one.description" />
                    }
                    icon={<LocationOnOutlined />}
                    title={
                      <I18nText i18nKey="pages.home.section_two.column_one.row_one.title" />
                    }
                  />

                  <HowItWorksItem
                    description={
                      <I18nText i18nKey="pages.home.section_two.column_one.row_two.description" />
                    }
                    icon={<ChatOutlined />}
                    title={
                      <I18nText i18nKey="pages.home.section_two.column_one.row_two.title" />
                    }
                  />

                  <HowItWorksItem
                    description={
                      <I18nText i18nKey="pages.home.section_two.column_one.row_three.description" />
                    }
                    icon={<CalendarToday />}
                    title={
                      <I18nText i18nKey="pages.home.section_two.column_one.row_three.title" />
                    }
                  />
                </LinearLayout>
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <Typography
                  align="left"
                  className={classes.spacer}
                  variant="h6"
                  color="primary"
                >
                  <I18nText i18nKey="pages.home.section_two.column_two.title" />
                </Typography>
                <LinearLayout orientation="vertical" spacing={2}>
                  <HowItWorksItem
                    description={
                      <I18nText i18nKey="pages.home.section_two.column_two.row_one.description" />
                    }
                    icon={<HomeOutlined />}
                    title={
                      <I18nText i18nKey="pages.home.section_two.column_two.row_one.title" />
                    }
                  />

                  <HowItWorksItem
                    description={
                      <I18nText i18nKey="pages.home.section_two.column_two.row_two.description" />
                    }
                    icon={<PhonelinkRingOutlined />}
                    title={
                      <I18nText i18nKey="pages.home.section_two.column_two.row_two.title" />
                    }
                  />

                  <HowItWorksItem
                    description={
                      <I18nText i18nKey="pages.home.section_two.column_two.row_three.description" />
                    }
                    icon={<AttachMoneyOutlined />}
                    title={
                      <I18nText i18nKey="pages.home.section_two.column_two.row_three.title" />
                    }
                  />
                </LinearLayout>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export const HowItWorksSection = withStyles(styles)(HowItWorksSectionClass);
