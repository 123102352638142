import { ApolloProvider } from "@apollo/react-hooks";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import ApolloClient from "apollo-boost";
import { createBrowserHistory as createHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { connect } from "react-redux";
import { Router } from "react-router-dom";
import { Dispatch } from "redux";

import { SessionActions } from "./action/SessionActions";
import { UserActions } from "./action/UserActions";
import { FetchApi } from "./api/index";
import { createFetcher } from "./api/index";
import { App } from "./App";
import { Auth } from "./Auth";
import { AsyncStripeProvider } from "./component/AsyncStripeProvider";
import { AppState } from "./model/index";
import { getSessionStatus } from "./reducer/session";
import { configureStore } from "./store/configureStore";
import theme from "./theme";
import { bindActionCreators } from "./tools/index";
import { NavigationActions } from "./tools/NavigationActions";
import { withNavigation } from "./tools/withNavigation";

import "typeface-roboto-condensed";
import "typeface-work-sans";
import "./i18n";
import "./index.css";

declare var ga: any;

ga("create", process.env.REACT_APP_TRACKING_ID, "auto");
ga("send", "pageview");

let token: string | undefined;

const client = new ApolloClient({
  uri:
    process.env.REACT_APP_GRAPHQL_ROOT ||
    window.location.origin + "/api/v2/graphql",
  request: (operation) => {
    operation.setContext({
      headers: token ? { authorization: `Bearer ${token}` } : {},
    });
  },
  onError: ({ networkError, graphQLErrors, operation }) => {
    const { response } = operation.getContext();
    if (
      response.status === 401 ||
      graphQLErrors?.some((e) => e.extensions?.code === "UNAUTHENTICATED")
    ) {
      store.dispatch(sessionActions.clearToken());
    }
  },
});

function onUnauthorized() {
  store.dispatch(sessionActions.clearToken());
}

const apiRootV2 =
  (process.env.REACT_APP_API_ROOT_V2 || window.location.origin) + "/api/v2";
const history = createHistory();
const navigationActions = new NavigationActions(history);

// Expose history.push to cypress so that it can navigate without page reloads
if ((window as any).Cypress) {
  (window as any).cypressNavigate = history.push;
}

const fetcherV2 = createFetcher(apiRootV2, { onUnauthorized });
const apiV2 = new FetchApi(fetcherV2);
const store = configureStore(
  {},
  { fetchV2: apiV2.fetch, history, navigationActions }
);
store.subscribe(() => {
  const state: AppState = store.getState();
  token = state.sessionState?.token;
  const updatedFetcherV2 = createFetcher(apiRootV2, { onUnauthorized, token });
  apiV2.setFetcher(updatedFetcherV2);
});

const mapStateToProps = (state: AppState) => {
  const sessionStatus = getSessionStatus(state);
  return {
    ...state,
    sessionStatus,
  };
};

history.listen((location) => {
  ga("set", "page", location.pathname + location.search);
  ga("send", "pageview");
});

const auth = new Auth(window.location.origin);
const userActions = new UserActions();
const sessionActions = new SessionActions(auth, userActions);

// Using any here because "dispatch" doesn't accept thunk actions in its types.
const login = async (dispatch: any) => {
  await dispatch(sessionActions.renewToken());
  await dispatch(userActions.getMe());
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // Using any here because "dispatch" doesn't accept thunk actions in its types.
    login: () => dispatch(login as any),
    sessionActions: bindActionCreators(sessionActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
};

const ConnectedApp = withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(App)
);

ReactDOM.render(
  <React.Suspense fallback={<div />}>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AsyncStripeProvider>
            <Router history={history}>
              <ConnectedApp />
            </Router>
          </AsyncStripeProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </React.Suspense>,
  document.getElementById("root") as HTMLElement
);
