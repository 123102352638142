import Container from "@material-ui/core/Container";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
}));

export const PageContainer: React.FC = (props) => {
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="xl">
      {props.children as any}
    </Container>
  );
};
