import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { FormProps } from "form/index";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { UserProfileFormState } from "../form/state";

export type UserProfileAboutMeFormProps = FormProps<
  UserProfileFormState,
  keyof UserProfileFormState
>;

export class UserProfileAboutMeForm extends React.Component<
  UserProfileAboutMeFormProps
> {
  render() {
    const descriptionError = getErrorForKey(this.props.errors, "description");
    return (
      <FormWrapper
        onPrevious={this.props.onPrevious}
        onSubmit={this.props.onSubmit}
        headline={<I18nText i18nKey="forms.about_me.headline" />}
      >
        <Grid item={true} xs={12}>
          <TextField
            error={!!descriptionError}
            fullWidth={true}
            multiline={true}
            rowsMax="10"
            helperText={descriptionError}
            label={<I18nText i18nKey="forms.about_me.fields.label" />}
            onChange={createStringEventHandler(
              this.props,
              "description" as any
            )}
            value={this.props.object.description}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
