import { useQuery } from "@apollo/react-hooks";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { gql } from "apollo-boost";
import React from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { I18nText } from "component/I18nText";
import Button from "component/modules/Button";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { SocialMediaButton } from "component/SocialMediaButton";
import { UnstyledLink } from "component/UnstyledLink";
import { useNavigationActions } from "tools/useNavigationActions";

import { getStripeDashboardUrl } from "./state";
import {
  UserProfileQuery,
  UserProfileQueryVariables,
} from "./UserProfilePage.generated";

const USER_PROFILE_QUERY = gql`
  query UserProfile($id: ID!) {
    userProfileById(id: $id) {
      email
      firstName
      fullName
      phoneNumber
      professionalRole
      specialtyTypes
      description
      socialMediaFacebook
      socialMediaInstagram
      socialMediaTwitter
      socialMediaWebsite
      socialMediaYelp
      bankConnected
      images {
        url
      }
      stylistLicenses {
        id
        type
        number
        state
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 90,
    marginRight: theme.spacing(2),
    width: 90,
  },

  field: {
    paddingTop: theme.spacing(2),
  },

  list: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },

  spacer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  spacerLarge: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
}));

export const UserProfilePage = () => {
  const classes = useStyles();
  // Using any here because this code is going to get deleted once this is moved to GraphQL
  const dispatch: any = useDispatch();

  // TODO: Move this to GraphQL once we have stripe in the express server
  const [stripeDashboardPending, setStripeDashboardPending] = React.useState(
    false
  );
  const handleStripeClick = React.useCallback(async () => {
    setStripeDashboardPending(true);
    const url = await dispatch(getStripeDashboardUrl());
    setStripeDashboardPending(false);
    window.open(url);
  }, [dispatch]);

  const navigationActions = useNavigationActions();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const state = location.state as any;
  const userId = params.id;
  const { data, error, loading } = useQuery<
    UserProfileQuery,
    UserProfileQueryVariables
  >(USER_PROFILE_QUERY, {
    fetchPolicy: state?.refetch && "network-only",
    variables: {
      id: userId,
    },
  });
  const userProfile = data?.userProfileById;

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error || !userProfile) {
    return (
      <Typography
        className={classes.spacerLarge}
        align="center"
        color="error"
        variant="h5"
      >
        <I18nText i18nKey={`common.error`} />
      </Typography>
    );
  }
  const userImageUrl = userProfile.images[0]?.url ?? "";

  return (
    <div>
      <Container className={classes.spacerLarge} maxWidth="lg">
        <Grid container={true} alignItems="center" justify="center">
          <Grid item={true}>
            <Avatar
              alt="User Avatar"
              className={classes.avatar}
              src={userImageUrl}
            />
          </Grid>
          <div>
            <Typography variant="h3">
              {userProfile.fullName || userProfile.firstName}
            </Typography>
          </div>
          <Grid className={classes.field} item={true} xs={12}>
            <Divider className={classes.spacer} />
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.spacerLarge} maxWidth="lg">
        <Grid container={true} justify="center" direction="column" spacing={4}>
          {(userProfile.professionalRole ?? -1) >= 0 && (
            <Grid item={true} xs={12}>
              <Typography variant="subtitle2">
                <I18nText i18nKey="pages.user_profile.fields.role.label" />
              </Typography>
              <Typography variant="body1">
                <I18nText
                  i18nKey={`select_list.professional_role.${userProfile.professionalRole}`}
                />
              </Typography>
            </Grid>
          )}
          <Grid item={true} xs={12}>
            <Typography variant="subtitle2">
              <I18nText i18nKey="pages.user_profile.fields.contact.label" />
            </Typography>
            <Typography variant="body1">{userProfile.email}</Typography>
            <Typography variant="body1">
              {userProfile.phoneNumber || ""}
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="subtitle2">
              <I18nText i18nKey="pages.user_profile.fields.licenses.label" />
            </Typography>
            <ul className={classes.list}>
              {userProfile.stylistLicenses.map((license) => {
                return (
                  <li key={license.id}>
                    <Typography variant="body1">
                      <I18nText
                        i18nKey="pages.user_profile.fields.license_number.label"
                        licenseNumber={license.number}
                      />
                    </Typography>
                    <Typography variant="body1">
                      <I18nText
                        i18nKey="pages.user_profile.fields.license_type.label"
                        licenseType={license.type}
                      />
                    </Typography>
                    <Typography variant="body1">
                      <I18nText i18nKey="pages.user_profile.fields.license_state.label" />
                      <I18nText
                        i18nKey={`select_list.states.${license.state}.full`}
                      />
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="subtitle2">
              <I18nText i18nKey="pages.user_profile.fields.specialty_types.label" />
            </Typography>
            <ul className={classes.list}>
              {userProfile.specialtyTypes?.map((specialtyType) => {
                return (
                  <li key={specialtyType}>
                    <Typography variant="body1">
                      <I18nText
                        i18nKey={`select_list.specialty_types.${specialtyType}`}
                      />
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant="subtitle2">
              <I18nText i18nKey="pages.user_profile.fields.about_me.label" />
            </Typography>
            <Typography variant="body1">{userProfile.description}</Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <UnstyledLink
              to={navigationActions.getUserProfileEditPagePath(userId)}
            >
              <Button color="secondary" variant="contained">
                <I18nText i18nKey="pages.user_profile.buttons.edit.label" />
              </Button>
            </UnstyledLink>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.spacerLarge} maxWidth="lg">
        <Typography variant="h4">Payment</Typography>
        <Divider />
      </Container>
      <Container className={classes.spacerLarge} maxWidth="lg">
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            {userProfile.bankConnected ? (
              <div>
                <Typography variant="body1">
                  <I18nText i18nKey="pages.user_profile.help_text.bank_connected" />
                </Typography>
                <Button
                  disabled={stripeDashboardPending}
                  onClick={handleStripeClick}
                  variant="contained"
                  color="secondary"
                >
                  {stripeDashboardPending && <CircularProgress size={20} />}
                  <I18nText i18nKey="pages.user_profile.buttons.stripe_dashboard.label" />
                </Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.spacer} variant="body1">
                  <I18nText i18nKey="pages.user_profile.help_text.connect_to_stripe" />
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  href={`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${window.location.origin}/stripe_callback&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}`}
                  target="_blank"
                >
                  <I18nText i18nKey="pages.user_profile.buttons.stripe_connect.label" />
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.spacerLarge} maxWidth="lg">
        <Typography variant="h4">Social Media</Typography>
        <Divider />
      </Container>
      <Container maxWidth="lg">
        <Grid container={true} spacing={2}>
          {userProfile.socialMediaFacebook && (
            <Grid item={true} md={3} xs={12}>
              <SocialMediaButton href={userProfile.socialMediaFacebook}>
                <I18nText i18nKey="pages.user_profile.social_media_buttons.facebook_label" />
              </SocialMediaButton>
            </Grid>
          )}
          {userProfile.socialMediaTwitter && (
            <Grid item={true} md={3} xs={12}>
              <SocialMediaButton href={userProfile.socialMediaTwitter}>
                <I18nText i18nKey="pages.user_profile.social_media_buttons.twitter_label" />
              </SocialMediaButton>
            </Grid>
          )}
          {userProfile.socialMediaInstagram && (
            <Grid item={true} md={3} xs={12}>
              <SocialMediaButton href={userProfile.socialMediaInstagram}>
                <I18nText i18nKey="pages.user_profile.social_media_buttons.instagram_label" />
              </SocialMediaButton>
            </Grid>
          )}
          {userProfile.socialMediaYelp && (
            <Grid item={true} md={3} xs={12}>
              <SocialMediaButton href={userProfile.socialMediaYelp}>
                <I18nText i18nKey="pages.user_profile.social_media_buttons.yelp_label" />
              </SocialMediaButton>
            </Grid>
          )}
          {userProfile.socialMediaWebsite && (
            <Grid item={true} md={3} xs={12}>
              <SocialMediaButton href={userProfile.socialMediaWebsite}>
                <I18nText i18nKey="pages.user_profile.social_media_buttons.website_label" />
              </SocialMediaButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};
