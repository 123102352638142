import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { createSelectEventHandlerFactory } from "form/index";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";
import { ChairType } from "types.generated";

import { ChairFormProps } from "../form/state";

export type ChairDetailsFormProps = ChairFormProps;

const createChairTypeSelectEventHandler = createSelectEventHandlerFactory<
  ChairType
>();

export class ChairDetailsForm extends React.Component<ChairDetailsFormProps> {
  render() {
    const { type: chairType, price } = this.props.object;
    const titleError = getErrorForKey(this.props.errors, "title");
    const descriptionError = getErrorForKey(this.props.errors, "description");
    const priceError = getErrorForKey(this.props.errors, "chair_price");
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.chair_details.title" />}
      >
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="title-input"
            error={!!titleError}
            fullWidth={true}
            helperText={titleError}
            label={
              <I18nText i18nKey={"forms.chair_details.fields.title.label"} />
            }
            onChange={createStringEventHandler(this.props, "title")}
            required={true}
            value={this.props.object.title}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="description-input"
            error={!!descriptionError}
            fullWidth={true}
            helperText={descriptionError}
            label={
              <I18nText
                i18nKey={"forms.chair_details.fields.description.label"}
              />
            }
            onChange={createStringEventHandler(
              this.props,
              "description" as any
            )}
            value={this.props.object.description}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="chair-type">
              <I18nText i18nKey="forms.chair_details.fields.type.label" />
            </InputLabel>
            <Select
              data-test-id="chair-type-input"
              onChange={createChairTypeSelectEventHandler(
                this.props,
                "type" as any
              )}
              value={chairType}
              inputProps={{ id: "chair-type", name: "chairType" }}
            >
              <MenuItem data-test-id="chair" value={ChairType.Chair}>
                <I18nText
                  i18nKey={`select_list.chair_type.${ChairType.Chair}`}
                />
              </MenuItem>
              <MenuItem
                data-test-id="private-suite"
                value={ChairType.PrivateSuite}
              >
                <I18nText
                  i18nKey={`select_list.chair_type.${ChairType.PrivateSuite}`}
                />
              </MenuItem>
              <MenuItem data-test-id="event-space" value={ChairType.EventSpace}>
                <I18nText
                  i18nKey={`select_list.chair_type.${ChairType.EventSpace}`}
                />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="price-input"
            error={!!priceError}
            fullWidth={true}
            helperText={priceError}
            label={
              <I18nText i18nKey="forms.chair_details.fields.price.label" />
            }
            onChange={createStringEventHandler(this.props, "price" as any)}
            type="number"
            value={price}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <div>
            <Typography variant="caption" color="primary">
              <I18nText i18nKey="forms.chair_details.disclaimers.disclaimer_one" />
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="primary">
              <I18nText i18nKey="forms.chair_details.disclaimers.disclaimer_two" />
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="primary">
              <I18nText i18nKey="forms.chair_details.disclaimers.disclaimer_three" />
            </Typography>
          </div>
        </Grid>
      </FormWrapper>
    );
  }
}
