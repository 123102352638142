import { ReducersMapObject } from "redux";

import { salonDetailReducer } from "./state";

export function getReducer(): ReducersMapObject {
  return {
    salonDetailState: salonDetailReducer,
  };
}

export { SalonDetailPage } from "./SalonDetailPage";
