import partial from "lodash/partial";

import { ApolloError } from "apollo-client";
import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";

import { FormErrorMessage } from "component/FormErrorMessage";
import { ObjectFieldChangeCallback } from "form/index";
import { getFormObject } from "form/index";
import { FormError } from "model/index";

import { CreateChairFormActions } from "../form/state";
import { ChairFormStep } from "../form/state";
import { createChairFormSteps } from "../form/state";
import { ChairFormState } from "../model";
import { CreateChairFormState } from "../model";

// Need to think about some names since SalonFormProps was already taken
export interface ChairFormComponentProps {
  chairFormError?: ApolloError;
  createChairFormActions: CreateChairFormActions;
  createChairFormState: CreateChairFormState;
  id: string;
  url: string;
}

export class ChairForm extends React.Component<ChairFormComponentProps> {
  render() {
    const validationErrors: ReadonlyArray<FormError> =
      this.props.chairFormError?.graphQLErrors.reduce((acc, graphQLError) => {
        return acc.concat(
          Object.entries(graphQLError.extensions!).map(([field, value]) => {
            return { field, value };
          })
        );
      }, [] as Array<FormError>) ?? [];
    return (
      <>
        <Route exact={true} path={this.props.url} render={this.mainRedirect} />
        {this.props.chairFormError && (
          <FormErrorMessage error={this.props.chairFormError} />
        )}
        <Switch>
          {createChairFormSteps.map((formStep) => {
            return (
              <Route
                key={formStep.path}
                path={`${this.props.url}/${formStep.path}`}
                render={this.createFormStep(formStep, validationErrors)}
              />
            );
          })}
        </Switch>
      </>
    );
  }

  private mainRedirect = () => {
    return <Redirect to={`${this.props.url}/chair_details`} />;
  };

  private createFormStep(
    formStep: ChairFormStep,
    errors: ReadonlyArray<FormError>
  ) {
    const onChairFieldChange: ObjectFieldChangeCallback<
      ChairFormState,
      keyof ChairFormState
    > = partial(this.props.createChairFormActions.updateField, this.props.id);
    const moveForward = (e?: React.FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      this.props.createChairFormActions.moveForward(this.props.id, formStep);
    };
    const moveBackward = (e?: React.FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      this.props.createChairFormActions.moveBackward(this.props.id, formStep);
    };
    return () => {
      const chair = getFormObject(
        this.props.createChairFormState,
        this.props.id
      );
      if (!chair) {
        return null;
      }
      const { component: Component } = formStep;
      return (
        <Component
          errors={errors}
          object={chair}
          onObjectFieldChange={onChairFieldChange}
          onPrevious={moveBackward}
          onSubmit={moveForward}
        />
      );
    };
  }
}
