import { BadRequestError } from "./ApiError";

export type Fetcher = (path: string, init?: RequestInit) => Promise<Response>;
export type ApiFetch<T> = (fetcher: Fetcher) => Promise<T>;

export type FetcherOptions = Readonly<{
  onUnauthorized?: () => void;
  token?: string;
}>;

export function createFetcher(root: string, options: FetcherOptions): Fetcher {
  const { onUnauthorized, token } = options;
  function createUrl(path: string) {
    return `${root}/${path}`;
  }

  return async function fetcher(
    path: string,
    init?: RequestInit
  ): Promise<Response> {
    const headers = new Headers(init?.headers);
    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }

    const response = await fetch(createUrl(path), {
      ...init,
      // Pass in the "headers" object instead of Headers to allow this to work for a fetch polyfill in Cypress.
      headers: Object.fromEntries(headers.entries()),
    });
    switch (response.status) {
      case 400: {
        const body = await response.json();
        throw new BadRequestError(body);
      }
      case 401: {
        onUnauthorized?.();
        break;
      }
    }
    return response;
  };
}

export class FetchApi {
  constructor(private fetcher: Fetcher) {}

  fetch = <T>(callback: ApiFetch<T>) => {
    return callback(this.fetcher);
  };

  setFetcher(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }
}
