import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

const styles: any = (theme: Theme) => ({
  paddingBottom1: { paddingBottom: theme.spacing(1) },
  paddingBottom2: { paddingBottom: theme.spacing(2) },
  paddingBottom3: { paddingBottom: theme.spacing(3) },
  paddingBottom4: { paddingBottom: theme.spacing(4) },
  paddingBottom5: { paddingBottom: theme.spacing(5) },
  paddingLeft1: { paddingLeft: theme.spacing(1) },
  paddingLeft2: { paddingLeft: theme.spacing(2) },
  paddingLeft3: { paddingLeft: theme.spacing(3) },
  paddingLeft4: { paddingLeft: theme.spacing(4) },
  paddingLeft5: { paddingLeft: theme.spacing(5) },
  paddingRight1: { paddingRight: theme.spacing(1) },
  paddingRight2: { paddingRight: theme.spacing(2) },
  paddingRight3: { paddingRight: theme.spacing(3) },
  paddingRight4: { paddingRight: theme.spacing(4) },
  paddingRight5: { paddingRight: theme.spacing(5) },
  paddingTop1: { paddingTop: theme.spacing(1) },
  paddingTop2: { paddingTop: theme.spacing(2) },
  paddingTop3: { paddingTop: theme.spacing(3) },
  paddingTop4: { paddingTop: theme.spacing(4) },
  paddingTop5: { paddingTop: theme.spacing(5) },
});

export type Spacing = 0 | 1 | 2 | 3 | 4 | 5;

interface PaddingProps
  extends Partial<PaddingDefaultProps>,
    WithStyles<typeof styles> {}

interface PaddingDefaultProps {
  paddingBottom: Spacing;
  paddingLeft: Spacing;
  paddingRight: Spacing;
  paddingTop: Spacing;
}

type PaddingPropsWithDefaults = PaddingProps & PaddingDefaultProps;

export class PaddingClass extends React.Component<PaddingProps> {
  static defaultProps: PaddingDefaultProps = {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  };

  render() {
    const {
      classes,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
    } = this.props as PaddingPropsWithDefaults;
    const className = classnames({
      [classes.paddingBottom1]: paddingBottom === 1,
      [classes.paddingBottom2]: paddingBottom === 2,
      [classes.paddingBottom3]: paddingBottom === 3,
      [classes.paddingBottom4]: paddingBottom === 4,
      [classes.paddingBottom5]: paddingBottom === 5,
      [classes.paddingLeft1]: paddingLeft === 1,
      [classes.paddingLeft2]: paddingLeft === 2,
      [classes.paddingLeft3]: paddingLeft === 3,
      [classes.paddingLeft4]: paddingLeft === 4,
      [classes.paddingLeft5]: paddingLeft === 5,
      [classes.paddingRight1]: paddingRight === 1,
      [classes.paddingRight2]: paddingRight === 2,
      [classes.paddingRight3]: paddingRight === 3,
      [classes.paddingRight4]: paddingRight === 4,
      [classes.paddingRight5]: paddingRight === 5,
      [classes.paddingTop1]: paddingTop === 1,
      [classes.paddingTop2]: paddingTop === 2,
      [classes.paddingTop3]: paddingTop === 3,
      [classes.paddingTop4]: paddingTop === 4,
      [classes.paddingTop5]: paddingTop === 5,
    });
    return <div className={className}>{this.props.children}</div>;
  }
}

export const Padding = withStyles(styles)(PaddingClass);
