import Grid from "@material-ui/core/Grid";
import React from "react";

import { I18nText } from "./I18nText";
import Button from "./modules/Button";

export interface FormFooterProps {
  onPrevious?: (e?: React.FormEvent<HTMLElement>) => void;
}

export class FormFooter extends React.Component<FormFooterProps> {
  render() {
    return (
      <Grid item={true} xs={12}>
        <Grid container={true} justify="space-between" spacing={5}>
          <Grid item={true}>{this.previousButton()}</Grid>
          <Grid item={true}>
            <Button
              color="secondary"
              data-test-id="form-wrapper-next"
              variant="contained"
              type="submit"
            >
              <I18nText i18nKey="forms.footer.buttons.next.label" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  private previousButton() {
    if (this.props.onPrevious) {
      return (
        <Button
          color="secondary"
          onClick={this.props.onPrevious}
          variant="contained"
        >
          <I18nText i18nKey="forms.footer.buttons.previous.label" />
        </Button>
      );
    }
    return null;
  }
}
