import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

import { FormFooter } from "./FormFooter";
import Typography from "./modules/Typography";

export type FormWrapperStyles = WithStyles<"root" | "headline">;

export interface FormWrapperClassProps {
  onPrevious?: (e?: React.FormEvent<HTMLElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  headline?: React.ReactNode;
}

export type FormWrapperProps = FormWrapperClassProps & FormWrapperStyles;

const styles = (theme: Theme) => ({
  root: theme.mixins.gutters({
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  }),

  headline: {
    marginTop: theme.spacing(3),
  },
});

export class FormWrapperClass extends React.Component<FormWrapperProps> {
  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.props.onSubmit}>
        <Container maxWidth="md">
          <Grid container={true} spacing={5}>
            {this.props.headline && (
              <Grid item={true} xs={12}>
                <Typography
                  align="center"
                  className={classes.headline}
                  variant="h4"
                  marked="center"
                >
                  {this.props.headline}
                </Typography>
              </Grid>
            )}
            {this.props.children}
            <FormFooter onPrevious={this.props.onPrevious} />
          </Grid>
        </Container>
      </form>
    );
  }
}

export const FormWrapper = withStyles(styles)(FormWrapperClass);
