import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";

import { Auth0SignUpCallbackPage } from "./component/Auth0CallbackPage";
import { Auth0LoginCallbackPage } from "./component/Auth0CallbackPage";
import { ChairedAppBar } from "./component/ChairedAppBar";
import { Footer } from "./component/Footer";
import { Gatekeeper } from "./component/Gatekeeper";
import { LoginPage } from "./component/LoginPage";
import { SideMenu } from "./component/SideMenu";
import { SignUpPage } from "./component/SignUpPage";
import { AboutUsPage } from "./pages/aboutUs";
import { AdminPage } from "./pages/admin";
import { AdminSalonDetailPage } from "./pages/adminSalonDetail";
import { BecomingAHostPage } from "./pages/becomingAHost";
import { BookingConfirmationPage } from "./pages/bookingConfirmation";
import { BookingListingsPage } from "./pages/bookingListings";
import { CreateChairPage } from "./pages/createChair";
import { CreateSalonPage } from "./pages/createSalon";
import { EditChairPage } from "./pages/createChair";
import { EditSalonPage } from "./pages/createSalon";
import { CreateSalonBlockedDatePage } from "./pages/createSalonBlockedDate";
import { EditSalonProfilePage } from "./pages/editSalonProfile";
import { EditUserProfilePage } from "./pages/editUserProfile";
import { HomePage } from "./pages/home";
import { PrivacyPolicyPage } from "./pages/privacyPolicy";
import { SalonBlockedDatesPage } from "./pages/salonBlockedDates";
import { SalonBookingsPage } from "./pages/salonBookings";
import { SalonChairsPage } from "./pages/salonChairs";
import { SalonDetailPage } from "./pages/salonDetail";
import { SalonListingsPage } from "./pages/salonListings";
import { SalonSearchResultsPage } from "./pages/salonSearchResults";
import { SpaceLicenseAgreementPage } from "./pages/spaceLicenseAgreement/SpaceLicenseAgreementPage";
import { StripeCallbackPage } from "./pages/stripeCallback";
import { TermsOfUsePage } from "./pages/termsOfUse";
import { UserProfilePage } from "./pages/userProfile";

import { WithNavigationProvidedProps } from "./tools/withNavigation";
import { SessionActions } from "./action/SessionActions";
import { UserActions } from "./action/UserActions";
import { AppState } from "./model/index";
import { SessionStatus } from "./model/index";

export interface AppClassProps extends AppState {
  login: () => void;
  sessionActions: SessionActions;
  sessionStatus: SessionStatus;
  userActions: UserActions;
}

export type AppProps = AppClassProps & WithNavigationProvidedProps<void>;

interface State {
  menuOpen: boolean;
  publicUrlPaths: ReadonlyArray<string>;
}

const NON_USER_URL_PATHS = ["/login_callback", "/sign_up_callback", "/sign_up"];

export class App extends React.Component<AppProps, State> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      menuOpen: false,
      publicUrlPaths: [props.navigationActions.userProfilePagePath],
    };
  }

  componentDidMount() {
    if (!NON_USER_URL_PATHS.includes(this.props.location.pathname)) {
      this.props.login();
    }
  }

  render() {
    return (
      <div>
        <SideMenu
          onRequestClose={() => this.setState({ menuOpen: false })}
          open={this.state.menuOpen}
          sessionActions={this.props.sessionActions}
          user={this.props.userState.userProfile}
        />
        <ChairedAppBar
          homepagePath={this.props.navigationActions.homePagePath}
          toggleMenu={this.toggleMenu}
        />
        <Switch>
          <Route
            component={HomePage}
            exact={true}
            path={this.props.navigationActions.homePagePath}
          />
          <Route
            path={this.props.navigationActions.loginPagePath}
            render={this.loginPage}
          />
          <Route
            path={this.props.navigationActions.loginCallbackPagePath}
            render={this.auth0LoginCallbackPage}
          />
          <Route
            path={this.props.navigationActions.signUpPagePath}
            render={this.signUpPage}
          />
          <Route
            path={this.props.navigationActions.signUpCallbackPagePath}
            render={this.auth0SignUpCallbackPage}
          />
          <Route
            component={SalonSearchResultsPage}
            path={this.props.navigationActions.searchResultsPagePath}
          />
          <Route
            path={this.props.navigationActions.becomingAHostPagePath}
            component={BecomingAHostPage}
          />
          <Route
            path={this.props.navigationActions.aboutUsPagePath}
            component={AboutUsPage}
          />
          <Route
            path={this.props.navigationActions.spaceLicenseAgreementPagePath}
            component={SpaceLicenseAgreementPage}
          />
          <Route
            path={this.props.navigationActions.termsOfUsePagePath}
            component={TermsOfUsePage}
          />
          <Route
            path={this.props.navigationActions.privacyPolicyPagePath}
            component={PrivacyPolicyPage}
          />
          <Route
            exact={true}
            path={this.props.navigationActions.salonDetailPagePath}
            component={SalonDetailPage}
          />

          <Route render={this.gatekeeper} />
        </Switch>
        <Footer />
      </div>
    );
  }

  private toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  private loginPage = () => {
    return <LoginPage sessionActions={this.props.sessionActions} />;
  };

  private auth0LoginCallbackPage = () => {
    return (
      <Auth0LoginCallbackPage sessionActions={this.props.sessionActions} />
    );
  };

  private signUpPage = () => {
    return (
      <SignUpPage
        sessionActions={this.props.sessionActions}
        sessionState={this.props.sessionState}
      />
    );
  };

  private auth0SignUpCallbackPage = () => {
    return (
      <Auth0SignUpCallbackPage sessionActions={this.props.sessionActions} />
    );
  };

  private gatekeeper = () => {
    return (
      <Gatekeeper
        publicUrls={this.state.publicUrlPaths}
        sessionStatus={this.props.sessionStatus}
        sessionActions={this.props.sessionActions}
      >
        <Route
          component={UserProfilePage}
          exact={true}
          path={this.props.navigationActions.userProfilePagePath}
        />
        <Route
          path={this.props.navigationActions.stripeCallbackPagePath}
          component={StripeCallbackPage}
        />
        <Route
          path={this.props.navigationActions.createChairPagePath}
          component={CreateChairPage}
        />
        <Route
          path={this.props.navigationActions.createSalonPagePath}
          component={CreateSalonPage}
        />
        <Route
          exact={true}
          path={this.props.navigationActions.salonListingsPagePath}
          component={SalonListingsPage}
        />
        <Route
          path={this.props.navigationActions.editChairPagePath}
          component={EditChairPage}
        />
        <Route
          path={this.props.navigationActions.editSalonPagePath}
          component={EditSalonPage}
        />
        <Route
          path={this.props.navigationActions.editSalonProfilePagePath}
          component={EditSalonProfilePage}
        />
        <Route
          path={this.props.navigationActions.salonBookingConfirmationPagePath}
          component={BookingConfirmationPage}
        />
        <Route
          exact={true}
          path={this.props.navigationActions.salonBlockedDatesPagePath}
          component={SalonBlockedDatesPage}
        />
        <Route
          path={this.props.navigationActions.createSalonBlockedDatePagePath}
          component={CreateSalonBlockedDatePage}
        />
        <Route
          exact={true}
          path={this.props.navigationActions.salonBookingsPagePath}
          component={SalonBookingsPage}
        />
        <Route
          exact={true}
          path={this.props.navigationActions.salonChairsPagePath}
          component={SalonChairsPage}
        />
        <Route
          component={BookingListingsPage}
          path={this.props.navigationActions.bookingListingsPagePath}
        />
        <Route
          path={this.props.navigationActions.editUserProfilePagePath}
          component={EditUserProfilePage}
        />
        <Route
          exact={true}
          path={this.props.navigationActions.adminPagePath}
          component={AdminPage}
        />
        <Route
          path={this.props.navigationActions.adminSalonDetailPagePath}
          component={AdminSalonDetailPage}
        />
      </Gatekeeper>
    );
  };
}
