import { ThunkAction } from "action/ThunkAction";
import { FormActions } from "form/index";
import { FormProps } from "form/index";
import { FormState } from "form/index";
import { FormStep } from "form/index";
import { createForm } from "form/index";
import { findNextStep } from "form/index";
import { findPreviousStep } from "form/index";
import { getFormObject } from "form/index";
import { AppState } from "model/index";

import { EditSalonProfilePage_SalonFragment } from "../EditSalonProfilePage.generated";
import { SalonAmenitiesForm } from "../component/SalonAmenitiesForm";
import { SalonEquipmentForm } from "../component/SalonEquipmentForm";
import { SalonOperatingHoursForm } from "../component/SalonOperatingHoursForm";
import { SalonPracticesForm } from "../component/SalonPracticesForm";
import { SalonRestroomsForm } from "../component/SalonRestroomsForm";
import { SalonRulesForm } from "../component/SalonRulesForm";
import { SalonSocialMediaForm } from "../component/SalonSocialMediaForm";

export interface EditSalonProfileState extends AppState {
  editSalonProfileFormState: EditSalonProfileFormState;
}

type EditSalonProfileThunkAction = ThunkAction<void, EditSalonProfileState>;

export type SalonFormState = EditSalonProfilePage_SalonFragment;
export type EditSalonProfileFormState = FormState<SalonFormState>;

export interface SalonFormProps
  extends FormProps<SalonFormState, keyof SalonFormState> {}

export type SalonFormStep = FormStep<
  SalonFormState,
  keyof SalonFormState,
  SalonFormProps
>;

export const {
  actions: salonFormActions,
  reducer: editSalonProfileFormReducer,
} = createForm<SalonFormState>();

export const editSalonProfileFormSteps: ReadonlyArray<SalonFormStep> = [
  {
    component: SalonAmenitiesForm,
    path: "salon_amenities",
  },
  {
    component: SalonOperatingHoursForm,
    path: "salon_operating_hours",
  },
  {
    component: SalonPracticesForm,
    path: "salon_practices",
  },
  {
    component: SalonEquipmentForm,
    path: "salon_equipment",
  },
  {
    component: SalonRulesForm,
    path: "salon_rules",
  },
  {
    component: SalonRestroomsForm,
    path: "salon_restrooms",
  },
  {
    component: SalonSocialMediaForm,
    path: "salon_social_media",
  },
];

export class EditSalonProfileFormActions extends FormActions<
  SalonFormState,
  keyof SalonFormState
> {
  constructor(
    private readonly rootPath: string,
    private readonly submitSalon: any
  ) {
    super(salonFormActions);
  }

  moveForward(
    id: string,
    currentStep: SalonFormStep
  ): EditSalonProfileThunkAction {
    return (dispatch, getState, { navigationActions }) => {
      const salon = getFormObject(getState().editSalonProfileFormState, id);
      const nextStep = findNextStep(
        editSalonProfileFormSteps,
        currentStep,
        salon
      );
      if (nextStep) {
        navigationActions.gotoPath(this.rootPath, nextStep.path);
      } else {
        dispatch(this.submit(id, salon));
      }
    };
  }

  moveBackward(
    id: string,
    currentStep: SalonFormStep
  ): EditSalonProfileThunkAction {
    return (dispatch, getState, { navigationActions }) => {
      const salon = getFormObject(getState().editSalonProfileFormState, id);
      const previousStep = findPreviousStep(
        editSalonProfileFormSteps,
        currentStep,
        salon
      );
      if (previousStep) {
        navigationActions.gotoPath(this.rootPath, previousStep.path);
      }
    };
  }

  submit(id: string, salon: SalonFormState): EditSalonProfileThunkAction {
    return async (dispatch) => {
      try {
        await this.submitSalon(salon);
      } catch (error) {
        dispatch(salonFormActions.error(id, error));
      }
    };
  }
}
