import { useQuery } from "@apollo/react-hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { gql } from "apollo-boost";
import React from "react";

import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { WithNavigationProvidedProps } from "tools/withNavigation";
import { withNavigation } from "tools/withNavigation";

import { SalonListingCard } from "./component/SalonListingCard";
import { SalonListingsQuery } from "./SalonListingsPage.generated";

export type SalonListingsPageStyles = WithStyles<typeof styles>;

export type SalonListingsPageProps = SalonListingsPageStyles &
  WithNavigationProvidedProps<void>;

const SALON_LISTINGS_QUERY = gql`
  query SalonListings {
    mySalons {
      id
      name
      title
      address
      images {
        url
      }
      chairs {
        price
      }
    }
  }
`;

const styles: any = (theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  heading: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
});

const SalonListingsPageClass = (props: SalonListingsPageProps) => {
  const { classes } = props;

  const { data, loading } = useQuery<SalonListingsQuery>(SALON_LISTINGS_QUERY, {
    fetchPolicy: "no-cache",
  });
  const salons = data?.mySalons;

  const handleClick = (id?: number) => {
    if (props.navigationActions && id) {
      props.navigationActions.gotoSalonDetailsPage(id);
    }
  };

  return (
    <Container className={classes.container} maxWidth="xl">
      <Typography
        align="center"
        className={classes.heading}
        variant="h4"
        marked="center"
      >
        <I18nText i18nKey="pages.salon_listings.headline" />
      </Typography>
      <Grid container={true} spacing={4}>
        {loading && (
          <Grid item={true} xs={1}>
            <CircularProgress />
          </Grid>
        )}
        {salons?.map((salon: any) => {
          return (
            <Grid item={true} xs={12} sm={4} key={salon.id}>
              <div className={classes.padding}>
                <SalonListingCard
                  onClick={() => handleClick(salon.id)}
                  salon={salon}
                />
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export const SalonListingsPage = withNavigation<SalonListingsPageProps>(
  withStyles(styles)(SalonListingsPageClass) as any
);
