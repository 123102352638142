import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { I18nText } from "component/I18nText";

export type ItemListStyles = WithStyles<typeof styles>;

export interface ItemListClassProps {
  itemKey: string;
  list: ReadonlyArray<number>;
}

export type ItemListProps = ItemListClassProps & ItemListStyles;

const styles: any = (theme: Theme) => ({
  text: {
    color: theme.palette.common.white,
  },

  list: {
    listStyle: "none",
    padding: 0,
  },
});

export class ItemListClass extends React.Component<ItemListProps> {
  render() {
    const { classes, itemKey, list } = this.props;
    return (
      <ul className={classes.list}>
        {list.map((item: number) => (
          <li key={item}>
            <Typography variant="body1" className={classes.text}>
              <I18nText i18nKey={`${itemKey}.${item}`} />
            </Typography>
          </li>
        ))}
      </ul>
    );
  }
}

export const ItemList = withStyles(styles)(ItemListClass);
