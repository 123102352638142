import { useMutation } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { gql } from "apollo-boost";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { PageContainer } from "component/PageContainer";
import Typography from "component/modules/Typography";
import { FormError } from "model/index";
import { FlightInput } from "types.generated";
import { useNavigationActions } from "tools/useNavigationActions";

import {
  CreateSalonBlockedDatePage_CreateChairBlockedDatesMutation,
  CreateSalonBlockedDatePage_CreateChairBlockedDatesMutationVariables,
} from "./CreateSalonBlockedDatePage.generated";

const CREATE_CHAIR_BLOCKED_DATE = gql`
  mutation CreateSalonBlockedDatePage_createChairBlockedDates(
    $input: CreateChairBlockedDatesInput!
  ) {
    createChairBlockedDates(input: $input) {
      chairBlockedDates {
        id
      }
    }
  }
`;

export const CreateSalonBlockedDatePage = () => {
  const navigationActions = useNavigationActions();
  const { id, chairId } = useParams<{ id: string; chairId: string }>();

  const [
    createChairBlockedDates,
    { error: createChairBlockedDatesError },
  ] = useMutation<
    CreateSalonBlockedDatePage_CreateChairBlockedDatesMutation,
    CreateSalonBlockedDatePage_CreateChairBlockedDatesMutationVariables
  >(CREATE_CHAIR_BLOCKED_DATE, {
    onCompleted: (updatedData) => {
      if (updatedData.createChairBlockedDates?.chairBlockedDates) {
        navigationActions.gotoSalonBlockedDatesPage(id, chairId, {
          refetch: true,
        });
      }
    },
  });
  const handleCreateChairBlockedDate = (flight: FlightInput) => {
    createChairBlockedDates({
      variables: { input: { chairId, flights: [flight] } },
    });
  };

  return (
    <CreateSalonBlockedDatePagePresentation
      onCreateChairBlockedDate={handleCreateChairBlockedDate}
      createChairBlockedDateError={createChairBlockedDatesError}
    />
  );
};

export interface CreateSalonBlockedDatePagePresentationProps {
  onCreateChairBlockedDate: (flight: FlightInput) => void;
  createChairBlockedDateError?: ApolloError;
}

export const CreateSalonBlockedDatePagePresentation = (
  props: CreateSalonBlockedDatePagePresentationProps
) => {
  const { onCreateChairBlockedDate, createChairBlockedDateError } = props;

  const validationErrors: ReadonlyArray<FormError> = React.useMemo(() => {
    return (
      createChairBlockedDateError?.graphQLErrors.reduce((acc, graphQLError) => {
        return acc.concat(
          Object.entries(graphQLError.extensions!).map(([field, value]) => {
            return { field, value };
          })
        );
      }, [] as Array<FormError>) ?? []
    );
  }, [createChairBlockedDateError]);

  return (
    <PageContainer>
      <CreateChairBlockedDateForm
        onCreateChairBlockedDate={onCreateChairBlockedDate}
        errors={validationErrors}
      />
    </PageContainer>
  );
};

type CreateChairBlockedDateFormProps = Readonly<{
  onCreateChairBlockedDate: (flight: FlightInput) => void;
  errors: ReadonlyArray<FormError>;
}>;

const CreateChairBlockedDateForm = (props: CreateChairBlockedDateFormProps) => {
  const { onCreateChairBlockedDate, errors } = props;
  const [startDate, setStartDate] = React.useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedStartDate = e.target.value;
    if (moment(updatedStartDate).isAfter(moment(endDate))) {
      setEndDate(updatedStartDate);
    }
    setStartDate(updatedStartDate);
  };
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };
  const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    onCreateChairBlockedDate({
      startDate,
      endDate,
    });
  };

  return (
    <Container maxWidth="md">
      <FormWrapper
        onSubmit={handleSubmit}
        headline={
          <I18nText i18nKey="pages.create_salon_blocked_date.headline" />
        }
      >
        {errors && (
          <Grid item={true} xs={12}>
            <Typography color="error" variant="h6">
              {errors.reduce((acc, e) => acc + `${e.field}: ${e.value}\n`, "")}
            </Typography>
          </Grid>
        )}
        <Grid item={true} xs={12}>
          <TextField
            fullWidth={true}
            id="date"
            InputLabelProps={{ shrink: true }}
            label={
              <I18nText i18nKey="pages.create_salon_blocked_date.fields.start_date.label" />
            }
            onChange={handleStartDateChange}
            type="date"
            value={startDate}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            fullWidth={true}
            id="date"
            InputLabelProps={{ shrink: true }}
            label={
              <I18nText i18nKey="pages.create_salon_blocked_date.fields.end_date.label" />
            }
            onChange={handleEndDateChange}
            type="date"
            value={endDate}
          />
        </Grid>
      </FormWrapper>
    </Container>
  );
};
