import { useQuery, useMutation } from "@apollo/react-hooks";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { gql } from "apollo-boost";
import { History, Location } from "history";
import { Moment } from "moment";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";

import { I18nText } from "component/I18nText";
import { LinearLayout } from "component/LinearLayout";
import Button from "component/modules/Button";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { PageContainer } from "component/PageContainer";
import { UnstyledLink } from "component/UnstyledLink";
import { NavigationActions } from "tools/NavigationActions";
import { useNavigationActions } from "tools/useNavigationActions";

import {
  SalonBlockedDatesPage_SalonByIdQuery,
  SalonBlockedDatesPage_SalonByIdQueryVariables,
  SalonBlockedDatesPage_DeleteChairBlockedDateMutation,
  SalonBlockedDatesPage_DeleteChairBlockedDateMutationVariables,
  SalonBlockedDatesPage_ChairBlockedDateFragment,
  SalonBlockedDatesPage_SalonFragment,
} from "./SalonBlockedDatesPage.generated";

const chairBlockedDateFragment = gql`
  fragment SalonBlockedDatesPage_ChairBlockedDate on ChairBlockedDate {
    id
    startDate
    endDate
  }
`;

const salonFragment = gql`
  fragment SalonBlockedDatesPage_Salon on Salon {
    id
    name
    chairs {
      id
      title
      blockedDates(startDate: $startDate, endDate: $endDate) {
        ...SalonBlockedDatesPage_ChairBlockedDate
      }
    }
  }
  ${chairBlockedDateFragment}
`;

const SALON_QUERY = gql`
  query SalonBlockedDatesPage_salonById(
    $id: ID!
    $startDate: Date!
    $endDate: Date!
  ) {
    salonById(id: $id) {
      ...SalonBlockedDatesPage_Salon
    }
  }
  ${salonFragment}
`;

const DELETE_BLOCKED_DATE_MUTATION = gql`
  mutation SalonBlockedDatesPage_deleteChairBlockedDate(
    $input: DeleteChairBlockedDateInput!
  ) {
    deleteChairBlockedDate(input: $input) {
      chairBlockedDate {
        id
      }
    }
  }
`;

export const SalonBlockedDatesPage = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  const navigationActions = useNavigationActions();

  const month = getMonthFromUrl(location.search);
  const startDate = moment(month).startOf("month").toISOString();
  const endDate = moment(month).endOf("month").toISOString();
  const state = location.state as any;
  const { data, loading, error } = useQuery<
    SalonBlockedDatesPage_SalonByIdQuery,
    SalonBlockedDatesPage_SalonByIdQueryVariables
  >(SALON_QUERY, {
    fetchPolicy: state?.refetch && "network-only",
    variables: {
      id,
      startDate,
      endDate,
    },
  });

  const [deleteChairBlockedDate] = useMutation<
    SalonBlockedDatesPage_DeleteChairBlockedDateMutation,
    SalonBlockedDatesPage_DeleteChairBlockedDateMutationVariables
  >(DELETE_BLOCKED_DATE_MUTATION, {
    onCompleted: (updatedData) => {
      setBlockedDateToDelete(undefined);
    },
  });

  const [blockedDateToDelete, setBlockedDateToDelete] = React.useState<
    SalonBlockedDatesPage_ChairBlockedDateFragment | undefined
  >();

  if (!location.search || !month.isValid()) {
    return <Redirect to={getUrl(location, { month: moment() })} />;
  }

  const handleDeleteChairBlockedDate = (
    blockedDate: SalonBlockedDatesPage_ChairBlockedDateFragment
  ) => {
    deleteChairBlockedDate({
      variables: { input: { id: blockedDate.id } },
      update: (cache, result) => {
        const mutationData = result.data;
        const currentData = cache.readQuery<
          SalonBlockedDatesPage_SalonByIdQuery,
          SalonBlockedDatesPage_SalonByIdQueryVariables
        >({
          query: SALON_QUERY,
          variables: {
            id,
            startDate,
            endDate,
          },
        });
        const deletedChairBlockedDateId =
          mutationData?.deleteChairBlockedDate?.chairBlockedDate?.id;
        if (currentData && deletedChairBlockedDateId) {
          const newChairs = currentData.salonById?.chairs.map((chair) => {
            return {
              ...chair,
              blockedDates: chair.blockedDates.filter(
                (bd) => bd.id !== deletedChairBlockedDateId
              ),
            };
          });
          const newData = {
            salonById: {
              ...currentData.salonById,
              chairs: newChairs,
            },
          };
          cache.writeQuery({
            query: SALON_QUERY,
            variables: {
              id,
              startDate,
              endDate,
            },
            data: newData,
          });
        }
      },
    });
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  const salon = data?.salonById;

  if (error || !salon) {
    return (
      <Typography align="center" color="error" variant="h5">
        <I18nText i18nKey={`common.error`} />
      </Typography>
    );
  }

  const { chairId } = queryString.parse(location.search);
  if (!chairId) {
    return <Redirect to={getUrl(location, { chairId: salon.chairs[0].id })} />;
  }

  return (
    <SalonBlockedDatesPagePresentation
      history={history}
      location={location}
      navigationActions={navigationActions}
      month={month}
      chairId={chairId}
      salon={salon}
      blockedDateToDelete={blockedDateToDelete}
      onBlockedDateToDeleteChange={setBlockedDateToDelete}
      onDeleteChairBlockedDate={handleDeleteChairBlockedDate}
    />
  );
};

export type SalonBlockedDatesPagePresentationProps = Readonly<{
  history: History;
  location: Location;
  navigationActions: NavigationActions;
  month: Moment;
  chairId: string;
  salon: SalonBlockedDatesPage_SalonFragment;
  blockedDateToDelete?: SalonBlockedDatesPage_ChairBlockedDateFragment;
  onBlockedDateToDeleteChange: (
    blockedDate?: SalonBlockedDatesPage_ChairBlockedDateFragment
  ) => void;
  onDeleteChairBlockedDate: (
    id: SalonBlockedDatesPage_ChairBlockedDateFragment
  ) => void;
}>;

export const SalonBlockedDatesPagePresentation = (
  props: SalonBlockedDatesPagePresentationProps
) => {
  const {
    history,
    location,
    navigationActions,
    month,
    chairId,
    salon,
    blockedDateToDelete,
    onBlockedDateToDeleteChange,
    onDeleteChairBlockedDate,
  } = props;

  const handleChairChange = (e: React.ChangeEvent<{ value: any }>) => {
    props.history.replace(getUrl(props.location, { chairId: e.target.value }));
  };

  const handleLeftArrowClick = () => {
    history.replace(
      getUrl(location, { month: moment(month).subtract(1, "months") })
    );
  };

  const handleRightArrowClick = () => {
    history.replace(
      getUrl(location, { month: moment(month).add(1, "months") })
    );
  };

  const createDeleteBlockedDateHandler = (
    blockedDate: SalonBlockedDatesPage_ChairBlockedDateFragment
  ) => () => {
    onBlockedDateToDeleteChange(blockedDate);
  };

  const handleDeleteConfirmationDialogClose = () => {
    onBlockedDateToDeleteChange(undefined);
  };

  const handleDeleteConfirmationDialogAccept = () => {
    if (blockedDateToDelete) {
      onDeleteChairBlockedDate(blockedDateToDelete);
    }
  };

  const blockedDatesByChairId = React.useMemo(() => {
    return salon.chairs.reduce((acc, chair) => {
      acc[chair.id] = chair.blockedDates;
      return acc;
    }, {} as { [id: string]: ReadonlyArray<SalonBlockedDatesPage_ChairBlockedDateFragment> });
  }, [salon]);

  const blockedDates = blockedDatesByChairId[chairId];

  return (
    <PageContainer>
      <Dialog
        onClose={handleDeleteConfirmationDialogClose}
        open={!!blockedDateToDelete}
      >
        <DialogTitle>
          <I18nText i18nKey="pages.salon_blocked_dates.titles.confirm_delete" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <I18nText
              i18nKey="pages.salon_blocked_dates.body.confirm_delete"
              dateRange={
                blockedDateToDelete
                  ? formatBlockedDateTimeRange(blockedDateToDelete)
                  : ""
              }
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteConfirmationDialogClose}
            color="secondary"
            variant="contained"
            size="small"
          >
            <I18nText i18nKey="common.no" />
          </Button>
          <Button
            onClick={handleDeleteConfirmationDialogAccept}
            color="secondary"
            variant="contained"
            size="small"
            autoFocus={true}
          >
            <I18nText i18nKey="common.yes" />
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        container={true}
        direction="column"
        justify="flex-start"
        alignItems="center"
        spacing={5}
      >
        <Grid item={true} xs={12}>
          <Typography align="center" variant="h4" marked="center">
            <I18nText
              i18nKey="pages.salon_blocked_dates.headline"
              salonName={salon.name}
            />
          </Typography>
        </Grid>
        {salon.chairs.length > 1 && (
          <Grid item={true} xs={12}>
            <Select value={parseInt(chairId, 10)} onChange={handleChairChange}>
              {salon.chairs.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item={true} xs={12}>
          <UnstyledLink
            to={navigationActions.getCreateSalonBlockedDatePath(
              salon.id,
              chairId
            )}
          >
            <Button size="small" color="secondary" variant="contained">
              <I18nText i18nKey="pages.salon_blocked_dates.buttons.add_blocked_date.label" />
            </Button>
          </UnstyledLink>
        </Grid>
        <Grid item={true} xs={12}>
          <LinearLayout orientation="horizontal" spacing={2}>
            <KeyboardArrowLeft onClick={handleLeftArrowClick} />
            <Typography variant="h5">{month.format("MMMM YYYY")}</Typography>
            <KeyboardArrowRight onClick={handleRightArrowClick} />
          </LinearLayout>
        </Grid>
        <Grid item={true} xs={12}>
          <List>
            {blockedDates.map((blockedDate) => {
              return (
                <ListItem key={blockedDate.id}>
                  <Grid item={true} xs={12}>
                    <Card>
                      <CardContent>
                        <Typography>
                          {formatBlockedDateTimeRange(blockedDate)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          color="secondary"
                          onClick={createDeleteBlockedDateHandler(blockedDate)}
                          size="small"
                        >
                          <I18nText i18nKey="pages.salon_blocked_dates.buttons.delete_blocked_date.label" />
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

function getMonthFromUrl(search: string): Moment {
  const { month } = queryString.parse(search);
  return moment(month);
}

function formatMonthForUrl(month: Moment): string {
  return month.format("YYYY-MM");
}

function formatBlockedDateTimeRange(
  blockedDate: SalonBlockedDatesPage_ChairBlockedDateFragment
) {
  return `${moment.utc(blockedDate.startDate).format("LL")} - ${moment
    .utc(blockedDate.endDate)
    .format("LL")}`;
}

function getUrl(
  location: Location,
  { chairId, month }: { chairId?: number | string; month?: Moment }
) {
  const queryParams = queryString.parse(location.search);
  const updatedQueryParams = { ...queryParams };
  if (chairId) {
    updatedQueryParams.chairId = chairId;
  }
  if (month) {
    updatedQueryParams.month = formatMonthForUrl(month);
  }
  return `${location.pathname}?${queryString.stringify(updatedQueryParams)}`;
}
