import omit from "lodash/omit";
import partial from "lodash/partial";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { ApolloError } from "apollo-client";
import { gql } from "apollo-boost";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import { FormErrorMessage } from "component/FormErrorMessage";
import { I18nText } from "component/I18nText";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { ObjectFieldChangeCallback } from "form/index";
import { getFormObject } from "form/index";
import { EntityState } from "model/index";
import { FormError } from "model/index";
import { bindActionCreators } from "tools/index";
import { useNavigationActions } from "tools/useNavigationActions";

import { EditUserProfileFormActions } from "./form/state";
import { EditUserProfileFormState } from "./form/state";
import { EditUserProfileState } from "./form/state";
import { UserProfileFormState } from "./form/state";
import { UserProfileFormStep } from "./form/state";
import { editUserProfileFormSteps } from "./form/state";
import { emptyLicense } from "./form/state";

import {
  EditUserProfilePage_GetUserProfileQuery,
  EditUserProfilePage_GetUserProfileQueryVariables,
  EditUserProfilePage_UpdateUserProfileMutation,
  EditUserProfilePage_UpdateUserProfileMutationVariables,
  EditUserProfilePage_UserProfileFragment,
} from "./EditUserProfilePage.generated";

const stylistLicenseFragment = gql`
  fragment EditUserProfilePage_stylistLicense on StylistLicense {
    id
    number
    state
    type
    expirationDate
  }
`;

const userProfileFragment = gql`
  fragment EditUserProfilePage_userProfile on UserProfile {
    userId
    email
    firstName
    lastName
    phoneNumber
    professionalRole
    description
    specialtyTypes
    socialMediaFacebook
    socialMediaInstagram
    socialMediaTwitter
    socialMediaWebsite
    socialMediaYelp
    images {
      id
      publicId
      url
    }
    stylistLicenses {
      ...EditUserProfilePage_stylistLicense
    }
  }
  ${stylistLicenseFragment}
`;

const GET_USER_PROFILE = gql`
  query EditUserProfilePage_GetUserProfile($id: ID!) {
    userProfileById(id: $id) {
      ...EditUserProfilePage_userProfile
    }
  }
  ${userProfileFragment}
`;

const UPDATE_USER_PROFILE = gql`
  mutation EditUserProfilePage_UpdateUserProfile(
    $input: UpdateUserProfileInput!
  ) {
    updateUserProfile(input: $input) {
      userProfile {
        userId
      }
    }
  }
`;

export const EditUserProfilePageContainer = () => {
  const params = useParams<{ id: string }>();
  const navigationActions = useNavigationActions();
  const { id } = params;

  const { data, error, loading } = useQuery<
    EditUserProfilePage_GetUserProfileQuery,
    EditUserProfilePage_GetUserProfileQueryVariables
  >(GET_USER_PROFILE, {
    variables: { id },
  });
  const [updateUserProfile, { error: updateUserProfileError }] = useMutation<
    EditUserProfilePage_UpdateUserProfileMutation,
    EditUserProfilePage_UpdateUserProfileMutationVariables
  >(UPDATE_USER_PROFILE, {
    onCompleted: (updatedData) => {
      if (updatedData.updateUserProfile?.userProfile) {
        navigationActions.gotoUserProfilePage(
          updatedData.updateUserProfile.userProfile.userId,
          {
            refetch: true,
          }
        );
      }
    },
  });
  const updateUserProfileMapped = (
    userProfileFormState: UserProfileFormState
  ) => {
    // Map the existing query message to match the mutation input
    const mappedUserProfile = {
      ...omit(userProfileFormState, ["__typename", "userId"]),
      images: userProfileFormState.images.map((i) => omit(i, ["__typename"])),
      stylistLicenses: userProfileFormState.stylistLicenses.map((l) =>
        omit(l, l.id === emptyLicense.id ? ["__typename", "id"] : "__typename")
      ),
    };
    updateUserProfile({
      variables: { input: { id, userProfile: mappedUserProfile } },
    });
  };

  const userProfile = data?.userProfileById;

  const validationErrors: ReadonlyArray<FormError> = React.useMemo(() => {
    return (
      updateUserProfileError?.graphQLErrors.reduce((acc, graphQLError) => {
        return acc.concat(
          Object.entries(graphQLError.extensions!).map(([field, value]) => {
            return { field, value };
          })
        );
      }, [] as Array<FormError>) ?? []
    );
  }, [updateUserProfileError]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error || !userProfile) {
    return (
      <Typography align="center" color="error" variant="h5">
        <I18nText i18nKey={`common.error`} />
      </Typography>
    );
  }
  return (
    <EditUserProfilePage
      userProfile={userProfile}
      updateUserProfile={updateUserProfileMapped}
      updateUserProfileError={updateUserProfileError}
      validationErrors={validationErrors}
    />
  );
};

export interface EditUserProfilePageClassProps {
  editUserProfileFormActions: EditUserProfileFormActions;
  editUserProfileFormState: EditUserProfileFormState;
  entityState: EntityState;
  userProfile: EditUserProfilePage_UserProfileFragment;
  updateUserProfile: (userProfile: UserProfileFormState) => void;
  updateUserProfileError?: ApolloError;
  validationErrors: ReadonlyArray<FormError>;
}

export type EditUserProfilePageProps = EditUserProfilePageClassProps &
  RouteComponentProps<{ id: string }>;

export class EditUserProfilePageClass extends React.Component<
  EditUserProfilePageProps
> {
  componentDidMount() {
    const { userProfile } = this.props;
    this.props.editUserProfileFormActions.createObject(
      userProfile.userId,
      userProfile
    );
  }

  render() {
    const { updateUserProfileError, validationErrors } = this.props;

    const { match } = this.props;
    return (
      <span>
        <Route exact={true} path={match.url} render={this.mainRedirect} />
        {updateUserProfileError && (
          <FormErrorMessage error={updateUserProfileError} />
        )}
        <Switch>
          {editUserProfileFormSteps.map((formStep, i) => {
            return (
              <Route
                key={formStep.path}
                path={`${match.url}/${formStep.path}`}
                render={this.createFormStep(formStep, validationErrors)}
              />
            );
          })}
        </Switch>
      </span>
    );
  }

  private mainRedirect = () => {
    const { match } = this.props;
    return <Redirect to={`${match.url}/user_profile_details`} />;
  };

  private createFormStep(
    formStep: UserProfileFormStep,
    errors: ReadonlyArray<FormError>
  ) {
    const userId = this.props.match.params.id;
    const onUserProfileFieldChange: ObjectFieldChangeCallback<
      UserProfileFormState,
      keyof UserProfileFormState
    > = partial(
      this.props.editUserProfileFormActions.updateField,
      userId
    ) as any;
    const moveForward = (e?: React.FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      this.props.editUserProfileFormActions.moveForward(userId, formStep);
    };
    const moveBackward = (e?: React.FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      this.props.editUserProfileFormActions.moveBackward(userId, formStep);
    };
    return () => {
      const userProfile = getFormObject(
        this.props.editUserProfileFormState,
        userId
      );
      if (!userProfile) {
        return null;
      }
      const { component: Component } = formStep;
      return (
        <Component
          errors={errors}
          object={userProfile}
          onObjectFieldChange={onUserProfileFieldChange}
          onPrevious={moveBackward}
          onSubmit={moveForward}
        />
      );
    };
  }
}

type UnconnectedEditUserProfilePageProps = Omit<
  EditUserProfilePageProps,
  "editUserProfileFormActions" | "editUserProfileFormState" | "entityState"
>;

const mapStateToProps = (state: EditUserProfileState) => {
  const { editUserProfileFormState, entityState } = state;
  return {
    editUserProfileFormState,
    entityState,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: UnconnectedEditUserProfilePageProps
) => {
  const editUserProfileFormActions = new EditUserProfileFormActions(
    props.match.url,
    props.updateUserProfile
  );
  return {
    editUserProfileFormActions: bindActionCreators(
      editUserProfileFormActions,
      dispatch
    ),
  };
};

export const EditUserProfilePage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditUserProfilePageClass)
);
