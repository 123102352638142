import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { SalonCheckBoxItem } from "component/SalonCheckBoxItem";
import { FormProps } from "form/index";
import { createArrayEventHandler } from "form/index";

import { UserProfileFormState } from "../form/state";

export type UserProfileSpecialtyTypeFormProps = FormProps<
  UserProfileFormState,
  keyof UserProfileFormState
>;

const allSpecialtyTypes = [0, 1, 2, 3, 4, 5, 6, 7, 8];

export class UserProfileSpecialtyTypeForm extends React.Component<
  UserProfileSpecialtyTypeFormProps
> {
  render() {
    const specialtyTypes = this.props.object.specialtyTypes;
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.specialty_types.headline" />}
      >
        <Grid item={true} xs={12}>
          <FormGroup>
            {allSpecialtyTypes.map((specialty) => {
              return (
                <SalonCheckBoxItem
                  checked={!!specialtyTypes?.includes(specialty)}
                  handleChange={createArrayEventHandler(
                    this.props,
                    "specialtyTypes" as any,
                    [specialty]
                  )}
                  label={
                    <I18nText
                      i18nKey={`select_list.specialty_types.${specialty}`}
                    />
                  }
                  key={specialty}
                />
              );
            })}
          </FormGroup>
        </Grid>
      </FormWrapper>
    );
  }
}
