import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { SalonCheckBoxItem } from "component/SalonCheckBoxItem";
import { FormProps } from "form/index";
import { createArrayEventHandler } from "form/index";

import { SalonFormState } from "../../form/state";

export type SalonEquipmentFormProps = FormProps<
  SalonFormState,
  keyof SalonFormState
>;
const allEquipment = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export class SalonEquipmentForm extends React.Component<
  SalonEquipmentFormProps
> {
  render() {
    const equipmentList = this.props.object.equipment;
    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_equipment.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_equipment.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <FormGroup>
            {allEquipment.map((equipmentItem) => {
              return (
                <SalonCheckBoxItem
                  checked={equipmentList.includes(equipmentItem)}
                  data-test-id={`equipment-${equipmentItem}-input`}
                  handleChange={createArrayEventHandler(
                    this.props,
                    "equipment",
                    [equipmentItem]
                  )}
                  label={
                    <I18nText
                      i18nKey={`select_list.equipment.${equipmentItem}`}
                    />
                  }
                  key={equipmentItem}
                />
              );
            })}
          </FormGroup>
        </Grid>
      </FormWrapper>
    );
  }
}
