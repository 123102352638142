import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

import { Padding } from "./Padding";
import { Spacing } from "./Padding";

const styles: any = (theme: Theme) => ({
  orientationHorizontal: {
    flexDirection: "row",
  },
  orientationVertical: {
    flexDirection: "column",
  },
  root: {
    display: "flex",
  },
});

interface LinearLayoutProps
  extends Partial<LinearLayoutDefaultProps>,
    WithStyles<typeof styles> {
  children?: React.ReactNode;
  orientation: "horizontal" | "vertical";
}

interface LinearLayoutDefaultProps {
  spacing: Spacing;
}

type LinearLayoutPropsWithDefaults = LinearLayoutProps &
  LinearLayoutDefaultProps;

export class LinearLayoutClass extends React.Component<LinearLayoutProps> {
  static defaultProps: LinearLayoutDefaultProps = {
    spacing: 0,
  };

  render() {
    const { classes, orientation, spacing } = this
      .props as LinearLayoutPropsWithDefaults;
    const className = classnames(classes.root, {
      [classes.orientationHorizontal]: orientation === "horizontal",
      [classes.orientationVertical]: orientation === "vertical",
    });
    return (
      <div className={className}>
        {spacing > 0 ? this.childrenWithSpacing() : this.props.children}
      </div>
    );
  }

  private childrenWithSpacing() {
    const { children, orientation, spacing } = this
      .props as LinearLayoutPropsWithDefaults;
    let isFirst = true;
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        if (isFirst) {
          isFirst = false;
          return child;
        } else if (orientation === "vertical") {
          return <Padding paddingTop={spacing}>{child}</Padding>;
        } else {
          return <Padding paddingLeft={spacing}>{child}</Padding>;
        }
      }
      return child;
    });
  }
}

export const LinearLayout = withStyles(styles)(LinearLayoutClass);
