import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import Grid from "@material-ui/core/Grid";
import { gql } from "apollo-boost";
import React from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import Button from "component/modules/Button";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import { NavigationActions } from "tools/NavigationActions";
import { useNavigationActions } from "tools/useNavigationActions";

import {
  SalonChairsQuery,
  SalonChairsQueryVariables,
  SalonChairsPage_ChairFragment,
} from "./SalonChairsPage.generated";

const chairFragment = gql`
  fragment SalonChairsPage_chair on Chair {
    id
    title
  }
`;

const SALON_CHAIRS_QUERY = gql`
  query SalonChairs($id: ID!) {
    salonById(id: $id) {
      id
      chairs {
        ...SalonChairsPage_chair
      }
    }
  }
  ${chairFragment}
`;

export const SalonChairsPage = (props: any) => {
  const navigationActions = useNavigationActions();
  const location = useLocation();
  const { salonId } = useParams<{ salonId: string }>();
  const state = location.state as any;
  const salonChairsQueryResult = useQuery<
    SalonChairsQuery,
    SalonChairsQueryVariables
  >(SALON_CHAIRS_QUERY, {
    fetchPolicy: state?.refetch && "network-only",
    variables: {
      id: salonId,
    },
  });

  return (
    <SalonChairsPagePresentation
      navigationActions={navigationActions}
      salonChairsQueryResult={salonChairsQueryResult}
    />
  );
};

export type SalonChairsPagePresentationProps = Readonly<{
  navigationActions: NavigationActions;
  salonChairsQueryResult: QueryResult<SalonChairsQuery>;
}>;

export const SalonChairsPagePresentation = (
  props: SalonChairsPagePresentationProps
) => {
  const { navigationActions, salonChairsQueryResult } = props;
  const { data, error, loading } = salonChairsQueryResult;
  const salon = data?.salonById;

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error || !salon) {
    return (
      <Typography align="center" color="error" variant="h5">
        <I18nText i18nKey={`common.error`} />
      </Typography>
    );
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    navigationActions.gotoSalonProfileEditPage(salon.id);
  };
  const handleAddChair = () => {
    navigationActions.gotoCreateChairPage(salon.id);
  };
  const createHandleEditChair = (
    chair: SalonChairsPage_ChairFragment
  ) => () => {
    navigationActions.gotoEditChairPage(salon.id, chair.id);
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      headline={<I18nText i18nKey="forms.chair_list.title" />}
    >
      <Grid item={true}>
        <Button
          size="small"
          data-test-id="salon-chairs-add"
          variant="contained"
          color="secondary"
          onClick={handleAddChair}
        >
          <I18nText i18nKey="forms.chair_list.buttons.add_chair.label" />
        </Button>
      </Grid>
      {salon.chairs.map((chair) => {
        return (
          <Grid item={true} xs={12} key={chair.id}>
            <div>
              <Typography variant="h5">{chair.title}</Typography>
              <Button
                color="secondary"
                size="small"
                onClick={createHandleEditChair(chair)}
              >
                <I18nText i18nKey="forms.chair_list.buttons.edit.label" />
              </Button>
            </div>
          </Grid>
        );
      })}
    </FormWrapper>
  );
};
