import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { gql } from "apollo-boost";
import React from "react";

import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { useNavigationActions } from "tools/useNavigationActions";

import { SalonList_SalonFragment } from "./SalonList.generated";

export const fragments = {
  salon: gql`
    fragment SalonList_salon on Salon {
      id
      name
      address
    }
  `,
};

export type SalonListProps = Readonly<{
  salons: ReadonlyArray<SalonList_SalonFragment>;
}>;

export const SalonList = (props: SalonListProps) => {
  const { salons } = props;
  const navigationActions = useNavigationActions();
  const handleClick = (id: string) => {
    navigationActions.gotoAdminSalonPage(id);
  };

  return (
    <>
      {salons.length === 0 && (
        <Typography>
          <I18nText i18nKey="forms.admin.no_salons" />
        </Typography>
      )}
      {salons.length > 0 && (
        <Grid container={true}>
          {salons.map((salon) => {
            return (
              <Grid item={true} xs={12} md={3} key={salon.id}>
                <Card>
                  <CardContent>
                    <Typography
                      onClick={() => handleClick(salon.id)}
                      variant="h5"
                    >
                      {salon.name}
                    </Typography>
                    <Typography>{salon.address}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

SalonList.displayName = "SalonList";
