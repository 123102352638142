import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";

import { SessionActions } from "action/SessionActions";
import { UserProfile } from "model/index";
import { WithNavigationProvidedProps } from "tools/withNavigation";
import { withNavigation } from "tools/withNavigation";

import { I18nText } from "./I18nText";
import { UnstyledAnchor } from "./UnstyledLink";
import { UnstyledLink } from "./UnstyledLink";
import Typography from "./modules/Typography";

interface SideMenuClassProps {
  onRequestClose: () => void;
  open: boolean;
  sessionActions: SessionActions;
  user?: UserProfile;
}

export type SideMenuProps = SideMenuClassProps &
  WithNavigationProvidedProps<void>;

class SideMenuClass extends React.Component<SideMenuProps> {
  render() {
    return (
      <Drawer onClose={this.props.onRequestClose} open={this.props.open}>
        {this.menuItems()}
      </Drawer>
    );
  }

  private menuItems(): JSX.Element {
    const userId = this.props.user?.userId;
    if (userId) {
      return (
        <List>
          {this.createListItem(
            "home",
            this.props.navigationActions.homePagePath
          )}
          <Divider />
          {this.createListItem(
            "profile",
            this.props.navigationActions.getUserProfilePagePath(userId)
          )}
          {this.createListItem(
            "bookings",
            this.props.navigationActions.bookingListingsPagePath
          )}
          {this.createListItem(
            "your_salons",
            this.props.navigationActions.salonListingsPagePath
          )}
          {this.createListItem(
            "add_salon",
            this.props.navigationActions.createSalonPagePath
          )}
          <Divider />
          {this.createListItem(
            "becoming_a_host",
            this.props.navigationActions.becomingAHostPagePath
          )}
          {this.createListItem(
            "about_us",
            this.props.navigationActions.aboutUsPagePath
          )}
          {this.createListItem("support", undefined, SUPPORT_URL)}
          <Divider />
          {this.createListItem(
            "log_out",
            undefined,
            undefined,
            this.props.sessionActions.logout
          )}
        </List>
      );
    } else {
      return (
        <List>
          {this.createListItem(
            "login",
            undefined,
            undefined,
            this.props.sessionActions.login
          )}
          {this.createListItem(
            "sign_up",
            undefined,
            undefined,
            this.props.sessionActions.startSignUp
          )}
          <Divider />
          {this.createListItem(
            "becoming_a_host",
            this.props.navigationActions.becomingAHostPagePath
          )}
          {this.createListItem(
            "about_us",
            this.props.navigationActions.aboutUsPagePath
          )}
          {this.createListItem("support", undefined, SUPPORT_URL)}
        </List>
      );
    }
  }

  private createListItem(
    label: string,
    path?: string,
    url?: string,
    action?: any
  ) {
    const labelComponent = (
      <ListItem button={true} onClick={action || this.props.onRequestClose}>
        <ListItemText
          primary={
            <Typography variant="h6">
              <I18nText i18nKey={`component.side_menu.labels.${label}`} />
            </Typography>
          }
        />
      </ListItem>
    );
    if (path) {
      return <UnstyledLink to={path}>{labelComponent}</UnstyledLink>;
    } else if (url) {
      return <UnstyledAnchor href={url}>{labelComponent}</UnstyledAnchor>;
    } else {
      return labelComponent;
    }
  }
}

export const SideMenu = withNavigation(SideMenuClass);

const SUPPORT_URL = "https://support.getchaired.com/";
