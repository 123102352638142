import omit from "lodash/omit";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ApolloError } from "apollo-client";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";

import { I18nText } from "component/I18nText";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { bindActionCreators } from "tools/index";
import { useNavigationActions } from "tools/useNavigationActions";

import { ChairForm } from "./component/ChairForm";
import { CreateChairFormActions } from "./form/state";
import { CreateOrEditChairPageState } from "./model";
import { ChairFormState } from "./model";
import { CreateChairFormState } from "./model";

import {
  EditChairPage_GetChairQuery,
  EditChairPage_GetChairQueryVariables,
  EditChairPage_UpdateChairMutation,
  EditChairPage_UpdateChairMutationVariables,
} from "./EditChairPage.generated";

const chairFragment = gql`
  fragment EditChairPage_chair on Chair {
    id
    type
    price
    title
    description
    amenities
    squareFootage
    stationCount
  }
`;

const GET_CHAIR = gql`
  query EditChairPage_GetChair($id: ID!) {
    chairById(id: $id) {
      ...EditChairPage_chair
    }
  }
  ${chairFragment}
`;

const UPDATE_CHAIR = gql`
  mutation EditChairPage_UpdateChair($input: UpdateChairInput!) {
    updateChair(input: $input) {
      chair {
        ...EditChairPage_chair
      }
    }
  }
  ${chairFragment}
`;

export const EditChairPageConatiner = () => {
  const { salonId, chairId } = useParams<{
    salonId: string;
    chairId: string;
  }>();
  const navigationActions = useNavigationActions();

  const { data, error, loading } = useQuery<
    EditChairPage_GetChairQuery,
    EditChairPage_GetChairQueryVariables
  >(GET_CHAIR, {
    variables: { id: chairId },
  });

  const [updateChair, { error: updateChairError }] = useMutation<
    EditChairPage_UpdateChairMutation,
    EditChairPage_UpdateChairMutationVariables
  >(UPDATE_CHAIR, {
    onCompleted: (updatedData) => {
      if (updatedData.updateChair?.chair) {
        navigationActions.gotoSalonChairsPage(salonId);
      }
    },
  });

  const mappedUpdateChair = (chairFormState: ChairFormState) => {
    const mappedChair = {
      ...omit(chairFormState, ["__typename", "id"]),
    };
    updateChair({
      variables: { input: { id: chairId, chair: mappedChair } },
    });
  };

  const chair = data?.chairById;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error || !chair) {
    return (
      <Typography align="center" color="error" variant="h5">
        <I18nText i18nKey={`common.error`} />
      </Typography>
    );
  }
  return (
    <EditChairPage
      chair={chair}
      updateChair={mappedUpdateChair}
      updateChairError={updateChairError}
    />
  );
};

export interface EditChairPageClassProps {
  chair: ChairFormState;
  createChairFormActions: CreateChairFormActions;
  createChairFormState: CreateChairFormState;
  updateChair: (chair: ChairFormState) => void;
  updateChairError?: ApolloError;
}

export type EditChairPageProps = EditChairPageClassProps &
  RouteComponentProps<void>;

export class EditChairPageClass extends React.Component<EditChairPageProps> {
  constructor(props: EditChairPageProps) {
    super(props);

    props.createChairFormActions.createObject(props.chair.id, props.chair);
  }

  render() {
    return (
      <ChairForm
        chairFormError={this.props.updateChairError}
        createChairFormActions={this.props.createChairFormActions}
        createChairFormState={this.props.createChairFormState}
        id={this.props.chair.id}
        url={this.props.match.url}
      />
    );
  }
}

type UnconnectedEditChairPageProps = Omit<
  EditChairPageProps,
  "createChairFormActions" | "createChairFormState"
>;

const mapStateToProps = (state: CreateOrEditChairPageState) => {
  const { createChairFormState } = state;
  return {
    createChairFormState,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: UnconnectedEditChairPageProps
) => {
  const createChairFormActions = new CreateChairFormActions(
    props.match.url,
    props.updateChair
  );
  return {
    createChairFormActions: bindActionCreators(
      createChairFormActions,
      dispatch
    ),
  };
};

export const EditChairPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditChairPageClass)
);
