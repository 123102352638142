import { ReducersMapObject } from "redux";

import { createChairFormReducer } from "./form/state";

export function getReducer(): ReducersMapObject {
  return {
    createChairFormState: createChairFormReducer,
  };
}

export { CreateChairPageConatiner as CreateChairPage } from "./CreateChairPage";
export { EditChairPageConatiner as EditChairPage } from "./EditChairPage";
