import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./MultiDateRangePicker.css";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { Moment } from "moment";
import React from "react";
// @ts-ignore
import DateRangePicker from "react-dates/lib/components/DateRangePicker";
import { FocusedInputShape } from "react-dates";

import { I18nText } from "component/I18nText";
import { LinearLayout } from "component/LinearLayout";
import Button from "component/modules/Button";

export interface DateRange {
  endDate: Moment;
  startDate: Moment;
}

export interface MultiDateRangePickerProps {
  dateRanges: ReadonlyArray<DateRange>;
  endDate: Moment | null;
  focusedInput: any;
  isDayBlocked: (day: Moment) => boolean;
  onDateRangeAdded: (dateRange: DateRange) => void;
  onDateRangeRemoved: (dateRange: DateRange) => void;
  onDatesChange: (arg: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => void;
  onFocusChange: (focusedInput: FocusedInputShape) => void;
  startDate: Moment | null;
}

const MultiDateRangePicker: React.SFC<MultiDateRangePickerProps> = (
  props: MultiDateRangePickerProps
) => {
  const selectedDateRanges = props.dateRanges.map((dateRange, index) => {
    return (
      <ListItem key={index}>
        <LinearLayout orientation="horizontal" spacing={1}>
          <Typography variant="body1">
            {dateRange.startDate.format("LL")} -{" "}
            {dateRange.endDate.format("LL")}
          </Typography>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => props.onDateRangeRemoved(dateRange)}
          >
            <I18nText i18nKey="common.remove" />
          </Button>
        </LinearLayout>
      </ListItem>
    );
  });
  const onDateRangeAdded = () => {
    const { endDate, startDate } = props;
    if (endDate && startDate) {
      props.onDateRangeAdded({ endDate, startDate });
    }
  };
  return (
    <List>
      {selectedDateRanges}
      <ListItem disableGutters={true}>
        <LinearLayout orientation="horizontal" spacing={1}>
          <DateRangePicker
            isDayBlocked={props.isDayBlocked}
            minimumNights={0}
            numberOfMonths={1}
            startDateId="startDate"
            endDateId="endDate"
            startDate={props.startDate}
            endDate={props.endDate}
            onDatesChange={props.onDatesChange}
            focusedInput={props.focusedInput}
            onFocusChange={props.onFocusChange}
          />
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={onDateRangeAdded}
          >
            <I18nText i18nKey="common.add" />
          </Button>
        </LinearLayout>
      </ListItem>
    </List>
  );
};

MultiDateRangePicker.displayName = "MultiDateRangePicker";

export { MultiDateRangePicker };
