import omit from "lodash/omit";
import partial from "lodash/partial";

import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ApolloError } from "apollo-client";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";

import { FormErrorMessage } from "component/FormErrorMessage";
import { I18nText } from "component/I18nText";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { FormError } from "model/index";
import { ObjectFieldChangeCallback } from "form/index";
import { getFormObject } from "form/index";
import { bindActionCreators } from "tools/index";
import { useNavigationActions } from "tools/useNavigationActions";

import { EditSalonProfileFormActions } from "./form/state";
import { EditSalonProfileFormState } from "./form/state";
import { EditSalonProfileState } from "./form/state";
import { SalonFormState } from "./form/state";
import { SalonFormStep } from "./form/state";
import { editSalonProfileFormSteps } from "./form/state";

import {
  EditSalonProfilePage_GetSalonQuery,
  EditSalonProfilePage_GetSalonQueryVariables,
  EditSalonProfilePage_UpdateSalonMutation,
  EditSalonProfilePage_UpdateSalonMutationVariables,
} from "./EditSalonProfilePage.generated";

const salonFragment = gql`
  fragment EditSalonProfilePage_salon on Salon {
    id
    name
    licenseNumber
    licenseState
    phoneNumber
    address
    location {
      longitude
      latitude
    }
    title
    description
    contactName
    contactEmail
    contactPhoneNumber
    images {
      id
      publicId
      url
    }
    amenities
    equipment
    practices
    rules
    restroomCountMen
    restroomCountUnisex
    restroomCountWomen
    operatingHours {
      id
      dayOfWeek
      startTime
      endTime
    }
    socialMediaFacebook
    socialMediaInstagram
    socialMediaTwitter
    socialMediaWebsite
    socialMediaYelp
  }
`;

const GET_SALON = gql`
  query EditSalonProfilePage_GetSalon($id: ID!) {
    salonById(id: $id) {
      ...EditSalonProfilePage_salon
    }
  }
  ${salonFragment}
`;

const UPDATE_SALON = gql`
  mutation EditSalonProfilePage_UpdateSalon($input: UpdateSalonInput!) {
    updateSalon(input: $input) {
      salon {
        ...EditSalonProfilePage_salon
      }
    }
  }
  ${salonFragment}
`;

export const EditSalonProfilePageConatiner = () => {
  const { id } = useParams<{
    id: string;
  }>();
  const navigationActions = useNavigationActions();

  const { data, error, loading } = useQuery<
    EditSalonProfilePage_GetSalonQuery,
    EditSalonProfilePage_GetSalonQueryVariables
  >(GET_SALON, {
    variables: { id },
  });

  const [updateSalon, { error: updateSalonError }] = useMutation<
    EditSalonProfilePage_UpdateSalonMutation,
    EditSalonProfilePage_UpdateSalonMutationVariables
  >(UPDATE_SALON, {
    onCompleted: (updatedData) => {
      if (updatedData.updateSalon?.salon) {
        navigationActions.gotoSalonDetailsPage(id);
      }
    },
  });

  const mappedUpdateSalon = (salonFormState: SalonFormState) => {
    const mappedSalon: any = {
      ...omit(salonFormState, ["id", "__typename"]),
      images: salonFormState.images.map((i) => omit(i, ["__typename"])),
      location: omit(salonFormState.location, ["__typename"]),
      operatingHours: salonFormState.operatingHours.map((o) =>
        omit(o, "__typename")
      ),
    };
    updateSalon({
      variables: { input: { id, salon: mappedSalon } },
    });
  };

  const salon = data?.salonById;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error || !salon) {
    return (
      <Typography align="center" color="error" variant="h5">
        <I18nText i18nKey={`common.error`} />
      </Typography>
    );
  }
  return (
    <EditSalonProfilePage
      salon={salon}
      updateSalon={mappedUpdateSalon}
      updateSalonError={updateSalonError}
    />
  );
};

export interface EditSalonProfilePageClassProps {
  salon: SalonFormState;
  updateSalon: (salon: SalonFormState) => void;
  updateSalonError?: ApolloError;
  editSalonProfileFormActions: EditSalonProfileFormActions;
  editSalonProfileFormState: EditSalonProfileFormState;
}

export type EditSalonProfilePageProps = EditSalonProfilePageClassProps &
  RouteComponentProps<{ id: string }>;

export class EditSalonProfilePageClass extends React.Component<
  EditSalonProfilePageProps
> {
  componentDidMount() {
    const { salon } = this.props;
    this.props.editSalonProfileFormActions.createObject(salon.id, salon);
  }

  render() {
    const { match, updateSalonError } = this.props;
    const validationErrors: ReadonlyArray<FormError> =
      updateSalonError?.graphQLErrors.reduce((acc, graphQLError) => {
        return acc.concat(
          Object.entries(graphQLError.extensions!).map(([field, value]) => {
            return { field, value };
          })
        );
      }, [] as Array<FormError>) ?? [];
    return (
      <span>
        <Route exact={true} path={match.url} render={this.mainRedirect} />
        {updateSalonError && <FormErrorMessage error={updateSalonError} />}
        <Switch>
          {editSalonProfileFormSteps.map((formStep, i) => {
            return (
              <Route
                key={formStep.path}
                path={`${match.url}/${formStep.path}`}
                render={this.createFormStep(formStep, validationErrors)}
              />
            );
          })}
        </Switch>
      </span>
    );
  }

  private mainRedirect = () => {
    const { match } = this.props;
    return <Redirect to={`${match.url}/salon_amenities`} />;
  };

  private createFormStep(
    formStep: SalonFormStep,
    errors: ReadonlyArray<FormError>
  ) {
    const salonId = this.props.match.params.id;
    const onSalonFieldChange: ObjectFieldChangeCallback<
      SalonFormState,
      keyof SalonFormState
    > = partial(
      this.props.editSalonProfileFormActions.updateField,
      salonId
    ) as any;
    const moveForward = (e?: React.FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      this.props.editSalonProfileFormActions.moveForward(salonId, formStep);
    };
    const moveBackward = (e?: React.FormEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
      }
      this.props.editSalonProfileFormActions.moveBackward(salonId, formStep);
    };
    return () => {
      const salon = getFormObject(
        this.props.editSalonProfileFormState,
        salonId
      );
      if (!salon) {
        return null;
      }
      const { component: Component } = formStep;
      return (
        <Component
          errors={errors}
          object={salon}
          onObjectFieldChange={onSalonFieldChange}
          onPrevious={moveBackward}
          onSubmit={moveForward}
        />
      );
    };
  }
}

type UnconnectedEditSalonProfilePageProps = Omit<
  EditSalonProfilePageProps,
  "editSalonProfileFormActions" | "editSalonProfileFormState"
>;

const mapStateToProps = (state: EditSalonProfileState) => {
  const { editSalonProfileFormState } = state;
  return {
    editSalonProfileFormState,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: UnconnectedEditSalonProfilePageProps
) => {
  const editSalonProfileFormActions = new EditSalonProfileFormActions(
    props.match.url,
    props.updateSalon
  );
  return {
    editSalonProfileFormActions: bindActionCreators(
      editSalonProfileFormActions,
      dispatch
    ),
  };
};

export const EditSalonProfilePage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditSalonProfilePageClass)
);
