import zip from "lodash/zip";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Theme, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { Elements } from "react-stripe-elements";
import { Location } from "history";
import { QueryResult } from "@apollo/react-common";
import { ApolloError } from "apollo-client";
import { gql } from "apollo-boost";
import { useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";

import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { I18nText } from "component/I18nText";
import { NavigationActions } from "tools/NavigationActions";
import { useNavigationActions } from "tools/useNavigationActions";

import { BookingCheckoutForm } from "./component/BookingCheckoutForm";

import { CreateBookingInput } from "types.generated";
import {
  BookingConfirmationQuery,
  BookingConfirmationQueryVariables,
  CreateBookingMutation,
  CreateBookingMutationVariables,
} from "./BookingConfirmationPage.generated";

const BOOKING_CONFIRMATION_QUERY = gql`
  query BookingConfirmation($id: ID!, $flights: [FlightInput!]!) {
    chairById(id: $id) {
      id
      salon {
        id
        name
      }
      priceForBooking(flights: $flights) {
        chairCost
        guestFeeAmount
        totalChargeAmount
      }
    }
  }
`;

const CREATE_BOOKING_MUTATION = gql`
  mutation CreateBooking($input: CreateBookingInput!) {
    createBooking(input: $input) {
      bookings {
        id
      }
    }
  }
`;

export const BookingConfirmationPage = () => {
  const location = useLocation();
  const navigationActions = useNavigationActions();

  const { chairId, checkInDate, checkOutDate } = queryString.parse(
    location.search
  );
  const startDatesAsArray =
    checkInDate instanceof Array ? checkInDate : [checkInDate];
  const endDatesAsArray =
    checkOutDate instanceof Array ? checkOutDate : [checkOutDate];

  const flights = combineDates(startDatesAsArray, endDatesAsArray);

  const bookingConfirmationQueryResult = useQuery<
    BookingConfirmationQuery,
    BookingConfirmationQueryVariables
  >(BOOKING_CONFIRMATION_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      id: chairId,
      flights,
    },
  });

  const [
    createBooking,
    { loading: createBookingLoading, error: createBookingError },
  ] = useMutation<CreateBookingMutation, CreateBookingMutationVariables>(
    CREATE_BOOKING_MUTATION,
    {
      onCompleted: (updatedData) => {
        const bookings = updatedData.createBooking?.bookings;
        if (bookings && bookings.length > 0) {
          navigationActions.gotoBookingListingsPage({
            refetch: true,
            bookingConfirmed: true,
          });
        }
      },
    }
  );
  const handleCreateBooking = (input: CreateBookingInput) => {
    createBooking({ variables: { input } });
  };

  return (
    <BookingConfirmationPagePresentation
      checkInDate={checkInDate}
      checkOutDate={checkOutDate}
      location={location}
      navigationActions={navigationActions}
      queryResult={bookingConfirmationQueryResult}
      onCreateBooking={handleCreateBooking}
      mutationLoading={createBookingLoading}
      mutationError={createBookingError}
    />
  );
};

export type BookingConfirmationPagePresentationProps = Readonly<{
  checkInDate: ReadonlyArray<string>;
  checkOutDate: ReadonlyArray<string>;
  onCreateBooking: (input: CreateBookingInput) => void;
  location: Location;
  queryResult: QueryResult<any>;
  navigationActions: NavigationActions;
  mutationLoading: boolean;
  mutationError: ApolloError | undefined;
}>;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },

  totalPrice: {
    fontWeight: 800,
  },
}));

export const BookingConfirmationPagePresentation = (
  props: BookingConfirmationPagePresentationProps
) => {
  const {
    checkInDate,
    checkOutDate,
    navigationActions,
    queryResult,
    onCreateBooking,
    mutationLoading,
    mutationError,
  } = props;

  const classes = useStyles();
  const { data, error, loading } = queryResult;
  const chairId = data?.chairById.id;
  const salon = data?.chairById?.salon;
  const bookingPrice = data?.chairById?.priceForBooking;

  if (loading || mutationLoading) {
    return <LoadingIndicator />;
  }

  if (
    !salon ||
    !checkInDate ||
    !checkOutDate ||
    checkInDate.length !== checkOutDate.length ||
    error ||
    mutationError
  ) {
    return (
      <div>
        <I18nText i18nKey="pages.booking_confirmation.error" />
      </div>
    );
  }

  const handleCancel = () => {
    navigationActions.gotoSalonDetailsPage(salon.id);
  };

  const handleSubmit = async (stripe: any) => {
    if (chairId && checkInDate && checkOutDate) {
      const { token } = await stripe.createToken();
      const flights = combineDates(checkInDate, checkOutDate);
      onCreateBooking({ token: token.id, chairId, flights });
    }
  };

  const timeRanges = combineDates(checkInDate, checkOutDate);
  return (
    <Container className={classes.container} maxWidth="md">
      <Grid container={true} alignContent="center" spacing={3}>
        <Grid item={true} xs={12}>
          <Typography variant="h4">
            <I18nText
              i18nKey="pages.booking_confirmation.headline"
              name={salon.name}
            />
          </Typography>
        </Grid>
        {timeRanges.map((dateRange: DateRange, index) => {
          return (
            <Grid item={true} key={index} xs={12}>
              <div key={dateRange.startDate}>
                <Typography variant="subtitle1">
                  <I18nText
                    i18nKey="pages.booking_confirmation.titles.start"
                    date={moment(dateRange.startDate).format(
                      "dddd[,] MMM Do YYYY"
                    )}
                  />
                </Typography>
                <Typography variant="subtitle1">
                  <I18nText
                    i18nKey="pages.booking_confirmation.titles.end"
                    date={moment(dateRange.endDate).format(
                      "dddd[,] MMM Do YYYY"
                    )}
                  />
                </Typography>
              </div>
            </Grid>
          );
        })}
        {bookingPrice && (
          <Grid item={true} xs={12}>
            <Typography variant="subtitle1">
              <I18nText
                i18nKey="pages.booking_confirmation.titles.chair_cost"
                value={`$${bookingPrice.chairCost}`}
              />
            </Typography>
            <Typography variant="subtitle1">
              <I18nText
                i18nKey="pages.booking_confirmation.titles.guest_fee"
                value={`$${bookingPrice.guestFeeAmount}`}
              />
            </Typography>
            <Typography className={classes.totalPrice} variant="h6">
              <I18nText
                i18nKey="pages.booking_confirmation.titles.total_price"
                value={`$${bookingPrice.totalChargeAmount}`}
              />
            </Typography>
          </Grid>
        )}
        <Grid item={true} xs={12}>
          <Elements>
            <BookingCheckoutForm
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              pending={loading}
            />
          </Elements>
          {loading || mutationLoading ? (
            <div>
              <I18nText i18nKey="common.loading" />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Container>
  );
};

type DateRange = Readonly<{
  endDate: string;
  startDate: string;
}>;

function combineDates(
  startDates: string | ReadonlyArray<string>,
  endDates: string | ReadonlyArray<string>
): ReadonlyArray<DateRange> {
  const startDatesAsArray =
    startDates instanceof Array ? startDates : [startDates];
  const endDatesAsArray = endDates instanceof Array ? endDates : [endDates];
  return zip(startDatesAsArray, endDatesAsArray).map(([startDate, endDate]) => {
    return {
      endDate,
      startDate,
    };
  });
}
