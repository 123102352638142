import React from "react";

import { SessionActions } from "action/SessionActions";

export interface AuthRedirectProps {
  sessionActions: SessionActions;
}

export class AuthRedirect extends React.Component<AuthRedirectProps> {
  componentDidMount() {
    this.props.sessionActions.loginWithRedirect();
  }

  render() {
    return null;
  }
}
