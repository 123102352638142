import { ThunkAction } from "action/ThunkAction";
import { requestTour as requestTourApi } from "api/salon";
import { AppState } from "model/index";
import { ActionsUnion } from "tools/redux";
import { createAction } from "tools/redux";

export const REQUEST_TOUR_COMPLETED = "REQUEST_TOUR_COMPLETED";
export const REQUEST_TOUR_ERRORED = "REQUEST_TOUR_ERRORED";
export const REQUEST_TOUR_REQUESTED = "REQUEST_TOUR_REQUESTED";

export interface SalonDetailPageState extends AppState {
  salonDetailState: SalonDetailState;
}

const SalonDetailActionCreators = {
  requestTourCompleted: () => createAction(REQUEST_TOUR_COMPLETED),
  requestTourErrored: (error: Error) =>
    createAction(REQUEST_TOUR_ERRORED, error),
  requestTourRequested: () => createAction(REQUEST_TOUR_REQUESTED),
};
export type SalonDetailActionCreators = ActionsUnion<
  typeof SalonDetailActionCreators
>;

export const requestTour = (id: string): ThunkAction => {
  return async (dispatch, _, { fetchV2 }) => {
    dispatch(SalonDetailActionCreators.requestTourRequested());
    try {
      await fetchV2(requestTourApi(id));
      dispatch(SalonDetailActionCreators.requestTourCompleted());
    } catch (error) {
      dispatch(SalonDetailActionCreators.requestTourErrored(error));
    }
  };
};

export type SalonDetailState = Readonly<{
  requestTourError?: Error;
  requestTourStatus: "unknown" | "pending" | "completed";
}>;

const initialState: SalonDetailState = {
  requestTourStatus: "unknown",
};

export function salonDetailReducer(
  state: SalonDetailState = initialState,
  action: SalonDetailActionCreators
): SalonDetailState {
  switch (action.type) {
    case REQUEST_TOUR_REQUESTED: {
      return { ...state, requestTourStatus: "pending" };
    }
    case REQUEST_TOUR_COMPLETED: {
      return { ...state, requestTourStatus: "completed" };
    }
    case REQUEST_TOUR_ERRORED: {
      const requestTourError = action.payload;
      return { ...state, requestTourStatus: "unknown", requestTourError };
    }
    default: {
      return state;
    }
  }
}

export const getRequestTourStatus = (pageState: SalonDetailPageState) => {
  return pageState.salonDetailState.requestTourStatus;
};

export const getRequestTourError = (pageState: SalonDetailPageState) => {
  return pageState.salonDetailState.requestTourError;
};
