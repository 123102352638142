/// <reference types="@types/googlemaps" />

import React from "react";

import "./Map.css";

export interface LatLng {
  lat: number;
  lng: number;
}

export interface MapProps {
  center: LatLng;
  zoom?: number;
}

export interface State {
  map?: google.maps.Map;
}

export const MapContext = React.createContext<google.maps.Map | undefined>(
  undefined
);

export class Map extends React.Component<MapProps, State> {
  state = {
    map: undefined,
  };

  render() {
    return (
      <MapContext.Provider value={this.state.map}>
        <div className="Map" ref={this.handleComponentMount}>
          {this.props.children}
        </div>
      </MapContext.Provider>
    );
  }

  private handleComponentMount = (node: HTMLElement | null) => {
    if (this.state.map || node === null) {
      return;
    }

    const mapOptions = {
      center: this.props.center,
      zoom: this.props.zoom,
    };
    const map = new google.maps.Map(node, mapOptions);
    this.setState({ map });
  };
}
