import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

import { FormWrapper } from "component/FormWrapper";
import { I18nText } from "component/I18nText";
import Typography from "component/modules/Typography";
import { FormProps } from "form/index";
import { createStringEventHandler } from "form/index";
import { getErrorForKey } from "form/index";

import { SalonFormState } from "../form/state";

export type SalonSocialMediaFormProps = FormProps<
  SalonFormState,
  keyof SalonFormState
>;

export class SalonSocialMediaForm extends React.Component<
  SalonSocialMediaFormProps
> {
  render() {
    const socialMediaFacebookError = getErrorForKey(
      this.props.errors,
      "social_media_facebook"
    );
    const socialMediaInstagramError = getErrorForKey(
      this.props.errors,
      "social_media_instagram"
    );
    const socialMediaTwitterError = getErrorForKey(
      this.props.errors,
      "social_media_twitter"
    );
    const socialMediaWebsiteError = getErrorForKey(
      this.props.errors,
      "social_media_website"
    );
    const socialMediaYelpError = getErrorForKey(
      this.props.errors,
      "social_media_yelp"
    );

    return (
      <FormWrapper
        onSubmit={this.props.onSubmit}
        onPrevious={this.props.onPrevious}
        headline={<I18nText i18nKey="forms.salon_social_media.title" />}
      >
        <Grid item={true} xs={12}>
          <Typography variant="subtitle1">
            <I18nText i18nKey="forms.salon_social_media.headline" />
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            data-test-id="social-facebook-input"
            error={!!socialMediaFacebookError}
            fullWidth={true}
            helperText={socialMediaFacebookError}
            label={
              <I18nText i18nKey="forms.salon_social_media.fields.facebook.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaFacebook" as any
            )}
            value={this.props.object.socialMediaFacebook}
          />
          <TextField
            data-test-id="social-instagram-input"
            error={!!socialMediaInstagramError}
            fullWidth={true}
            helperText={socialMediaInstagramError}
            label={
              <I18nText i18nKey="forms.salon_social_media.fields.instagram.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaInstagram" as any
            )}
            value={this.props.object.socialMediaInstagram}
          />
          <TextField
            data-test-id="social-twitter-input"
            error={!!socialMediaTwitterError}
            fullWidth={true}
            helperText={socialMediaTwitterError}
            label={
              <I18nText i18nKey="forms.salon_social_media.fields.twitter.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaTwitter" as any
            )}
            value={this.props.object.socialMediaTwitter}
          />
          <TextField
            data-test-id="social-website-input"
            error={!!socialMediaWebsiteError}
            fullWidth={true}
            helperText={socialMediaWebsiteError}
            label={
              <I18nText i18nKey="forms.salon_social_media.fields.website.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaWebsite" as any
            )}
            value={this.props.object.socialMediaWebsite}
          />
          <TextField
            data-test-id="social-yelp-input"
            error={!!socialMediaYelpError}
            fullWidth={true}
            helperText={socialMediaYelpError}
            label={
              <I18nText i18nKey="forms.salon_social_media.fields.yelp.label" />
            }
            onChange={createStringEventHandler(
              this.props,
              "socialMediaYelp" as any
            )}
            value={this.props.object.socialMediaYelp}
          />
        </Grid>
      </FormWrapper>
    );
  }
}
