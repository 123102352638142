import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { NavigationActions } from "./NavigationActions";

export interface WithNavigationProvidedProps<T> extends RouteComponentProps<T> {
  navigationActions: NavigationActions;
}

export function withNavigation<T extends WithNavigationProvidedProps<any>>(
  ComponentClass: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof WithNavigationProvidedProps<any>>> {
  class WithNavigation extends React.Component<T> {
    render() {
      const navigationActions = new NavigationActions(this.props.history);
      return (
        <ComponentClass {...this.props} navigationActions={navigationActions} />
      );
    }
  }
  return withRouter(WithNavigation) as any;
}
