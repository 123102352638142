import React from "react";
import { AnyAction } from "redux";
import { Reducer } from "redux";
import generateUuid from "uuid/v4";

import { ThunkAction } from "action/ThunkAction";
import { FormError } from "model/index";
import { createAction } from "tools/redux";

export type AsyncMapper<T, K extends keyof T, V> = (v: V) => Promise<T[K]>;

export interface ObjectFieldChangeCallback<T, K extends keyof T> {
  (key: K, value: T[K]): void;
  <V>(key: K, value: V, mapper: AsyncMapper<T, K, V>): void;
}

export interface FormProps<T, K extends keyof T> {
  errors: ReadonlyArray<FormError>;
  object: T;
  onObjectFieldChange: ObjectFieldChangeCallback<T, K>;
  onPrevious: (e?: React.FormEvent<HTMLElement>) => void;
  onSubmit: (e?: React.FormEvent<HTMLElement>) => void;
}

export interface ActionCreators<T, K extends keyof T> {
  createObject(id: string, initialObject: T): AnyAction;
  error(id: string, error: Error): AnyAction;
  updateField(id: string, key: K, value: T[K]): AnyAction;
  validationError(id: string, errors: ReadonlyArray<FormError>): AnyAction;
}

export interface ReduxForm<T> {
  actions: ActionCreators<T, keyof T>;
  reducer: Reducer<FormState<T>>;
}

export function createForm<T>(initialState: FormState<T> = {}): ReduxForm<T> {
  const uuid = generateUuid();
  const FORM_OBJECT_CREATE = `FORM_OBJECT_CREATE_${uuid}`;
  const FORM_OBJECT_FIELD_UPDATE = `FORM_OBJECT_FIELD_UPDATE_${uuid}`;
  const FORM_OBJECT_SUBMIT_ERRORED = `FORM_OBJECT_SUBMIT_ERRORED_${uuid}`;

  const actions = {
    createObject: (id: string, initialObject: T) =>
      createAction(FORM_OBJECT_CREATE, { id, initialObject }),
    error: (id: string, error: Error) =>
      createAction(FORM_OBJECT_SUBMIT_ERRORED, { id, error }),
    updateField: <K extends keyof T>(id: string, key: K, value: T[K]) =>
      createAction(FORM_OBJECT_FIELD_UPDATE, { id, key, value }),
    validationError: (id: string, errors: ReadonlyArray<FormError>) => {
      const error = new ValidationError(errors);
      return createAction(FORM_OBJECT_SUBMIT_ERRORED, { id, error });
    },
  };

  const reducer = (state: FormState<T> = initialState, action: AnyAction) => {
    switch (action.type) {
      case FORM_OBJECT_CREATE: {
        return Object.assign({}, state, {
          [action.payload.id]: {
            error: undefined,
            object: action.payload.initialObject,
          },
        });
      }
      case FORM_OBJECT_FIELD_UPDATE: {
        const object = Object.assign({}, state[action.payload.id].object, {
          [action.payload.key]: action.payload.value,
        });
        return Object.assign({}, state, {
          [action.payload.id]: {
            ...state[action.payload.id],
            object,
          },
        });
      }
      case FORM_OBJECT_SUBMIT_ERRORED: {
        const { error } = action.payload;
        return Object.assign({}, state, {
          [action.payload.id]: {
            ...state[action.payload.id],
            error,
          },
        });
      }
      default: {
        return state;
      }
    }
  };
  return { actions, reducer };
}

export abstract class FormActions<T, K extends Extract<keyof T, string>> {
  constructor(private readonly actionCreators: ActionCreators<T, K>) {}

  createObject(id: string, initialObject: T) {
    return this.actionCreators.createObject(id, initialObject);
  }

  updateField(id: string, key: K, value?: T[K]): ThunkAction;
  updateField<V>(
    id: string,
    key: K,
    value: V,
    mapper: (value: V) => Promise<T[K]>
  ): ThunkAction;
  updateField<V>(
    id: string,
    key: K,
    valueOrUnmappedValue: T[K] | V,
    mapper?: (value: V) => Promise<T[K]>
  ): ThunkAction {
    return async (dispatch) => {
      try {
        const value = mapper
          ? await mapper(valueOrUnmappedValue as V)
          : (valueOrUnmappedValue as T[K]);
        dispatch(this.actionCreators.updateField(id, key, value));
      } catch (error) {
        const formError = {
          field: key,
          value: error.message,
        };
        dispatch(this.actionCreators.validationError(id, [formError]));
      }
    };
  }
}

export interface FormStep<T, K extends keyof T, P extends FormProps<T, K>> {
  component: React.ComponentType<P>;
  path: string;
  show?: (object: T) => boolean;
}

export function findNextStep<T, K extends keyof T, P extends FormProps<T, K>>(
  steps: ReadonlyArray<FormStep<T, K, P>>,
  currentStep: FormStep<T, K, P>,
  object: T
) {
  let nextIndex = steps.indexOf(currentStep) + 1;
  while (nextIndex < steps.length) {
    const nextStep = steps[nextIndex];
    if (!nextStep.show || nextStep.show!(object)) {
      return nextStep;
    }
    nextIndex++;
  }
  return undefined;
}

export function findPreviousStep<
  T,
  K extends keyof T,
  P extends FormProps<T, K>
>(
  steps: ReadonlyArray<FormStep<T, K, P>>,
  currentStep: FormStep<T, K, P>,
  object: T
) {
  let previousIndex = steps.indexOf(currentStep) - 1;
  while (previousIndex >= 0) {
    const previousStep = steps[previousIndex];
    if (!previousStep.show || previousStep.show!(object)) {
      return previousStep;
    }
    previousIndex--;
  }
  return undefined;
}

export interface FormStateObject<T> {
  error?: Error;
  object: T;
}
export type FormState<T> = Readonly<{
  [id: string]: FormStateObject<T>;
}>;

export function getFormObject<T>(state: FormState<T>, id: string) {
  return state[id] && state[id].object;
}

export function getFormObjectErrors<T>(state: FormState<T>, id: string) {
  const formObjectError = getFormObjectError(state, id);
  return formObjectError instanceof ValidationError
    ? formObjectError.errors
    : [];
}

export function getFormObjectError<T>(state: FormState<T>, id: string) {
  return state[id] && state[id].error;
}

export class ValidationError extends Error {
  constructor(
    public readonly errors: ReadonlyArray<FormError>,
    message?: string
  ) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export function getErrorForKey<T>(
  errors: ReadonlyArray<FormError>,
  key: string
) {
  const formError = errors.find((e) => e.field === key);
  return formError && formError.value;
}

type PropertyNames<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];
type StringPropertyNames<T> = PropertyNames<T, string>;
type NumberPropertyNames<T> = PropertyNames<T, number>;
type ArrayPropertyNames<T> = PropertyNames<T, ReadonlyArray<any>>;

export function createStringEventHandler<
  T,
  K extends keyof T,
  I extends StringPropertyNames<T>
>(props: FormProps<T, K>, key: I) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onObjectFieldChange(key as any, e.currentTarget.value as any);
  };
}

export function createNumberEventHandler<
  T,
  K extends keyof T,
  I extends NumberPropertyNames<T>
>(props: FormProps<T, K>, key: I) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseInt(e.currentTarget.value, 10);
    props.onObjectFieldChange(key as any, numberValue as any);
  };
}

export function createArrayEventHandler<
  T,
  K extends keyof T,
  I extends ArrayPropertyNames<T>
>(props: FormProps<T, K>, key: I, values: T[I]) {
  return (checked: boolean) => {
    const currentValues: ReadonlyArray<any> = (props.object[key] as any) || [];
    const newValues = checked
      ? [...currentValues, ...(values as any)]
      : currentValues.filter((v) => !(values as any).includes(v));
    props.onObjectFieldChange(key as any, newValues as any);
  };
}

export function createSelectEventHandlerFactory<U>() {
  return <T, K extends keyof T, I extends PropertyNames<T, U>>(
    props: FormProps<T, K>,
    key: I
  ) => {
    return (e: React.ChangeEvent<{ value: any }>) => {
      const numberValue: any = parseInt(e.target.value, 10);
      props.onObjectFieldChange(
        key as any,
        isNaN(numberValue) ? e.target.value : numberValue
      );
    };
  };
}
