import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import React from "react";

const styles: any = {
  container: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
};

export interface CenterProps extends WithStyles<typeof styles> {
  children?: React.ReactNode;
}

const CenterWithoutStyles = (props: CenterProps) => {
  return <div className={props.classes.container}>{props.children}</div>;
};

export const Center = withStyles(styles)(CenterWithoutStyles);
