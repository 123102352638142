import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

export type SpaceLicenseAgreementPageProps = WithStyles<typeof styles>;

const styles = (theme: Theme) => ({
  body: { paddingBottom: theme.spacing(2) },
  container: {
    margin: theme.spacing(2),
  },
});

const SpaceLicenseAgreementPageFunction: React.FunctionComponent<SpaceLicenseAgreementPageProps> = (
  props
) => {
  const classes = props.classes;
  const { t } = useTranslation();
  const content: Array<{
    headline: string;
    body: { paragraphs: Array<{ body: string }> };
  }> = t("pages.space_license_agreement.content", { returnObjects: true });

  return (
    <div className={classes.container}>
      <Grid container={true} spacing={5} justify="center" direction="column">
        <Grid item={true} xs={12}>
          {content.map(({ headline, body }, index) => (
            <React.Fragment key={index}>
              <Typography variant="h5" component="h3">
                {headline}
              </Typography>
              {body.paragraphs.map((paragraph) => (
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.body}
                >
                  {paragraph.body}
                </Typography>
              ))}
            </React.Fragment>
          ))}
          <Typography variant="body1" component="p" align="center">
            {t("pages.space_license_agreement.footer.body")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const SpaceLicenseAgreementPage = withStyles(styles)(
  SpaceLicenseAgreementPageFunction
);
