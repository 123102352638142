import orderBy from "lodash/orderBy";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

import { I18nText } from "component/I18nText";
import Button from "component/modules/Button";
import Typography from "component/modules/Typography";
import { UnstyledLink } from "component/UnstyledLink";
import { Salon } from "model/index";
import { WithNavigationProvidedProps } from "tools/withNavigation";
import { withNavigation } from "tools/withNavigation";

export type SalonListingsPageStyles = WithStyles<typeof styles>;

export interface SalonListingCardClassProps {
  onClick: () => void;
  salon: Salon;
}

export type SalonListingCardProps = SalonListingCardClassProps &
  SalonListingsPageStyles &
  WithNavigationProvidedProps<void>;

const styles: any = (theme: Theme) => ({
  media: {
    height: 200,
    objectFit: "cover",
  },

  spacer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
});

class SalonListingCardClass extends React.Component<SalonListingCardProps> {
  render() {
    const { classes, salon } = this.props;
    const lowestChairPrice =
      salon.chairs.length > 0
        ? `$${orderBy(salon.chairs, "price", ["asc"])[0].price}`
        : "--";
    return (
      <Card>
        <CardMedia
          className={classes.media}
          component="img"
          onClick={this.props.onClick}
          src={
            salon.images && salon.images.length > 0
              ? salon.images[0].url
              : "https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_400/v1551749522/siteImageAssets/Bkgrnd324.jpg"
          }
          title="Salon Image"
        />
        <CardContent onClick={this.props.onClick}>
          <Typography className={classes.spacer} variant="h5">
            {salon.name}
          </Typography>
          <Typography className={classes.spacer} variant="subtitle1">
            {salon.title}
          </Typography>
          <Typography className={classes.spacer} variant="body1">
            {salon.address}
          </Typography>
          <Typography className={classes.spacer} variant="body1">
            {lowestChairPrice}
          </Typography>
        </CardContent>
        <CardActions>
          <UnstyledLink
            to={this.props.navigationActions.getEditSalonPagePath(salon.id)}
          >
            <Button align="center" color="secondary" size="small">
              <I18nText i18nKey="pages.salon_listings.buttons.edit.label" />
            </Button>
          </UnstyledLink>

          <UnstyledLink
            to={this.props.navigationActions.getSalonBookingsPagePath(salon.id)}
          >
            <Button align="center" color="secondary" size="small">
              <I18nText i18nKey="pages.salon_listings.buttons.view_bookings.label" />
            </Button>
          </UnstyledLink>

          <UnstyledLink
            to={this.props.navigationActions.getSalonBlockedDatesPath(salon.id)}
          >
            <Button align="center" color="secondary" size="small">
              <I18nText i18nKey="pages.salon_listings.buttons.edit_blocked_dates.label" />
            </Button>
          </UnstyledLink>
        </CardActions>
      </Card>
    );
  }
}

export const SalonListingCard = withStyles(styles)(
  withNavigation(SalonListingCardClass)
);
