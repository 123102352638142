import Paper from "@material-ui/core/Paper";
import { Theme } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ApolloError } from "apollo-client";
import React from "react";

import { ValidationError } from "form/index";

import { I18nText } from "./I18nText";

const styles = (theme: Theme) => ({
  root: theme.mixins.gutters({
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  }),
});

export interface FormErrorMessageClassProps {
  error: Error;
}

export type FormErrorMessageStyles = WithStyles<typeof styles>;

export type FormErrorMessageProps = FormErrorMessageClassProps &
  FormErrorMessageStyles;

export const FormErrorMessageClass = (props: FormErrorMessageProps) => {
  const { classes, error } = props;
  return (
    <Paper className={classes.root} elevation={4}>
      <Typography color="error" variant="h6">
        <I18nText i18nKey={errorToI18nKey(error)} />
      </Typography>
    </Paper>
  );
};

function errorToI18nKey(error: Error) {
  switch (error.constructor) {
    case ValidationError:
    case ApolloError:
      return "common.form_validation_error";
    default:
      return "common.form_generic_error";
  }
}

export const FormErrorMessage = withStyles(styles)(FormErrorMessageClass);
