import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const Button: React.FunctionComponent<any> = ({ ...props }) => (
  <MuiButton disableRipple={true} {...props} />
);

export default withStyles((theme) => ({
  root: {
    borderRadius: 5,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: "'Roboto Condensed', sans-serif",
    padding: theme.spacing(2, 4),
    fontSize: "1rem",
    boxShadow: "none",
    "&:active, &:focus": {
      boxShadow: "none",
    },
    minWidth: 0,
  },
  sizeSmall: {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(13),
  },
  sizeLarge: {
    padding: theme.spacing(2, 5),
    fontSize: theme.typography.pxToRem(16),
  },
}))(Button);
