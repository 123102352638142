export const AUTH_COMPLETED = "AUTH_COMPLETED";
export const AUTH_ERRORED = "AUTH_ERRORED";
export const CREATE_USER_COMPLETED = "CREATE_USER_COMPLETED";
export const CREATE_USER_ERRORED = "CREATE_USER_ERRORED";
export const CREATE_USER_REQUESTED = "CREATE_USER_REQUESTED";
export const GET_ME_COMPLETED = "GET_ME_COMPLETED";
export const GET_ME_ERRORED = "GET_ME_ERRORED";
export const GET_ME_REQUESTED = "GET_ME_REQUESTED";
export const LOGOUT_USER_COMPLETED = "LOGOUT_USER_COMPLETED";
export const RENEW_TOKEN_REQUESTED = "RENEW_TOKEN_REQUESTED";
