import { WithStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import { LinkProps } from "react-router-dom";

const styles: any = {
  root: {
    color: "inherit",
    textDecoration: "none",
  },
};

// "innerRef" clashes between the two types. Take it out of the styles.
export type UnstyledLinkStyles = WithStyles<typeof styles>;

export interface UnstyledLinkProps extends LinkProps, UnstyledLinkStyles {
  children?: React.ReactNode;
}

export const UnstyledLinkClass = (props: UnstyledLinkProps) => {
  const { classes } = props;
  return (
    <Link {...props} className={classes.root}>
      {props.children}
    </Link>
  );
};

export const UnstyledLink = withStyles(styles)(UnstyledLinkClass);

export type UnstyledAnchorStyles = WithStyles<typeof styles>;

export interface UnstyledAnchorProps
  extends React.AnchorHTMLAttributes<any>,
    UnstyledAnchorStyles {
  children?: React.ReactNode;
}

export const UnstyledAnchorClass = (props: UnstyledAnchorProps) => {
  const { classes } = props;
  return (
    <a {...props} className={classes.root}>
      {props.children}
    </a>
  );
};

export const UnstyledAnchor = withStyles(styles)(UnstyledAnchorClass);
