import { QueryResult } from "@apollo/react-common";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import React from "react";
import { useParams } from "react-router-dom";

import { I18nText } from "component/I18nText";
import { LinearLayout } from "component/LinearLayout";
import LoadingIndicator from "component/modules/LoadingIndicator";
import Typography from "component/modules/Typography";
import { PageContainer } from "component/PageContainer";
import { SalonStatus } from "types.generated";

import { AdminSalonDetailQuery } from "./AdminSalonDetailPage.generated";
import { AdminSalonDetailQueryVariables } from "./AdminSalonDetailPage.generated";
import { AdminUpdateSalonStatusMutation } from "./AdminSalonDetailPage.generated";
import { AdminUpdateSalonStatusMutationVariables } from "./AdminSalonDetailPage.generated";
import { SalonDetail, fragments } from "./component/SalonDetail";

const ADMIN_SALON_DETAIL_QUERY = gql`
  query AdminSalonDetail($id: ID!) {
    salonById(id: $id) {
      ...SalonDetail_salon
    }
  }
  ${fragments.salon}
`;

const UPDATE_SALON_STATUS = gql`
  mutation AdminUpdateSalonStatus($input: UpdateSalonStatusInput!) {
    updateSalonStatus(input: $input) {
      salon {
        id
        status
      }
    }
  }
`;

export const AdminSalonDetailPage = () => {
  const { id } = useParams();
  const salonDetailQueryResult = useQuery<
    AdminSalonDetailQuery,
    AdminSalonDetailQueryVariables
  >(ADMIN_SALON_DETAIL_QUERY, { variables: { id } });

  const [updateSalonStatus] = useMutation<
    AdminUpdateSalonStatusMutation,
    AdminUpdateSalonStatusMutationVariables
  >(UPDATE_SALON_STATUS);
  const handleUpdateSalonStatus = (salonId: string, status: SalonStatus) => {
    updateSalonStatus({ variables: { input: { id: salonId, status } } });
  };
  return (
    <AdminSalonDetailPagePresentation
      onUpdateSalonStatus={handleUpdateSalonStatus}
      salonDetailQueryResult={salonDetailQueryResult}
    />
  );
};

export type AdminSalonDetailPagePresentationProps = Readonly<{
  salonDetailQueryResult: QueryResult<AdminSalonDetailQuery>;
  onUpdateSalonStatus: (id: string, status: SalonStatus) => void;
}>;

export const AdminSalonDetailPagePresentation = (
  props: AdminSalonDetailPagePresentationProps
) => {
  const { salonDetailQueryResult, onUpdateSalonStatus } = props;
  const { data, error, loading } = salonDetailQueryResult;
  return (
    <PageContainer>
      <LinearLayout orientation="vertical" spacing={2}>
        {loading && <LoadingIndicator />}
        {error && (
          <Typography align="center" color="error" variant="h5">
            <I18nText i18nKey={`common.error`} />
          </Typography>
        )}
        {data?.salonById && (
          <SalonDetail
            onUpdateSalonStatus={onUpdateSalonStatus}
            salon={data.salonById}
          />
        )}
      </LinearLayout>
    </PageContainer>
  );
};
