import { withTranslation } from "react-i18next";
import { WithTranslation } from "react-i18next";

interface I18nTextPublicProps {
  i18nKey: string;
  [index: string]: string;
}

const I18nTextComponent: React.SFC<I18nTextPublicProps & WithTranslation> = (
  props
) => {
  const { i18nKey, t, ...translationProps } = props;
  return t(i18nKey, translationProps) as any;
};

// tslint:disable-next-line:no-any
export const I18nText: React.SFC<I18nTextPublicProps> = withTranslation()(
  I18nTextComponent
) as any;
