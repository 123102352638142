import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core/styles";
import React from "react";

import Button from "component/modules/Button";
import Typography from "component/modules/Typography";
import { I18nText } from "component/I18nText";
import { UnstyledLink } from "component/UnstyledLink";
import { WithNavigationProvidedProps } from "tools/withNavigation";
import { withNavigation } from "tools/withNavigation";

export type BecomingAHostStyles = WithStyles<typeof styles>;
export type BecomingAHostProps = BecomingAHostStyles &
  WithNavigationProvidedProps<void>;

const styles: any = (theme: Theme) => ({
  button: {
    marginTop: theme.spacing(3),
  },

  footer: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_800/v1551749524/siteImageAssets/Bkgrnd861.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: 500,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  footerContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  headline: {
    color: theme.palette.common.white,
    margin: theme.spacing(1),
  },

  hero: {
    alignItems: "center",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,h_800/v1551749524/siteImageAssets/Become_a_Host_Top.jpg")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: 400,
    justifyContent: "center",
  },

  container: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },

  spacer: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
});

const rowItems = [
  {
    description: "pages.becoming_a_host.section_two.row_one.description",
    imageSource:
      "https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,w_300/v1551749746/siteImageAssets/Createlisting.png",
    key: 0,
    title: "pages.becoming_a_host.section_two.row_one.title",
  },

  {
    description: "pages.becoming_a_host.section_two.row_two.description",
    imageSource:
      "https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,w_300/v1551749527/siteImageAssets/Welcomeguests.png",
    key: 1,
    title: "pages.becoming_a_host.section_two.row_two.title",
  },

  {
    description: "pages.becoming_a_host.section_two.row_three.description",
    imageSource:
      "https://res.cloudinary.com/dqckrnxlu/image/upload/c_scale,w_300/v1551749783/siteImageAssets/Getpaid.png",
    key: 2,
    title: "pages.becoming_a_host.section_two.row_three.title",
  },
];

const RowItem: React.FunctionComponent<{
  classes: any;
  description: string;
  title: string;
  imageSource: string;
}> = ({ classes, title, imageSource, description }) => (
  <Grid
    className={classes.spacer}
    justify="center"
    container={true}
    spacing={4}
  >
    <Grid item={true} xs={6} md={2}>
      <img src={imageSource} alt="host" height="150" width="150" />
    </Grid>
    <Grid item={true} xs={12} md={6}>
      <Typography variant="h6">
        <strong>
          <I18nText i18nKey={title} />
        </strong>
      </Typography>
      <Typography variant="body1">
        <I18nText i18nKey={description} />
      </Typography>
    </Grid>
  </Grid>
);

const Hero: React.FunctionComponent<BecomingAHostProps> = (props) => (
  <div className={props.classes.hero}>
    <Typography align="center" className={props.classes.headline} variant="h1">
      <I18nText i18nKey={"pages.becoming_a_host.headline"} />
    </Typography>
    <Typography align="center" className={props.classes.headline} variant="h4">
      <I18nText i18nKey={"pages.becoming_a_host.sub_headline"} />
    </Typography>
    <UnstyledLink to={props.navigationActions.createSalonPagePath}>
      <Button
        className={props.classes.button}
        variant="contained"
        color="secondary"
      >
        <I18nText i18nKey="pages.becoming_a_host.buttons.start_now.label" />
      </Button>
    </UnstyledLink>
  </div>
);

export class BecomingAHostPageClass extends React.Component<
  BecomingAHostProps
> {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Hero {...this.props} />
        <Container className={classes.container} maxWidth="xl">
          <Grid container={true} justify="center" spacing={8}>
            <Grid item={true} xs={12} md={3}>
              <Typography variant="h6">
                <strong>
                  <I18nText
                    i18nKey={
                      "pages.becoming_a_host.section_one.column_one.title"
                    }
                  />
                </strong>
              </Typography>
              <Typography variant="body1">
                <I18nText
                  i18nKey={
                    "pages.becoming_a_host.section_one.column_one.description"
                  }
                />
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Typography variant="h6">
                <strong>
                  <I18nText
                    i18nKey={
                      "pages.becoming_a_host.section_one.column_two.title"
                    }
                  />
                </strong>
              </Typography>
              <Typography variant="body1">
                <I18nText
                  i18nKey={
                    "pages.becoming_a_host.section_one.column_two.description"
                  }
                />
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Typography variant="h6">
                <strong>
                  <I18nText
                    i18nKey={
                      "pages.becoming_a_host.section_one.column_three.title"
                    }
                  />
                </strong>
              </Typography>
              <Typography variant="body1">
                <I18nText
                  i18nKey={
                    "pages.becoming_a_host.section_one.column_three.description"
                  }
                />
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl">
          <Divider />
        </Container>
        <Container className={classes.container} maxWidth="lg">
          <Grid justify="center" container={true} spacing={6}>
            <Grid item={true} xs={12}>
              <Typography align="center" variant="h4" marked="center">
                <I18nText
                  i18nKey={"pages.becoming_a_host.section_two.headline"}
                />
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              {rowItems.map((item, index) => (
                <RowItem
                  key={index}
                  title={item.title}
                  description={item.description}
                  imageSource={item.imageSource}
                  classes={classes}
                />
              ))}
            </Grid>
          </Grid>
        </Container>
        <div className={classes.footer}>
          <Container className={classes.footerContainer} maxWidth="md">
            <Typography
              align="center"
              className={classes.headline}
              variant="h3"
            >
              <strong>
                <I18nText
                  i18nKey={"pages.becoming_a_host.section_three.headline"}
                />
              </strong>
            </Typography>
            <Typography
              align="center"
              className={classes.headline}
              variant="subtitle1"
            >
              <I18nText
                i18nKey={"pages.becoming_a_host.section_three.sub_headline"}
              />
            </Typography>
            <UnstyledLink to={this.props.navigationActions.createSalonPagePath}>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
              >
                <I18nText i18nKey="pages.becoming_a_host.buttons.start_now.label" />
              </Button>
            </UnstyledLink>
          </Container>
        </div>
      </div>
    );
  }
}

export const BecomingAHostPage = withStyles(styles)(
  withNavigation(BecomingAHostPageClass)
);
