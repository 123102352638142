import { combineReducers } from "redux";

import { getReducer as getCreateChairReducer } from "../pages/createChair";
import { getReducer as getCreateSalonReducer } from "../pages/createSalon";
import { getReducer as getEditSalonProfileReducer } from "../pages/editSalonProfile";
import { getReducer as getEditUserProfileReducer } from "../pages/editUserProfile";
import { getReducer as getSalonDetailReducer } from "../pages/salonDetail";
import { sessionReducer } from "./session";
import { userReducer } from "./user";

export const reducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  ...getCreateChairReducer(),
  ...getCreateSalonReducer(),
  ...getEditSalonProfileReducer(),
  ...getEditUserProfileReducer(),
  ...getSalonDetailReducer(),
});
