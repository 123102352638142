import MuiCircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const LoadingIndicator: React.FunctionComponent<any> = ({ ...props }) => (
  <Typography align="center">
    <MuiCircularProgress
      align="center"
      size={props.size || 60}
      color="secondary"
      {...props}
    />
  </Typography>
);

export default withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}))(LoadingIndicator);
