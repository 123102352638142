import { History } from "history";
import queryString from "query-string";
import { generatePath } from "react-router";

export class NavigationActions {
  public readonly aboutUsPagePath = "/about_us";
  public readonly adminPagePath = "/admin";
  public readonly adminSalonDetailPagePath = "/admin/salons/:id";
  public readonly becomingAHostPagePath = "/becoming_a_host";
  public readonly bookingListingsPagePath = "/bookings";
  public readonly contactPagePath = "/contact";
  public readonly createChairPagePath = "/salons/:salonId/create_chair";
  public readonly createSalonPagePath = "/create_salon";
  public readonly createSalonBlockedDatePagePath =
    "/salons/:id/chairs/:chairId/blocked_dates/create";
  public readonly editChairPagePath = "/salons/:salonId/chairs/:chairId/edit";
  public readonly editSalonPagePath = "/salons/:id/edit";
  public readonly editSalonProfilePagePath = "/salons/:id/profile_edit";
  public readonly editUserProfilePagePath = "/users/:id/profile_edit";
  public readonly homePagePath = "/";
  public readonly loginCallbackPagePath = "/login_callback";
  public readonly loginPagePath = "/login";
  public readonly logOutPagePath = "/log_out";
  public readonly privacyPolicyPagePath = "/privacy-policy";
  public readonly salonBookingsPagePath = "/salons/:id/bookings";
  public readonly salonBlockedDatesPagePath = "/salons/:id/blocked_dates";
  public readonly salonBookingConfirmationPagePath =
    "/salons/:id/booking_confirmation";
  public readonly salonDetailPagePath = "/salons/:id";
  public readonly salonListingsPagePath = "/salons";
  public readonly salonChairsPagePath = "/salons/:salonId/chairs";
  public readonly searchResultsPagePath = "/search_results";
  public readonly signUpCallbackPagePath = "/sign_up_callback";
  public readonly signUpPagePath = "/sign_up";
  public readonly stripeCallbackPagePath = "/stripe_callback";
  public readonly spaceLicenseAgreementPagePath = "/space-license-agreement";
  public readonly termsOfUsePagePath = "/terms-of-use";
  public readonly userProfilePagePath = "/users/:id";

  constructor(private readonly history: History<unknown>) {}

  gotoAdminSalonPage(salonId: number | string): void {
    this.history.push(this.getAdminSalonPagePath(salonId));
  }

  gotoBookingConfirmationPage(
    salonId: number | string,
    chairId: number | string,
    checkInDateUrl: string,
    checkOutDateUrl: string
  ): void {
    this.history.push(
      this.getBookingConfirmationPagePath(
        salonId,
        chairId,
        checkInDateUrl,
        checkOutDateUrl
      )
    );
  }

  gotoBookingListingsPage(state: unknown): void {
    this.history.push(this.bookingListingsPagePath, state);
  }

  gotoHomePage(): void {
    this.history.push(this.homePagePath);
  }

  gotoSignUpPage(): void {
    this.history.push(this.signUpPagePath);
  }

  gotoPath(...paths: any[]): void {
    this.history.push(paths.join("/"));
  }

  gotoSalonDetailsPage(salonId: number | string): void {
    this.history.push(this.getSalonDetailsPagePath(salonId));
  }

  gotoSalonChairsPage(salonId: number | string, state?: unknown): void {
    this.history.push(this.getSalonChairsPagePath(salonId), state);
  }

  gotoSalonBlockedDatesPage(
    salonId: number | string,
    chairId: number | string,
    state?: unknown
  ): void {
    this.history.push(this.getSalonBlockedDatesPath(salonId, chairId), state);
  }

  gotoSearchFormResultsPage(searchPath: string): void {
    this.history.push(searchPath);
  }

  gotoCreateChairPage(salonId: number | string): void {
    this.history.push(this.getCreateChairPagePath(salonId));
  }

  gotoEditChairPage(salonId: number | string, chairId: number | string): void {
    this.history.push(this.getEditChairPagePath(salonId, chairId));
  }

  gotoSalonProfileEditPage(salonId: number | string): void {
    this.history.push(this.getSalonProfileEditPagePath(salonId));
  }

  gotoUserProfilePage(userId: number | string, state: unknown): void {
    this.history.push(this.getUserProfilePagePath(userId), state);
  }

  getAdminSalonPagePath(salonId: number | string): string {
    return generatePath(this.adminSalonDetailPagePath, { id: salonId });
  }

  getBookingConfirmationPagePath(
    salonId: number | string,
    chairId: number | string,
    checkInDateUrl: string,
    checkOutDateUrl: string
  ): string {
    return (
      generatePath(this.salonBookingConfirmationPagePath, { id: salonId }) +
      `?chairId=${chairId}&${checkInDateUrl}&${checkOutDateUrl}`
    );
  }

  getSalonChairsPagePath(salonId: number | string): string {
    return generatePath(this.salonChairsPagePath, { salonId });
  }

  getSalonProfileEditPagePath(salonId: number | string): string {
    return generatePath(this.editSalonProfilePagePath, { id: salonId });
  }

  getCreateChairPagePath(salonId: number | string): string {
    return generatePath(this.createChairPagePath, { salonId });
  }

  getEditChairPagePath(
    salonId: number | string,
    chairId: number | string
  ): string {
    return generatePath(this.editChairPagePath, { salonId, chairId });
  }

  getEditSalonPagePath(salonId: number | string): string {
    return generatePath(this.editSalonPagePath, { id: salonId });
  }

  getSalonBookingsPagePath(salonId: number | string): string {
    return generatePath(this.salonBookingsPagePath, { id: salonId });
  }

  getSalonBlockedDatesPath(
    salonId: number | string,
    chairId?: number | string
  ): string {
    const chairQueryString = queryString.stringify({ chairId });
    return (
      generatePath(this.salonBlockedDatesPagePath, { id: salonId }) +
      (chairId ? `?${chairQueryString}` : "")
    );
  }

  getCreateSalonBlockedDatePath(
    salonId: number | string,
    chairId: number | string
  ): string {
    return generatePath(this.createSalonBlockedDatePagePath, {
      id: salonId,
      chairId,
    });
  }

  getSalonDetailsPagePath(salonId: number | string): string {
    return generatePath(this.salonDetailPagePath, { id: salonId });
  }

  getUserProfilePagePath(userId: number | string): string {
    return generatePath(this.userProfilePagePath, { id: userId });
  }

  getUserProfileEditPagePath(userId: number | string): string {
    return generatePath(this.editUserProfilePagePath, { id: userId });
  }
}
