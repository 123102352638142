import omit from "lodash/omit";

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ApolloError } from "apollo-client";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Dispatch } from "redux";

import { UserState } from "model/index";
import { bindActionCreators } from "tools/index";
import { useNavigationActions } from "tools/useNavigationActions";

import { SalonForm } from "./component/SalonForm";
import { CreateSalonFormActions } from "./form/state";
import { emptySalon } from "./form/state";
import { CreateOrEditSalonPageState } from "./model";
import { CreateSalonFormState } from "./model";
import { SalonFormState } from "./model";

import {
  CreateSalonPage_CreateSalonMutation,
  CreateSalonPage_CreateSalonMutationVariables,
} from "./CreateSalonPage.generated";

const CREATE_SALON = gql`
  mutation CreateSalonPage_CreateSalon($input: CreateSalonInput!) {
    createSalon(input: $input) {
      salon {
        id
        name
        licenseNumber
        licenseState
        phoneNumber
        address
        location {
          longitude
          latitude
        }
        title
        description
        contactName
        contactEmail
        contactPhoneNumber
        images {
          id
          publicId
          url
        }
      }
    }
  }
`;

export const CreateSalonPageConatiner = () => {
  const navigationActions = useNavigationActions();

  const [createSalon, { error: createSalonError }] = useMutation<
    CreateSalonPage_CreateSalonMutation,
    CreateSalonPage_CreateSalonMutationVariables
  >(CREATE_SALON, {
    onCompleted: (updatedData) => {
      if (updatedData.createSalon?.salon) {
        navigationActions.gotoSalonChairsPage(
          updatedData.createSalon?.salon.id,
          { refetch: true }
        );
      }
    },
  });

  const mappedCreateSalon = (salonFormState: SalonFormState) => {
    const mappedSalon = {
      ...omit(salonFormState, ["id"]),
    };
    createSalon({
      variables: { input: { salon: mappedSalon } },
    });
  };

  return (
    <CreateSalonPage
      createSalon={mappedCreateSalon}
      createSalonError={createSalonError}
    />
  );
};

export interface CreateSalonPageClassProps {
  createSalon: (salon: SalonFormState) => void;
  createSalonError?: ApolloError;
  createSalonFormActions: CreateSalonFormActions;
  createSalonFormState: CreateSalonFormState;
  userState: UserState;
}

export type CreateSalonPageProps = CreateSalonPageClassProps &
  RouteComponentProps<void>;

export class CreateSalonPageClass extends React.Component<
  CreateSalonPageProps
> {
  constructor(props: CreateSalonPageProps) {
    super(props);

    const { userProfile } = props.userState;
    props.createSalonFormActions.createObject(CREATE_SALON_ID, {
      ...emptySalon,
      contactEmail: (userProfile && userProfile.email) || "",
      contactName:
        (userProfile && `${userProfile.firstName} ${userProfile.lastName}`) ||
        "",
      contactPhoneNumber: (userProfile && userProfile.phoneNumber) || "",
    });
  }

  render() {
    return (
      <SalonForm
        salonFormError={this.props.createSalonError}
        createSalonFormActions={this.props.createSalonFormActions}
        createSalonFormState={this.props.createSalonFormState}
        id={CREATE_SALON_ID}
        url={this.props.match.url}
        userState={this.props.userState}
      />
    );
  }
}

const CREATE_SALON_ID = "CREATE_SALON_ID";

type UnconnectedCreateSalonPageProps = Omit<
  CreateSalonPageProps,
  "createSalonFormActions" | "createSalonFormState" | "userState"
>;

const mapStateToProps = (state: CreateOrEditSalonPageState) => {
  const { createSalonFormState, userState } = state;
  return {
    createSalonFormState,
    userState,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: UnconnectedCreateSalonPageProps
) => {
  const createSalonFormActions = new CreateSalonFormActions(
    props.match.url,
    props.createSalon
  );
  return {
    createSalonFormActions: bindActionCreators(
      createSalonFormActions,
      dispatch
    ),
  };
};

export const CreateSalonPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateSalonPageClass)
);
