import { Auth0DecodedHash } from "auth0-js";
import { WebAuth } from "auth0-js";

export class Auth {
  private readonly loginAuth0: WebAuth;
  private readonly signUpAuth0: WebAuth;

  constructor(private readonly root: string) {
    this.loginAuth0 = new WebAuth({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
      domain: process.env.REACT_APP_AUTH0_DOMAIN!,
      redirectUri: `${root}/login_callback`,
      responseType: "token id_token",
      scope: "openid profile email",
    });
    this.signUpAuth0 = new WebAuth({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
      domain: process.env.REACT_APP_AUTH0_DOMAIN!,
      redirectUri: `${root}/sign_up_callback`,
      responseType: "token id_token",
      scope: "openid profile email",
    });
  }

  login() {
    this.loginAuth0.authorize();
  }

  logout() {
    this.loginAuth0.logout({
      returnTo: this.root,
    });
  }

  checkSession(): Promise<Auth0DecodedHash | undefined> {
    return new Promise((resolve, reject) => {
      this.loginAuth0.checkSession({}, (error, authResult) => {
        if (authResult && authResult.accessToken) {
          resolve(authResult);
        } else if (error) {
          reject(error);
        }
        resolve(undefined);
      });
    });
  }

  signUp() {
    this.signUpAuth0.authorize();
  }

  async handleLoginAuthentication(): Promise<Auth0DecodedHash | undefined> {
    return this.handleAuthentication(this.loginAuth0);
  }

  async handleSignUpAuthentication(): Promise<Auth0DecodedHash | undefined> {
    return this.handleAuthentication(this.signUpAuth0);
  }

  private async handleAuthentication(
    auth0: WebAuth
  ): Promise<Auth0DecodedHash | undefined> {
    return new Promise((resolve, reject) => {
      auth0.parseHash((error, authResult) => {
        if (authResult && authResult.accessToken) {
          resolve(authResult);
        } else if (error) {
          reject(error);
        }
        resolve(undefined);
      });
    });
  }
}
