// @ts-ignore No types exist for this
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import React from "react";

import "./GeocoderInput.css";

export type GeocoderInputProps = Readonly<{
  initialValue?: string;
  onPlaceChange: (place: any) => void;
}>;

export const GeocoderInput = (props: GeocoderInputProps) => {
  const { initialValue, onPlaceChange } = props;
  const initInput = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        const geocoder = new MapboxGeocoder({
          accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
          countries: "us",
          types: "region,postcode,locality,neighborhood,place",
        });
        geocoder.on("result", onPlaceChange);
        geocoder.addTo("#geocoder");

        if (initialValue) {
          geocoder._inputEl.value = initialValue;
        }
      }
    },
    [initialValue, onPlaceChange]
  );
  return <div id="geocoder" className="GeocoderInput" ref={initInput} />;
};
